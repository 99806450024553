import { useMemo } from "react"
import Polyline from "../../../components/Maps/Polyline"
import { DailyReportResponse } from "../../../types/dailyReport"
import MapDailyData from "./MapDailyData"
import TimeSlider from "./TimeSlider"
interface Props {
  loading: boolean
  data: DailyReportResponse | undefined
  dailyReportTs?: Date
  map?: google.maps.Map
}
const DailyMapWrapper = ({ loading, data, dailyReportTs, map }: Props) => {
  const multiplePolylines = useMemo(() => {
    if (loading || !data) {
      return []
    }

    const polyLines = []

    let polyLine = []
    polyLine.push(data.positions[0])
    for (let i = 0; i < data.positions.length - 1; i++) {
      if (data.positions[i][4] === data.positions[i + 1][4]) {
        polyLine.push(data.positions[i + 1])
      } else {
        polyLines.push(polyLine)
        polyLine = []
        polyLine.push(data.positions[i + 1])
      }
    }
    if(polyLine.length > 0) {
      polyLines.push(polyLine)
    }
    return polyLines
  }, [loading, data])

  return (
    <>
      {multiplePolylines.map((polyline, index) => {
        if (index == 0) {
          return (
            <Polyline
              key={`start-polyline`}
              data={polyline}
              map={map}
              dailyReportTs={dailyReportTs}
              renderIcon="start"
            />
          )
        }
        if (index == multiplePolylines.length - 1) {
          return (
            <Polyline
              key={`end-polyline`}
              data={polyline}
              map={map}
              dailyReportTs={dailyReportTs}
              renderIcon="end"
            />
          )
        }
        return (
          <Polyline
            key={`polyline-${polyline[0][0]}`}
            data={polyline}
            dailyReportTs={dailyReportTs}
            map={map}
          />
        )
      })}

      <MapDailyData data={data?.daily_totals_data} isElectricVehicle={data?.is_electric_vehicle} />

      <TimeSlider data={data?.positions} />
    </>
  )
}

export default DailyMapWrapper
