import Card from "../../../components/Cards"
import TimeSeriesChart from "../../../components/Charts/TimeSeriesChart"
import MaintenanceCard from "./MaintenanceCard"
import MaintenanceFilters from "./MaintenanceFilters"
import { useTranslation } from "react-i18next"
import useMaintenance from "../../../hooks/queries/useMaintenance"
import DashboardContainer from "../../DashboardContainer"
import useStore from "../../../store"
import ContentLoader from "react-content-loader"
import shallow from "zustand/shallow"
import randomColors from "../../../util/randomColor"
import { useMemo } from "react"

const LoadingContent = () => (
  <ContentLoader viewBox="0 0 800 160" width={"100%"} height={160}>
    <rect x="0" y="30" rx="2" ry="2" width="100%" height="20" />
    <rect x="0" y="70" rx="2" ry="2" width="100%" height="20" />
    <rect x="0" y="110" rx="2" ry="2" width="100%" height="20" />
    {/* <rect x="0" y="60" rx="2" ry="2" width="400" height="400" /> */}
  </ContentLoader>
)
const Maintenance = () => {
  const { t } = useTranslation()
  const { loading, data, getData } = useMaintenance()
  const { filterMenuVisible, selectedLimitedEquipments } = useStore(
    (state) => ({
      filterMenuVisible: state.filterMenuVisible,
      selectedLimitedEquipments: state.selectedLimitedEquipments
    }),
    shallow
  )

  const vehicleColors = useMemo(() => {
    const colors: Record<string, string> = {}
    if (selectedLimitedEquipments && selectedLimitedEquipments.length > 0) {
      selectedLimitedEquipments?.map((equipment, index) => {
        colors[equipment.label] = randomColors[index]
      })
    }
    return colors
  }, [selectedLimitedEquipments])

  return (
    <DashboardContainer title="Maintenance">
      <div
        className="flex flex-col justify-between space-x-0 md:flex-row md:space-x-2 w-full flex-grow"
        style={{ height: "calc(100% - 4rem)" }}
      >
        <div className="w-full mr-0 mb-4 md:mb-0 md:w-4/12 lg:w-2/12 md:mr-1">
          <MaintenanceCard equipments={data?.maintenance_need_list} />
        </div>
        <div
          className={
            filterMenuVisible
              ? "w-full h-full lg:h-auto lg:w-8/12"
              : "w-full h-full lg:h-auto lg:w-10/12"
          }
        >
          <div className="w-full h-full flex-grow flex flex-col">
            <Card
              title={t("maintenance.odometer")}
              description={t("maintenance.in_km")}
              className="w-full h-64 lg:h-2/6"
              inlineHeader
              style={{marginTop: 0}}
            >
              <>
                {loading && <LoadingContent />}
                {!loading && (
                  <TimeSeriesChart
                    data={data?.dist_travel_chart_data}
                    vehicleColors={vehicleColors}
                  />
                )}
              </>
            </Card>

            <Card
              title={t("maintenance.sweeep_engine_timer")}
              description={t("maintenance.in_hours")}
              className="w-full h-64 lg:h-2/6"
              inlineHeader
            >
              <>
                {loading && <LoadingContent />}
                {!loading && (
                  <TimeSeriesChart
                    data={data?.sweep_hours_chart_data}
                    vehicleColors={vehicleColors}
                  />
                )}
              </>
            </Card>

            <Card
              title={t("maintenance.engine_timer")}
              description={t("maintenance.in_hours")}
              className="w-full h-64 lg:h-2/6"
              inlineHeader
            >
              <>
                {loading && <LoadingContent />}
                {!loading && (
                  <TimeSeriesChart
                    data={data?.engine_hours_chart_data}
                    vehicleColors={vehicleColors}
                  />
                )}
              </>
            </Card>
          </div>
        </div>
        {filterMenuVisible && (
          <div
            className={`transform absolute shadow-xl right-0 top-32 w-56 mx-auto z-20 transition duration-200 ease-in-out lg:shadow-none lg:top-0 lg:bottom-0 lg:right-auto lg:relative lg:w-80 ${
              filterMenuVisible ? "translate-x-0" : "translate-x-full"
            }`}
          >
            <MaintenanceFilters
              onApplyFilter={getData}
              equipments={selectedLimitedEquipments}
              vehicleColors={vehicleColors}
            />
          </div>
        )}
      </div>
    </DashboardContainer>
  )
}

export default Maintenance
