import { useState } from "react"
import apiClient from "../../api"

interface InviteUserData {
  loading: boolean
  error?: string
  success?: boolean
  inviteUser: (formData: FormData) => void
}

const useInviteUser = (): InviteUserData => {
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState<string>()
  const [success, setSuccess] = useState<boolean>()

  const inviteUser = async (formData: FormData) => {
    setLoading(true)
    setError(undefined)
    setSuccess(false)
    try {
      await apiClient.post("user-management/inviteUser", formData, {
        withCredentials: true
      })
      setSuccess(true)
    } catch (e: any) {
      setError(e)
    }
    setLoading(false)
  }

  return { loading, error, success, inviteUser }
}

export default useInviteUser
