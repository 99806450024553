import qs from "qs"
import { useCallback, useEffect, useState } from "react"
import shallow from "zustand/shallow"
import apiClient from "../../api"
import useStore from "../../store"
import { MaintenanceResponse } from "../../types/maintenance"
interface MaintenanceData {
  loading: boolean
  error?: string
  data?: MaintenanceResponse
  getData: () => void
}

const useMaintenance = (): MaintenanceData => {
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState<string>()
  const [data, setData] = useState<MaintenanceResponse>()

  const { selectedEquipments, selectedOrganization } = useStore(
    (state) => ({
      selectedEquipments: state.selectedLimitedEquipments,
      selectedOrganization: state.selectedOrganization
    }),
    shallow
  )

  useEffect(() => {
    if (selectedOrganization) {
      getData()
    }
  }, [selectedOrganization])

  const buildRequestParams = useCallback(() => {
    if (!selectedOrganization) {
      return null
    }
    const params: Record<string, string> = {}

    if (selectedEquipments?.length) {
      params["vehicle_ids"] = selectedEquipments.map((d) => d.value).join(",")
    }
    //  else {
    //   if (selectedBrands?.length) {
    //     params["brands_filter"] = selectedBrands.map((d) => d.value).join(",")
    //   }
    //   if (selectedTypes?.length) {
    //     params["types_filter"] = selectedTypes.map((d) => d.value).join(",")
    //   }
    //   if (selectedModels?.length) {
    //     params["models_filter"] = selectedModels.map((d) => d.value).join(",")
    //   }
    // }

    return params
  }, [
    // selectedDistricts,
    selectedOrganization,
    // selectedBrands,
    // selectedTypes,
    // selectedModels,
    selectedEquipments
  ])

  const getData = async () => {
    const reqParams = buildRequestParams()
    if (!reqParams) {
      return null
    }
    setLoading(true)
    try {
      const { data } = await apiClient.get(
        `/sweeper-api/charts/maintenance?${qs.stringify(reqParams)}`
      )

      setData(data)
    } catch (e: any) {
      setError(e)
    }
    setLoading(false)
  }

  return { loading, error, data, getData }
}

export default useMaintenance
