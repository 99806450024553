import { useEffect, useMemo, useState } from "react"
import { ActionMeta, SingleValue } from "react-select"
import shallow from "zustand/shallow"
import SelectFilterInput from "../../../components/Filter/Input/SelectFilterInput"
import useUpdateTimezone from "../../../hooks/mutations/useUpdateTimezone"
import useStore from "../../../store"
import availableTimezones from "../../../util/availableTimezones"
import notyf from "../../../util/notyf"

const Timezone = () => {
  const [selectedTZ, setSelectedTZ] = useState<string | null>(null)
  const { success, updateTimezone } = useUpdateTimezone()
  const { selectedOrganization, myOrganizations } = useStore(
    (state) => ({
      selectedOrganization: state.selectedOrganization,
      myOrganizations: state.myOrganizations
    }),
    shallow
  )

  const organizationTimezone = myOrganizations?.find(
    (q) => q.id === selectedOrganization
  )?.organization_timezone as string

  useEffect(() => {
    if (success) {
      notyf.success("Timezone updated successfully!")
    }
  }, [success])

  const handleFilterChange = (
    { value }: SingleValue<any>,
    _: ActionMeta<any>
  ) => {
    setSelectedTZ(value)
  }

  const handleSave = () => {
    if (!selectedOrganization || !selectedTZ) {
      return
    }
    const formData = new FormData()
    formData.append("timezone_name", selectedTZ)
    formData.append("target_organization_id", selectedOrganization?.toString())

    updateTimezone(formData)
  }

  const options = useMemo(
    () => availableTimezones.map((item) => ({ label: item, value: item })),
    []
  )

  return (
    <div className="relative mb-20 max-w-lg mx-auto text-center">
      <div className="mb-8 py-8 px-6 bg-white rounded shadow-form">
        <span className="text-primary font-bold text-lg">Change Timezone</span>
        <div className="w-full mb-4">
          <SelectFilterInput
            defaultValue={{label: organizationTimezone, value: organizationTimezone}}
            options={options}
            getOptionLabel={(item) => item.label}
            getOptionValue={(item) => item.value}
            maxMenuHeight={300}
            onChange={handleFilterChange}
          />
        </div>
        <button
          className="w-full py-4 bg-primary hover:bg-primary rounded text-white text-sm font-bold"
          onClick={handleSave}
        >
          Save Changes
        </button>
      </div>
    </div>
  )
}

export default Timezone
