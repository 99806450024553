import { useEffect, useState } from "react"
import useDeleteShapeFile from "../../../../hooks/mutations/useDeleteShapeFile"
import useUploadShapeFile from "../../../../hooks/mutations/useUploadShapeFile"
import useShapeFile from "../../../../hooks/queries/userManagement/useShapeFile"
import { useNotyf } from "../../../../hooks/useNotyf"
import useStore from "../../../../store"
import DeleteModal from "../DeleteModal"
import ShapeFileDropdown from "./ShapeFileDropdown"
import ShapeFileTable from "./ShapeFileTable"

const ShapeFile = () => {
  const [modalOpen, setModalOpen] = useState(false)
  const { data, loading, error, getShapeFile } = useShapeFile()
  const {
    uploadShapeFile,
    success,
  } = useUploadShapeFile()
  const { deleteShapeFile, success: deleteSuccess } = useDeleteShapeFile()
  const { notyf } = useNotyf()

  const selectedOrg = useStore((state) => state.selectedOrganization)

  const onFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    // Update the state
    const file = event.target.files?.[0]

    if (file && selectedOrg) {
      const formData = new FormData()
      formData.append("target_organization_id", selectedOrg.toString())
      formData.append("file", file, file.name)

      uploadShapeFile(formData)
    }
  }

  const handleDelete = () => {
    setModalOpen(false)
    deleteShapeFile()
  }


  useEffect(() => {
    if (success) {
      notyf?.success("Shapefile uploaded successfully")
      getShapeFile()
    }
  }, [success])

  useEffect(() => {
    if (deleteSuccess) {
      notyf?.success("Shapefile was deleted successfully")
      getShapeFile()
    }
  }, [deleteSuccess])

  if (loading) {
    return <div>Please wait...</div>
  }

  return (
    <>
      <div className="flex flex-col w-full bg-white items-center justify-center p-4">
        <div className="flex w-full justify-between items-center">
          <div className="font-2xl font-bold mb-4">
            Select shapefile to upload
          </div>
          <div
            className="bg-red-600 rounded-md font-semibold text-white px-4 py-2 text-sm cursor-pointer flex items-center"
            onClick={() => setModalOpen(true)}
          >
            Delete shapefile
          </div>
        </div>
        <div className=" items-center justify-center bg-grey-lighter py-12">
          <label className="w-64 flex flex-col items-center px-4 py-6 bg-white text-primary rounded-lg shadow-lg tracking-wide uppercase border border-primary cursor-pointer hover:bg-primary hover:text-white">
            <svg
              className="w-8 h-8"
              fill="currentColor"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
            >
              <path d="M16.88 9.1A4 4 0 0 1 16 17H5a5 5 0 0 1-1-9.9V7a3 3 0 0 1 4.52-2.59A4.98 4.98 0 0 1 17 8c0 .38-.04.74-.12 1.1zM11 11h3l-4-4-4 4h3v3h2v-3z" />
            </svg>
            <span className="mt-2 text-base leading-normal">
              Select ZIP file
            </span>
            <input type="file" className="hidden" onChange={onFileChange} />
          </label>
        </div>
        <div className="font-semibold font-xl">
          Active shapefile: {data?.name}
        </div>
      </div>

      <div className="mt-4">
        {data && (
          <ShapeFileDropdown
            data={data}
            onSuccess={getShapeFile}
          />
        )}
      </div>

      <div className="mt-4">
        {data && <ShapeFileTable data={data} />}
      </div>

      <DeleteModal
        closeModal={() => setModalOpen(false)}
        onConfirm={handleDelete}
        visible={modalOpen}
        title="Are you sure you want to delete the shapefile?"
      />
    </>
  )
}

export default ShapeFile
