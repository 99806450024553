import { useState } from "react"
import apiClient from "../../../api"

interface CreateDistrictData {
  loading: boolean
  error?: string
  success?: boolean
  createDistrict: (formData: FormData) => void
}

const useCreateDistrict = (): CreateDistrictData => {
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState<string>()
  const [success, setSuccess] = useState<boolean>()

  const createDistrict = async (formData: FormData) => {
    setLoading(true)
    setError(undefined)
    setSuccess(false)
    try {
      await apiClient.post("sweeper-api/districts/createDistrict", formData, {
        withCredentials: true
      })
      setSuccess(true)
    } catch (e: any) {
      setError(e)
    }
    setLoading(false)
  }

  return { loading, error, success, createDistrict }
}

export default useCreateDistrict
