import { useEffect, useMemo, useState } from "react"
import { Column, useSortBy, useTable } from "react-table"
import EditIcon from "../../../../assets/icons/edit.svg"
import RotateRightIcon from "../../../../assets/icons/rotate-right.svg"
import DeleteIcon from "../../../../assets/icons/trash.svg"
import useDeleteDistrict from "../../../../hooks/mutations/useDeleteDistrict"
import useDeleteUser from "../../../../hooks/mutations/useDeleteUser"
import useDistricts from "../../../../hooks/queries/userManagement/useDistricts"
import { useNotyf } from "../../../../hooks/useNotyf"
import useStore from "../../../../store"
import { DistrictResponse } from "../../../../types/userManagement/district"
import AddDistrictModal from "./AddDistrictModal"
import DeleteDistrictModal from "./DeleteDistrictModal"
import DistrictModal from "./DistrictModal"

const Districts = () => {
  const columns: Column<DistrictResponse>[] = useMemo(() => {
    return [
      {
        Header: "Name",
        accessor: "name",
      },
      {
        Header: "Nr. of Vehicles",
        accessor: "vehicle_count",
      },
      {
        id: "1",
        Header: " ",
        Cell: ({ cell: { row } }: { cell: any }) => (
          <div
            className="flex text-sm cursor-pointer"
            onClick={() => handleEditDistrict(row.original)}
          >
            <img src={EditIcon} className="w-4 mr-2" />
            <div className="text-gray-500">Edit Workshop</div>
          </div>
        )
      },
      {
        id: "2",
        Header: " ",
        Cell: ({ cell: { row } }: { cell: any }) => (
          <div
            className="flex text-sm cursor-pointer"
            onClick={() => handleEditClick(row.original)}
          >
            <img src={EditIcon} className="w-4 mr-2" />
            <div className="text-gray-500">Edit Vehicles</div>
          </div>
        )
      },
      {
        id: "4",
        Header: " ",
        Cell: ({ cell: { row } }: { cell: any }) => (
          <div
            className="flex text-sm cursor-pointer z-20"
            onClick={() => {
              setSelectedDistrict(row.original)
              setDeleteIsOpen(true)
            }}
          >
            <img src={DeleteIcon} className="w-4 mr-2" />
            <div className="text-gray-500">Delete</div>
          </div>
        )
      }
    ]
  }, [])

  const { notyf } = useNotyf()
  const [isOpen, setIsOpen] = useState(false)
  const [deleteIsOpen, setDeleteIsOpen] = useState(false)

  const [addDistrictModalOpen, setAddDistrictModalOpen] = useState(false)
  const [selectedDistrict, setSelectedDistrict] = useState<DistrictResponse>()
  const [filteredValue, setFilteredValue] = useState("")

  const { data, error, loading,getDistricts } = useDistricts()
  const { deleteDistrict, success: deleteSuccess } = useDeleteDistrict()

  const selectedOrgId = useStore(state => state.selectedOrganization)

  // filter NO_WORKSHOP name
  const filteredData = useMemo<DistrictResponse[]>(() => {
    if (data?.length && filteredValue.length > 0) {
      return data.filter((distr) => {
          return Object.keys(distr).some((key) => {
            if (typeof key == "string") {
              const districtProperty = distr[key as keyof DistrictResponse]
              if (typeof districtProperty == "string") {
                return districtProperty.toLowerCase().indexOf(filteredValue) != -1 && distr.name !== "NO_WORKSHOP"
              }
            }
          })
        })
    }
    if (data) {
      return data.filter(distr => distr.name !== "NO_WORKSHOP")
    }
    return []
  }, [filteredValue, data])

  const tableInstance = useTable(
    { columns, data: filteredData },
    useSortBy
  )

  const { getTableBodyProps, headerGroups, rows, prepareRow } = tableInstance

  useEffect(() => {
    if (deleteSuccess) {
      notyf?.open({ message: "User deleted successfully", background: "green" })
      setSelectedDistrict(undefined)
      getDistricts()
    }
  }, [deleteSuccess])

  const closeModal = () => {
    setIsOpen(false)
    setSelectedDistrict(undefined)
    getDistricts()
  }

  const openModal = () => {
    setIsOpen(true)
  }

  const handleEditClick = (row: DistrictResponse) => {
    setSelectedDistrict(row)
    openModal()
  }

  const handleEditDistrict = (row: DistrictResponse) => {
    setSelectedDistrict(row)
    setAddDistrictModalOpen(true)
  }

  const handleAddDealerCloseModal = () => {
    getDistricts()
    setAddDistrictModalOpen(false)
  }

  const handleDelete = (districtId?: number) => {
    if (districtId) {
      setDeleteIsOpen(false)
      deleteDistrict(districtId)
    }
  }

  return (
    <div className="w-full">
      <div className="bg-white flex justify-between">
        <div className="flex space-x-2 divide-x-2 items-center px-4 py-2">
          <div className="text-lg">Workshops</div>
          <div className="pl-2">
            <input
              autoComplete="false"
              value={filteredValue}
              type="text"
              className="appearance-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none sm:text-sm"
              placeholder="Filter workshops"
              onChange={(e) => setFilteredValue(e.target.value)}
            />
          </div>
        </div>
        <div
          className="bg-primary text-white px-4 py-1 text-sm cursor-pointer flex items-center"
          onClick={() => {
            setSelectedDistrict(undefined)
            setAddDistrictModalOpen(true)
          }}
        >
          Add Workshop
        </div>
      </div>
      <div className="space-y-4">
        <table {...getTableBodyProps()} className="w-full">
          <thead className="bg-white sticky top-0 border-t border-b">
            {
              // Loop over the header rows
              headerGroups.map((headerGroup) => (
                // Apply the header row props
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {
                    // Loop over the headers in each row
                    headerGroup.headers.map((column) => (
                      // Apply the header cell props
                      <th
                        {...column.getHeaderProps(
                          column.getSortByToggleProps()
                        )}
                        scope="col"
                        className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer hover:bg-gray-50"
                      >
                        {column.render("Header")}
                        <span>
                          {column.isSorted
                            ? column.isSortedDesc
                              ? " 🔽"
                              : " 🔼"
                            : ""}
                        </span>
                      </th>
                    ))
                  }
                </tr>
              ))
            }
          </thead>
          <tbody
            {...getTableBodyProps()}
            className="bg-white divide-y divide-gray-200"
          >
            {
              // Loop over the table rows
              rows.map((row) => {
                // Prepare the row for display
                prepareRow(row)
                return (
                  // Apply the row props
                  <tr
                    {...row.getRowProps()}
                    className="cursor-pointer hover:bg-gray-100"
                  >
                    {
                      // Loop over the rows cells
                      row.cells.map((cell) => {
                        // Apply the cell props
                        return (
                          <td
                            {...cell.getCellProps()}
                            className="px-6 py-4 whitespace-nowrap"
                          >
                            {
                              // Render the cell contents
                              cell.render("Cell")
                            }
                          </td>
                        )
                      })
                    }
                  </tr>
                )
              })
            }
          </tbody>
        </table>
      </div>
      <DistrictModal
        visible={isOpen}
        closeModal={closeModal}
        selectedDistrict={selectedDistrict}
      />
      <AddDistrictModal
        visible={addDistrictModalOpen}
        closeModal={handleAddDealerCloseModal}
        selectedOrgId={selectedOrgId}
        selectedDistrict={selectedDistrict}
      />

      <DeleteDistrictModal
        visible={deleteIsOpen}
        closeModal={() => setDeleteIsOpen(false)}
        userId={selectedDistrict?.district_id}
        onConfirm={handleDelete}
      />
    </div>
  )
}

export default Districts
