import { ReactNode } from "react"

interface Props {
  bgColor: string
  textColor: string
  iconSrc: string
  title: string
  secondTitle?: string
  children: ReactNode
}

const SummaryCard = ({
  bgColor,
  textColor,
  iconSrc,
  title,
  secondTitle,
  children
}: Props) => {
  return (
    <div className="py-3 rounded-md" style={{ backgroundColor: bgColor }}>
      <div
        className="flex space-x-4 pb-2 border-b px-3"
        style={{ borderColor: textColor }}
      >
        <img src={iconSrc} alt="img" />
        <div className="font-bold" style={{ color: textColor }}>
          {title}
        </div>
      </div>
      {secondTitle && (
        <div className="font-semibold text-center text-sm" style={{ color: "#374151" }}>
          {secondTitle}
        </div>
      )}
      <div className="">{children}</div>
    </div>
  )
}

export default SummaryCard
