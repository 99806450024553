import { useEffect, useState } from "react"
import apiClient from "../../../api"
import { UserOrganizationsResponse } from "../../../types/userManagement/userOrganizations"

interface UserOrganizationsData {
  loading: boolean
  error?: string
  data?: UserOrganizationsResponse
  getOrganizations: (userId: number) => void
}

const useUserOrganizations = (): UserOrganizationsData => {
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState<string>()
  const [data, setData] = useState<UserOrganizationsResponse>()

  const getOrganizations = async (userId: number) => {
    setLoading(true)
    try {
      const { data } = await apiClient.get<UserOrganizationsResponse>(
        `/user-management/getRelatedOrganizations?target_user_id=${userId}`
      )
      setData(data)
    } catch (e: any) {
      setError(e)
    }
    setLoading(false)
  }

  return { loading, error, data, getOrganizations }
}

export default useUserOrganizations
