import { Dialog, Transition } from "@headlessui/react"
import { Fragment, useEffect, useMemo, useState } from "react"
import { MultiSelect } from "react-multi-select-component"
import { Option } from "react-multi-select-component/dist/types/lib/interfaces"
import Checkbox from "../../../../components/Input/Checkbox"
import useCreateUser from "../../../../hooks/mutations/useCreateUser"
import useUpdateUser from "../../../../hooks/mutations/useUpdateUser"
import useOrganizationsNoDealer from "../../../../hooks/queries/userManagement/useOrganizationsNoDealer"
import { useNotyf } from "../../../../hooks/useNotyf"
import { User } from "../../../../types/userManagement/dealers"
import RotateRightIcon from "../../../../assets/icons/rotate-right.svg"
import { DistrictResponse } from "../../../../types/userManagement/district"
import useCreateDistrict from "../../../../hooks/queries/district/useCreateUser"
import useUpdateDistrict from "../../../../hooks/queries/district/useUpdateUser"

interface Props {
  visible: boolean
  selectedDistrict?: DistrictResponse
  selectedOrgId?: number
  closeModal: () => void
}

const AddDistrictModal = ({
  visible,
  selectedDistrict,
  selectedOrgId,
  closeModal
}: Props) => {
  const [name, setName] = useState<string>("")

  const { notyf } = useNotyf()
  const { createDistrict, success } = useCreateDistrict()
  const { updateDistrict, success: updateSuccess } = useUpdateDistrict()

  useEffect(() => {
    if (selectedDistrict) {
      setName(selectedDistrict.name)
    } else {
      setName("")
    }
  }, [selectedDistrict])

  useEffect(() => {
    if (success || updateSuccess) {
      setName("")
      closeModal()
    }
  }, [success, updateSuccess])

  useEffect(() => {
    if (success) {
      notyf?.success("District was added successfully")
      closeModal()
    }
  }, [success])

  useEffect(() => {
    if (updateSuccess) {
      notyf?.success("District was updated successfully")
      closeModal()
    }
  }, [updateSuccess])

  const handleNameInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    setName(e.target.value)
  }

  const handleConfirm = () => {
    if (name.length == 0) {
      return notyf?.error({ message: "Please complete all the fields" })
    }
    const formData = new FormData()
    formData.append("district_name", name)
    if (!!selectedDistrict) {
      formData.append(
        "target_district_id",
        selectedDistrict.district_id.toString()
      )
      updateDistrict(formData)
    } else {
      if(selectedOrgId) {
        formData.append("target_organization_id", selectedOrgId.toString())
        createDistrict(formData)
      }
    }
  }

  const { data } = useOrganizationsNoDealer()

  const options = useMemo<Option[]>(() => {
    if (data?.organizations.length) {
      return data.organizations.map((org) => ({
        label: org.organizationName,
        value: org.id
      }))
    }

    return []
  }, [data])

  return (
    <>
      <Transition appear show={visible} as={Fragment}>
        <Dialog
          as="div"
          className="fixed inset-0 z-10 overflow-y-auto"
          onClose={closeModal}
        >
          <Dialog.Overlay className="fixed inset-0 bg-black opacity-30" />
          <div className="min-h-screen px-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className="inline-block h-screen align-middle"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <div className="inline-block w-full max-w-lg p-6 my-8 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl">
                <div className="flex justify-between items-center">
                  {/* // search all organizations that dont have dealer, when button is clicked */}
                  <Dialog.Title
                    as="h3"
                    className="text-lg font-medium leading-6 text-gray-900"
                  >
                    {!!selectedDistrict ? "Edit" : "Add"} Workshop
                  </Dialog.Title>
                </div>

                <div className="mt-2">
                  <div className="mb-4">
                    <label className="sr-only">Workshop name</label>
                    <input
                      value={name}
                      type="text"
                      className="appearance-none rounded-3xl relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-primary focus:border-primary focus:z-10 sm:text-sm"
                      placeholder="Name"
                      onChange={handleNameInput}
                    />
                  </div>
                </div>

                <div className="flex w-full items-center justify-end">
                  <button
                    type="button"
                    className="inline-flex justify-center px-4 py-2 text-sm font-medium text-blue-900 bg-blue-100 border border-transparent rounded-md hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-500"
                    onClick={handleConfirm}
                  >
                    Confirm
                  </button>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>
    </>
  )
}

export default AddDistrictModal
