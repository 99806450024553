import { Notyf } from "notyf"
import { useEffect } from "react"
import shallow from "zustand/shallow"
import useStore from "../store"

export interface NotyfData {
  notyf?: Notyf
}

export const useNotyf = (): NotyfData => {
  const { notyf, setNotyf } = useStore(
    (state) => ({
      notyf: state.notyf,
      setNotyf: state.setNotyf
    }),
    shallow
  )

  useEffect(() => {
    if (!notyf) {
      setNotyf(
        new Notyf({ dismissible: true, position: { x: "right", y: "top" } })
      )
    }
  }, [notyf])

  return {
    notyf
  }
}
