import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import shallow from "zustand/shallow"
import Battery from "../../../assets/icons/homepage/battery.svg"
import Co2 from "../../../assets/icons/homepage/co2.svg"
import Distance from "../../../assets/icons/homepage/distance.svg"
import FuelWarn from "../../../assets/icons/homepage/fuel-warn.svg"
import Fuel from "../../../assets/icons/homepage/fuel.svg"
import Hour from "../../../assets/icons/homepage/hour.svg"
import Sweeper from "../../../assets/icons/homepage/sweeper.svg"
import Water from "../../../assets/icons/homepage/water.svg"
import useOverview from "../../../hooks/queries/useOverview"
import useStore from "../../../store"
import DashboardContainer from "../../DashboardContainer"
import HomeCard from "./HomeCard"

const HomePage = () => {
  const { loading, data } = useOverview()
  const { t } = useTranslation()

  const { filterMenuVisible, toggleFilterMenuVisible } = useStore(
    (state) => ({
      filterMenuVisible: state.filterMenuVisible,
      toggleFilterMenuVisible: state.toggleFilterMenuVisible
    }),
    shallow
  )

  const [selectedTitle, setSelectedTitle] = useState<string | null>(null)

  useEffect(() => {
    if (filterMenuVisible) {
      toggleFilterMenuVisible()
    }
  }, [filterMenuVisible])

  const handleCollapseClick = (title: string) => {
    if (selectedTitle === title) {
      return setSelectedTitle(null)
    }
    setSelectedTitle(title)
  }

  const commonProps = {
    isLoading: loading,
    onCollapse: handleCollapseClick,
    selectedTitle
  }
  return (
    <DashboardContainer
      title={t("home.homepage")}
      optionalLabel={
        <span className="text-gray-600">
          {data &&
            `This data is last week (${data.current_week_date}, week ${data.current_week_number}), compared to the week before (${data.previous_week_date}, week ${data.previous_week_number})`}
        </span>
      }
      hideFilter
    >
      <div className="flex w-full flex-col md:flex-row">
        {/* <div className="w-full md:w-2/12 lg:w-1/5">
          <div className="mb-6">
            {data && <YesterdayCard data={data.yesterday_box_data} />}
          </div>
        </div> */}
        <div className="m-0 w-full md:w-6/12 lg:w-1/2 md:mx-5">
          <HomeCard
            title={t("home.total_active_machines")}
            // description={t("home.weekly_average")}
            description={""}
            amount={data?.dashboard_data.total_active_vehicles}
            percentage={data?.dashboard_data.total_active_vehicles_delta}
            icon={Sweeper}
            contributionList={data?.contribution_lists.active_vehicles}
            deltaColor={data?.dashboard_data.total_active_vehicles_delta_color}
            {...commonProps}
          />
          <HomeCard
            title={t("home.total_inactive_machines")}
            description={""}
            // description={t("home.weekly_average")}
            amount={data?.dashboard_data.total_inactive_vehicles}
            percentage={data?.dashboard_data.total_inactive_vehicles_delta}
            contributionList={data?.contribution_lists.inactive_vehicles}
            deltaColor={
              data?.dashboard_data.total_inactive_vehicles_delta_color
            }
            icon={Sweeper}
            {...commonProps}
          />
          <HomeCard
            title={t("home.total_km_swept")}
            description={""}
            // description={t("home.week_km")}
            amount={data?.dashboard_data.total_sweep_distance}
            percentage={data?.dashboard_data.total_sweep_distance_delta}
            deltaColor={data?.dashboard_data.total_sweep_distance_delta_color}
            contributionList={data?.contribution_lists.total_km_swept}
            icon={Distance}
            {...commonProps}
          />
          <HomeCard
            {...commonProps}
            title={"Total km worked"}
            description={""}
            // description={t("home.week_km")}
            amount={data?.dashboard_data.total_distance}
            percentage={data?.dashboard_data.total_distance_delta}
            deltaColor={data?.dashboard_data.total_sweep_distance_delta_color}
            contributionList={data?.contribution_lists.total_km_worked}
            icon={Distance}
          />
          <HomeCard
            {...commonProps}
            title={t("home.total_sweep_hours")}
            description={""}
            // description={t("home.week_hours")}
            amount={data?.dashboard_data.total_sweep_hours}
            percentage={data?.dashboard_data.total_sweep_hours_delta}
            deltaColor={data?.dashboard_data.total_sweep_hours_delta_color}
            contributionList={data?.contribution_lists.total_sweep_hours}
            icon={Hour}
          />

          <HomeCard
            {...commonProps}
            title={t("home.total_co2_emitted")}
            description={""}
            amount={data?.dashboard_data.total_co2_emission_kg}
            percentage={data?.dashboard_data.total_co2_emission_kg_delta}
            deltaColor={data?.dashboard_data.total_co2_emission_kg_delta_color}
            contributionList={data?.contribution_lists.total_co2_emissions}
            icon={Sweeper}
          />

          {/* <HomeCard
            isLoading={loading}
            title={t("home.electric_potential")}
            description={t("home.ev_savings_1000")}
            amount={data?.dashboard_data.electric_potential_save}
            percentage={data?.dashboard_data.electric_potential_save_delta}
            icon={Eur}
          /> */}
        </div>
        <div className="m-0 w-full md:w-6/12 lg:w-1/2 md:mr-5">
          <HomeCard
            {...commonProps}
            title={t("home.total_fuel_use")}
            description={""}
            amount={data?.dashboard_data.total_fuel_consumption}
            percentage={data?.dashboard_data.total_fuel_consumption_delta}
            deltaColor={data?.dashboard_data.total_fuel_consumption_delta_color}
            contributionList={data?.contribution_lists.total_fuel_use}
            icon={Fuel}
          />
          <HomeCard
            {...commonProps}
            title={t("home.avg_fuel_use_l")}
            description={""}
            amount={data?.dashboard_data.average_fuel_usage}
            percentage={data?.dashboard_data.average_fuel_usage_delta}
            deltaColor={data?.dashboard_data.average_fuel_usage_delta_color}
            contributionList={data?.contribution_lists.avg_engine_fuel_rate}
            icon={Fuel}
          />
          <HomeCard
            {...commonProps}
            title={"Total kWh use"}
            description={""}
            amount={data?.dashboard_data.total_electric_consumption_kwh}
            percentage={
              data?.dashboard_data.total_electric_consumption_kwh_delta
            }
            contributionList={data?.contribution_lists.total_kwh_use}
            icon={Battery}
          />
          <HomeCard
            {...commonProps}
            title={"Avg. electricity use in kW"}
            description={""}
            amount={data?.dashboard_data.average_battery_usage}
            percentage={data?.dashboard_data.average_battery_usage_delta}
            contributionList={data?.contribution_lists.avg_battery_usage_kw}
            icon={Battery}
          />
          <HomeCard
            {...commonProps}
            title={t("home.total_water_use")}
            description={""}
            amount={data?.dashboard_data.total_water_consumption}
            percentage={data?.dashboard_data.total_water_consumption_delta}
            deltaColor={
              data?.dashboard_data.total_water_consumption_delta_color
            }
            contributionList={data?.contribution_lists.total_water_use}
            icon={Water}
          />
          <HomeCard
            {...commonProps}
            title={t("home.avg_water")}
            description={""}
            amount={data?.dashboard_data.water_usage_per_km_swept}
            percentage={data?.dashboard_data.water_usage_per_km_swept_delta}
            contributionList={data?.contribution_lists.avg_water_use}
            deltaColor={
              data?.dashboard_data.water_usage_per_km_swept_delta_color
            }
            icon={Water}
          />
        </div>
      </div>
    </DashboardContainer>
  )
}

export default HomePage
