import { Dialog, Transition } from "@headlessui/react"
import { Fragment, useEffect, useMemo, useState } from "react"
import { MultiSelect } from "react-multi-select-component"
import { Option } from "react-multi-select-component/dist/types/lib/interfaces"
import useCreateUser from "../../../../hooks/mutations/useCreateUser"
import useCityAdmins from "../../../../hooks/queries/userManagement/useCityAdmins"
import { User } from "../../../../types/userManagement/fesAdmins"
import RotateRightIcon from "../../../../assets/icons/rotate-right.svg"
import Checkbox from "../../../../components/Input/Checkbox"
import useUpdateUser from "../../../../hooks/mutations/useUpdateUser"
import { useNotyf } from "../../../../hooks/useNotyf"
import { VehicleResponse } from "../../../../types/vehicle"

interface Props {
  visible: boolean
  closeModal: () => void
  selectedVehicle: VehicleResponse | null
}

const VehicleModal = ({ visible, closeModal, selectedVehicle }: Props) => {
  const [name, setName] = useState<string>("")

  const { notyf } = useNotyf()
  const { createUser, success } = useCreateUser()
  const { updateUser, success: updateSuccess } = useUpdateUser()

  useEffect(() => {
    if (selectedVehicle) {
      setName(selectedVehicle.name)
    } else {
      setName("")
    }
  }, [selectedVehicle])

  useEffect(() => {
    if (success) {
      notyf?.success("User was added successfully")
      closeModal()
    }
  }, [success])

  useEffect(() => {
    if (updateSuccess) {
      notyf?.success("User was updated successfully")
      closeModal()
    }
  }, [updateSuccess])

  const handleNameInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    setName(e.target.value)
  }

  const handleConfirm = () => {
    if (name.length == 0) {
      return notyf?.error({ message: "Please complete all the fields" })
    }
    const formData = new FormData()
    // formData.append("first_name", firstName)
    // formData.append("last_name", lastName)

    // if (!!selectedUser) {
    //   formData.append("user_active", active.toString())
    //   formData.append("target_user_id", selectedUser.id.toString())
    //   updateUser(formData)
    // } else {
    //   if (password.length == 0) {
    //     return notyf?.error({ message: "Password is required" })
    //   }
    //   formData.append("email", email)

    //   formData.append("user_role", "FES_ADMIN")
    //   formData.append("password", password)
    //   createUser(formData)
    // }
  }

  return (
    <>
      <Transition appear show={visible} as={Fragment}>
        <Dialog
          as="div"
          className="fixed inset-0 z-10 overflow-y-auto"
          onClose={closeModal}
        >
          <Dialog.Overlay className="fixed inset-0 bg-black opacity-30" />
          <div className="min-h-screen px-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className="inline-block h-screen align-middle"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <div className="inline-block w-full max-w-lg p-6 my-8 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl">
                <div className="flex justify-between items-center">
                  {/* // search all organizations that dont have dealer, when button is clicked */}
                  <Dialog.Title
                    as="h3"
                    className="text-lg font-medium leading-6 text-gray-900"
                  >
                    {!!selectedVehicle ? "Edit" : "Add"} Vehicle
                  </Dialog.Title>
                </div>
                <div className="mt-2">
                  <div className="mb-4">
                    <label className="sr-only">Email address</label>
                    <input
                      value={name}
                      type="text"
                      className="appearance-none rounded-3xl relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-primary focus:border-primary focus:z-10 sm:text-sm"
                      placeholder="Vehicle name"
                      onChange={handleNameInput}
                    />
                  </div>
                </div>

                <div className="flex w-full items-center justify-end">
                  <button
                    type="button"
                    className="inline-flex justify-center px-4 py-2 text-sm font-medium text-blue-900 bg-blue-100 border border-transparent rounded-md hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-500"
                    onClick={handleConfirm}
                  >
                    Confirm
                  </button>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>
    </>
  )
}

export default VehicleModal
