import axios from "axios"
import { useState } from "react"
import { useNavigate } from "react-router"
import shallow from "zustand/shallow"
import apiClient from "../api"
import useStore from "../store"
import notyf from "../util/notyf"
import useMyOrganizations from "./queries/userManagement/useMyOrganizations"
export interface AuthData {
  loggedIn?: boolean
  errors: any[]
  authenticate: (email: string, password: string) => void
  logout: () => void
}

export const useAuth = (): AuthData => {
  const [errors, setErrors] = useState<any[]>([])

  const { getOrganizations } = useMyOrganizations()
  const { setAccessToken, loggedIn } = useStore(
    (state) => ({
      setAccessToken: state.setAccessToken,
      loggedIn: state.loggedIn
    }),
    shallow
  )

  const authenticate = async (
    email: string,
    password: string
  ): Promise<void> => {
    if (!email || !password) {
      notyf.error("Email and password is required")
      return
    }
    var bodyFormData = new FormData()
    bodyFormData.append("email", email)
    bodyFormData.append("password", password)
    try {
      const { data } = await axios.post<{ access: string }>(
        `${process.env.REACT_APP_API_BASE_URL}/token`,
        bodyFormData,
        {
          withCredentials: true
        }
      )
      setAccessToken(data.access)
      getOrganizations(data.access)
    } catch (error: any) {
      console.info("error signinrequest ", error)
      notyf.error(error.response.data.detail)
      // setErrors(error.response.data.detail)
    }
  }

  const logout = async () => {
    try {
      await apiClient.get("/token/logout", {
        withCredentials: true
      })
      window.location.href = "/"
    } catch (e) {}
  }

  return {
    loggedIn,
    errors,
    authenticate,
    logout
  }
}
