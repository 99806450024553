import Card from "../../../components/Cards"
import ContentLoader from "react-content-loader"
import ArrowDown from "../../../assets/icons/arrow_down.svg"
import ArrowUp from "../../../assets/icons/arrow_up.svg"
import CollapseIcon from "../../../assets/icons/collapse.svg"
import { useEffect, useMemo, useState } from "react"
import { ActiveVehicle, DeltaColor } from "../../../types/home"
import Arrow from "./Arrow"

interface Props {
  isLoading: boolean
  title: string
  description: string
  amount?: number | string
  percentage?: number
  icon: string
  deltaColor?: DeltaColor
  contributionList?: ActiveVehicle[]
  onCollapse: (title: string) => void
  selectedTitle: string | null
}

const HomeCard = ({
  isLoading,
  title,
  description,
  amount,
  percentage,
  icon,
  deltaColor,
  contributionList,
  onCollapse,
  selectedTitle
}: Props) => {
  const arrowDirection = !percentage ? null : percentage > 0 ? "up" : "down"

  const calculatedAmount = useMemo(() => {
    if (!amount) {
      return null
    }

    if (typeof amount === "number") {
      if (amount > 100) {
        return amount.toFixed()
      }
      return amount
    }

    const amountFloat = parseFloat(amount)

    if (amountFloat > 100) {
      return amountFloat.toFixed()
    }
    if (amountFloat == 0) {
      return null
    }
    return amountFloat
  }, [amount])

  if (isLoading) {
    return (
      <Card>
        <ContentLoader viewBox="0 0 380 41">
          {/* Only SVG shapes */}
          <rect x="0" y="5" rx="5" ry="5" width="35" height="35" />
          <rect x="80" y="5" rx="4" ry="4" width="300" height="13" />
          <rect x="80" y="30" rx="3" ry="3" width="250" height="10" />
        </ContentLoader>
      </Card>
    )
  }

  return (
    <>
      <Card className={selectedTitle === title ? "rounded-b-none" : ""}>
        <img
          src={CollapseIcon}
          className="cursor-pointer"
          onClick={() => onCollapse(title)}
        />
        <div className="p-2 bg-primary rounded-md" style={{ minWidth: "4rem" }}>
          <img
            src={icon}
            alt="icon"
            className=" md:w-10 md:h-10 lg:w-12 lg:h-12"
          />
        </div>
        <div className="flex flex-auto flex-col justify-center">
          <div className="font-bold text-primary text-sm lg:text-base">
            {title}
          </div>
          <div className="text-primary">{description}</div>
        </div>
        <div className="flex items-end lg:items-center justify-center flex-col lg:flex-row">
          <span
            className={`text-sm md:text-lg lg:text-2xl xl:text-4xl font-bold text-center mr-2 text-primary ${
              !calculatedAmount ? "text-yellow-500" : ""
            }`}
          >
            {calculatedAmount ?? "-"}
          </span>
          {!!percentage && (
            <div
              className={`flex items-center space-x-2 rounded-2xl px-2 py-1 ${
                deltaColor == DeltaColor.Green ? "bg-green-600" : "bg-red-600"
              }`}
            >
              {arrowDirection && (
                <Arrow
                  color={
                    deltaColor === DeltaColor.Green ? "#219653" : "#EB5757"
                  }
                  direction={arrowDirection}
                />
              )}
              <span className="text-white font-semibold text-xs sm:text-sm lg:text-md">
                {percentage.toString()}
              </span>
            </div>
          )}
        </div>
      </Card>

      {selectedTitle === title && (
        <div className="bg-white px-4 py-2">
          <div className="max-w-md mx-auto">
            <div className="max-h-56 overflow-y-auto">
              <table className="table-auto w-full">
                <thead>
                  <tr>
                    <th className="text-bold text-primary text-sm md:text-base lg:text-lg">Vehicle name</th>
                    <th className="text-bold text-primary text-sm md:text-base lg:text-lg">Value</th>
                  </tr>
                </thead>
                <tbody>
                  {contributionList?.map((item, index) => (
                    <tr className="border-b">
                      <td className="py-2 px-4 text-primary text-sm md:text-base lg:text-lg">
                        {index + 1}. {item.equipment_name}
                      </td>
                      <td className="py-2 px-4 text-primary">{item.value}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      )}
    </>
  )
}

export default HomeCard
