import { useEffect, useState } from "react"
import shallow from "zustand/shallow"
import useStore from "../../../store"

const ElectricVehicleCheckbox = ({}) => {
  const { isElectricVehicle, setIsElectricVehicle } = useStore(
    (state) => ({
      isElectricVehicle: state.isElectricVehicle,
      setIsElectricVehicle: state.setIsElectricVehicle
    }),
    shallow
  )

  const handleCheckboxChange = (key: boolean) => {
    setIsElectricVehicle(key)
  }

  return (
    <div className="toggleWrapper">
      <span className="mr-2">Electric vehicle</span>
      <input
        type="checkbox"
        name="toggle1"
        className="mobileToggle"
        id="toggle1"
        checked={isElectricVehicle ?? false}
        onChange={() => handleCheckboxChange(!isElectricVehicle)}
      />
      <label htmlFor="toggle1" className="cursor-pointer" />
    </div>
  )
}

export default ElectricVehicleCheckbox
