const AddMember = () => {
  const handleSubmit = (e?: React.FormEvent<HTMLFormElement>) => {
    e?.preventDefault()
    e?.stopPropagation()
  }

  return (
    <div className="relative mb-20 max-w-lg mx-auto text-center">
      <div className="mb-8 py-8 px-6 bg-white rounded shadow-form">
        <span className="text-primary font-bold text-lg">Add Member</span>
        <h3 className="mt-2 mb-6 text-2xl text-gray-500">
          Unavailable
          {/* Make sure to provide a valid email */}
        </h3>
        <form onSubmit={handleSubmit}>
          <div className="mb-6 pr-4 flex items-center border rounded-lg">
            <input
              className="py-4 pl-4 mr-4 w-full placeholder-blueGray-400 bg-white focus:outline-none border-0 rounded-l-lg"
              placeholder="Member's e-mail"
              type="email"
            />
            <svg
              className="w-6 h-6 text-blueGray-300"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M16 12a4 4 0 10-8 0 4 4 0 008 0zm0 0v1.5a2.5 2.5 0 005 0V12a9 9 0 10-9 9m4.5-1.206a8.959 8.959 0 01-4.5 1.207"
              ></path>
            </svg>
          </div>
          <button
           className="w-full py-4 bg-primary hover:bg-primary rounded text-white text-sm font-bold"
            type="submit"
          >
            Add Member
          </button>
        </form>
      </div>
    </div>
  )
}

export default AddMember
