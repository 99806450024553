import { useTranslation } from "react-i18next"
import { MultiSelect } from "react-multi-select-component"
import shallow from "zustand/shallow"
import useDistricts from "../../../hooks/queries/useDistricts"
import useStore from "../../../store"
import FilterSection from "../FilterSection"

const DistrictFilter = () => {
  
  const { t } = useTranslation()

  const { data, selectedDistricts, setSelectedDistricts } = useStore(
    (state) => ({
      selectedDistricts: state.selectedDistricts,
      data: state.districts,
      setSelectedDistricts: state.setSelectedDistricts
    }),
    shallow
  )

  const options = data.map((vehicle) => ({
    label: vehicle.name,
    value: vehicle.district_id
  }))

  return (
    <FilterSection title={t("filter_comp.workshop")} className="font-bold">
      <MultiSelect
        ClearSelectedIcon={true}
        options={options}
        value={selectedDistricts ?? []}
        onChange={setSelectedDistricts}
        labelledBy="Select"
      />
    </FilterSection>
  )
}

export default DistrictFilter
