interface Props {
  active?: boolean
}
const HeatmapDistrictIcon = ({ active }: Props) => {
  return (
    <svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        id="icon/maps/add_location_24px"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 2C8.14 2 5 5.14 5 9C5 14.25 12 22 12 22C12 22 19 14.25 19 9C19 5.14 15.86 2 12 2ZM7 9C7 6.24 9.24 4 12 4C14.76 4 17 6.24 17 9C17 11.88 14.12 16.19 12 18.88C9.92 16.21 7 11.85 7 9ZM11 8V6H13V8H15V10H13V12H11V10H9V8H11Z"
        fill={active ? "#0055A0" : "#fff"}
      />
    </svg>
  )
}

export default HeatmapDistrictIcon
