import { Wrapper } from "@googlemaps/react-wrapper"
import React, { Children, cloneElement, isValidElement } from "react"
import Map from "./Map"

interface Props {
  loading?: boolean
  children?: React.ReactChild | React.ReactChild[]
}

const render = () => {
  return <h1>Please wait...</h1>
}

export const GMapWrapper = ({ loading, children }: Props) => {
  return (
    <Wrapper
      apiKey={process.env.REACT_APP_GOOGLE_API_KEY}
      render={render}
      libraries={["visualization"]}
    >
      <Map
        center={{ lat: 48.8553785, lng: 2.345 }}
        disableDefaultUI
        loading={loading}
        zoom={12}
        style={{
          flexGrow: "1",
          height: "100%",
          margin: "0",
          position: "relative"
        }}
      >
        {Children.map(children, (child) => {
          if (isValidElement(child)) {
            // set the map prop on the child component
            return cloneElement(child)
          }
        })}
      </Map>
    </Wrapper>
  )
}
