import { Column, useSortBy, useTable } from "react-table"
import Scrollbars from "react-custom-scrollbars"
import { useEffect, useMemo, useState } from "react"
import RotateRightIcon from "../../../assets/icons/rotate-right.svg"
import DeleteIcon from "../../../assets/icons/trash.svg"
import { useNavigate } from "react-router-dom"
import useStore from "../../../store"
import RegularUserModal from "./RegularUserModal"
import useRegularUsers from "../../../hooks/queries/userManagement/useRegularUsers"
import {
  RegularUser,
  RegularUserResponse
} from "../../../types/userManagement/regularUsers"
import EditIcon from "../../../assets/icons/edit.svg"
import useDeleteUser from "../../../hooks/mutations/useDeleteUser"
import DeleteModal from "./DeleteModal"
import { useNotyf } from "../../../hooks/useNotyf"
import AddUserModal from "./AddUserModal"

const UserManagement = () => {
  const [isOpen, setIsOpen] = useState(false)
  const [isOpenAddUser, setIsOpenAddUser] = useState(false)
  const [deleteIsOpen, setDeleteIsOpen] = useState(false)
  const [selectedUser, setSelectedUser] = useState<RegularUser | null>(null)
  const { data, getUsers } = useRegularUsers()
  const [filteredValue, setFilteredValue] = useState("")
  const { notyf } = useNotyf()

  const columns: Column<RegularUser>[] = useMemo(() => {
    return [
      {
        Header: "Name",
        accessor: "first_name",
        Cell: ({
          cell: {
            row: { original }
          }
        }) => (
          <div className="text-sm">
            <div className="text-gray-800 font-semibold">
              {original.first_name}
            </div>{" "}
            <div className="text-gray-600">{original.last_name}</div>
          </div>
        )
      },
      {
        Header: "Email",
        accessor: "email"
      },
      // {
      //   Header: "Role",
      //   accessor: "user_role",
      //   Cell: ({
      //     cell: {
      //       row: { values }
      //     }
      //   }) => (
      //     <span className="rounded-xl px-2 py-1 bg-green-400 text-white text-xs">
      //       {values.user_role}
      //     </span>
      //   )
      // },
      {
        Header: "Active",
        accessor: "user_active",
        Cell: ({
          cell: {
            row: { values }
          }
        }) => (
          <div className="">{values.user_active ? "Active" : "Inactive"}</div>
        )
      },
      {
        id: "0",
        Header: "  ",
        Cell: ({ cell: row }: { cell: any }) => (
          <div
            className="flex text-sm cursor-pointer"
            onClick={() => handleEditUser(row.row.original)}
          >
            <img src={EditIcon} className="w-4 mr-2" />
            <div className="text-gray-500">Edit</div>
          </div>
        )
      },
      {
        id: "1",
        Header: " ",
        Cell: ({
          cell: {
            row: { original }
          }
        }: {
          cell: any
        }) => (
          <div
            className="flex text-sm cursor-pointer"
            onClick={() => {
              setSelectedUser(original)
              setDeleteIsOpen(true)
            }}
          >
            <img src={DeleteIcon} className="w-4 mr-2" />
            <div className="text-gray-500">Delete</div>
          </div>
        )
      }
    ]
  }, [])

  const selectedOrg = useStore((q) => q.selectedOrganization)
  const { deleteUser, success: successDelete } = useDeleteUser()

  const filteredData = useMemo<RegularUserResponse>(() => {
    if (data?.users.length && filteredValue.length > 0) {
      return {
        users: data.users.filter((user) => {
          return Object.keys(user).some((key) => {
            if (typeof key == "string") {
              const userProperty = user[key as keyof RegularUser]

              if (typeof userProperty == "string") {
                return (
                  userProperty.toLowerCase().indexOf(filteredValue) != -1 &&
                  user.organization == selectedOrg
                )
              }
            }
          })
        })
      }
    }
    if (data) {
      return { users: data.users.filter((q) => q.organization == selectedOrg) }
    }
    return { users: [] } as RegularUserResponse
  }, [filteredValue, data, selectedOrg])

  const activeInactive = useMemo(() => {
    if (!filteredData?.users) {
      return [0, 0]
    }
    const allUsers = filteredData.users.length
    const inactiveUsers = filteredData.users.filter((q) => !q.user_active)
    return [allUsers, inactiveUsers.length]
  }, [filteredData])

  const tableInstance = useTable(
    { columns, data: filteredData.users },
    useSortBy
  )

  const { getTableBodyProps, headerGroups, rows, prepareRow } = tableInstance

  useEffect(() => {
    if (successDelete) {
      notyf?.open({ message: "User deleted successfully", background: "green" })
      getUsers()
    }
  }, [successDelete])

  const closeModal = () => {
    setSelectedUser(null)
    getUsers()
    setIsOpen(false)
  }

  const closeModalAddUser = () => {
    setIsOpenAddUser(false)
  }

  const openModal = () => {
    setIsOpen(true)
  }

  const handleEditUser = (user: RegularUser) => {
    setSelectedUser(user)
    openModal()
  }

  const handleDeleteClick = (userId?: number) => {
    if (userId) {
      setDeleteIsOpen(false)
      deleteUser(userId)
    }
  }

  return (
    <div className="w-full">
      <div className="bg-white flex justify-between">
        <div className="flex space-x-2 divide-x-2 items-center px-4 py-2">
          <div className="text-lg">All Users</div>
          <div className="text-gray-600 text-sm pl-2">
            {activeInactive[0]} total {activeInactive[1]} inactive
          </div>
          <div className="pl-2">
            <input
              autoComplete="false"
              value={filteredValue}
              type="text"
              className="appearance-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none sm:text-sm"
              placeholder="Filter users"
              onChange={(e) => setFilteredValue(e.target.value)}
            />
          </div>
        </div>
        <div
          className="bg-primary text-white px-4 py-1 text-sm cursor-pointer flex items-center"
          onClick={() => setIsOpenAddUser(true)}
        >
          Add User
        </div>
      </div>
      <div className="space-y-4">
        <table {...getTableBodyProps()} className="w-full">
          <thead className="bg-white sticky top-0 border-t border-b">
            {
              // Loop over the header rows
              headerGroups.map((headerGroup) => (
                // Apply the header row props
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {
                    // Loop over the headers in each row
                    headerGroup.headers.map((column) => (
                      // Apply the header cell props
                      <th
                        {...column.getHeaderProps(
                          column.getSortByToggleProps()
                        )}
                        scope="col"
                        className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer hover:bg-gray-50"
                      >
                        {column.render("Header")}
                        <span>
                          {column.isSorted
                            ? column.isSortedDesc
                              ? " 🔽"
                              : " 🔼"
                            : ""}
                        </span>
                      </th>
                    ))
                  }
                </tr>
              ))
            }
          </thead>
          <tbody
            {...getTableBodyProps()}
            className="bg-white divide-y divide-gray-200"
          >
            {
              // Loop over the table rows
              rows.map((row) => {
                // Prepare the row for display
                prepareRow(row)
                return (
                  // Apply the row props
                  <tr {...row.getRowProps()}>
                    {
                      // Loop over the rows cells
                      row.cells.map((cell) => {
                        // Apply the cell props
                        return (
                          <td
                            {...cell.getCellProps()}
                            className="px-6 py-4 whitespace-nowrap"
                          >
                            {
                              // Render the cell contents
                              cell.render("Cell")
                            }
                          </td>
                        )
                      })
                    }
                  </tr>
                )
              })
            }
          </tbody>
        </table>
      </div>

      <RegularUserModal
        visible={isOpen}
        closeModal={closeModal}
        selectedUser={selectedUser}
      />
      <AddUserModal visible={isOpenAddUser} closeModal={closeModalAddUser} />
      <DeleteModal
        visible={deleteIsOpen}
        closeModal={() => setDeleteIsOpen(false)}
        userId={selectedUser?.id}
        onConfirm={handleDeleteClick}
      />
    </div>
  )
}

export default UserManagement
