import Select, { GroupBase, Props } from "react-select"

const customStyles: any = {
  control: (provided: any, state: any) => ({
    ...provided,
    borderRadius: 45,
    color: "#0055a0",
    borderColor: "#0055a0",
    "&:hover, &:focus": {
      borderColor: "#0055a0"
    }
  })
}

const CustomFilterInput = <
  Option,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>
>(
  props: Props<Option, IsMulti, Group>
) => {
  return <Select {...props} styles={customStyles} />
}

export default CustomFilterInput
