import { format, fromUnixTime } from "date-fns"
import { useMemo } from "react"
import { useTranslation } from "react-i18next"
import shallow from "zustand/shallow"
import Card from "../../../components/Cards"
import { GMapWrapper } from "../../../components/Maps"
import Polygon from "../../../components/Maps/Polygon"
import useHeatMapDistrict from "../../../hooks/queries/useHeatMapDistrict"
import useStore from "../../../store"
import DashboardContainer from "../../DashboardContainer"
import HeatMapDistrictFilters from "./HeatMapDistrictFilters"

const HeatMapDistrict = () => {
  const { t } = useTranslation()

  const { loading, data, heatMapDistrictMeasure, getData } =
    useHeatMapDistrict()
  const { filterMenuVisible, startDate, endDate } = useStore(
    (state) => ({
      filterMenuVisible: state.filterMenuVisible,
      startDate: state.startDate,
      endDate: state.endDate
    }),
    shallow
  )
  const measuredFilterData = useMemo(() => {
    if (loading || !data || !heatMapDistrictMeasure) {
      return { low: [], medium: [], high: [] }
    }

    return data[heatMapDistrictMeasure]
  }, [heatMapDistrictMeasure, loading, data])

  const getTitle = () => {
    const options = [
      {
        key: "by_sweep_hours",
        label: t("heatmap_district.time_working")
      },
      {
        key: "by_working_days",
        label: t("heatmap_district.vehicle_count")
      },
      {
        key: "by_sweep_hours_per_vehicle",
        label: t("heatmap_district.avg_time_working")
      }
    ]

    const selectedOption = options.find(
      (item) => item.key === heatMapDistrictMeasure
    )

    return selectedOption?.label
  }

  return (
    <DashboardContainer
      title="Heatmap District"
      optionalLabel={
        <span className="text-gray-600">
          {data &&
            `Start date: ${format(
              fromUnixTime(startDate as number),
              "dd-MM-yyyy"
            )}, End date: ${format(
              fromUnixTime(endDate as number),
              "dd-MM-yyyy"
            )}`}
        </span>
      }
    >
      <div
        className="flex space-x-2 justify-between"
        style={{ height: "calc(100% - 6rem" }}
      >
        <div className={filterMenuVisible ? "w-full lg:w-10/12" : "w-full"}>
          <Card
            title={t("heatmap_district.sweeper_management")}
            description={t("heatmap_district.heatmap_district")}
            additionalTitle={getTitle()}
            inlineHeader
            className="h-full"
          >
            <GMapWrapper loading={loading}>
              <Polygon data={measuredFilterData} />
            </GMapWrapper>
          </Card>
        </div>
        {filterMenuVisible && (
          <div
            className={`transform absolute shadow-xl right-0 bottom-4 top-32 w-56 mx-auto z-20 transition duration-200 ease-in-out lg:shadow-none lg:top-0 lg:bottom-0 lg:right-auto lg:relative lg:w-80 ${
              filterMenuVisible ? "translate-x-0" : "translate-x-full"
            }`}
          >
            <HeatMapDistrictFilters onApplyFilter={getData} />
          </div>
        )}
      </div>
    </DashboardContainer>
  )
}

export default HeatMapDistrict
