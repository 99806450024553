import { ReactNode } from "react"
interface FilterProps {
  hideHeader?: boolean
  children: ReactNode
}

const Filter = ({ children }: FilterProps) => {
  return (
    <div className="rounded-lg h-full">
      {children}
    </div>
  )
}

export default Filter
