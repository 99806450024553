import { useEffect, useMemo } from "react"
import { useTranslation } from "react-i18next"
import { MultiSelect } from "react-multi-select-component"
import { Option } from "react-multi-select-component/dist/types/lib/interfaces"
import shallow from "zustand/shallow"
import useVehicles from "../../../hooks/queries/useVehicles"
import useStore from "../../../store"
import FilterSection from "../FilterSection"

const maxOptions = 10
const LimitedEquipmentFilter = () => {
  const { t } = useTranslation()

  const {
    filteredEquipments,
    selectedLimitedEquipments,
    setSelectedLimitedEquipments
  } = useStore(
    (state) => ({
      filteredEquipments: state.filteredEquipments,
      selectedLimitedEquipments: state.selectedLimitedEquipments,
      setSelectedLimitedEquipments: state.setSelectedLimitedEquipments
    }),
    shallow
  )

  const options = useMemo(() => {
    if (!filteredEquipments) {
      return []
    }

    if(selectedLimitedEquipments && selectedLimitedEquipments.length >= maxOptions) {
      const selectedEqs = selectedLimitedEquipments.map(eq => eq.value)
      return filteredEquipments.map((item) => ({
        disabled: !selectedEqs.includes(item.equipment_id),
        label: item.name,
        value: item.equipment_id
      }))
    }

    return filteredEquipments.map((equipment) => ({
      label: equipment.name,
      value: equipment.equipment_id
    }))
  }, [filteredEquipments, selectedLimitedEquipments])


  useEffect(() => {
    if(!filteredEquipments || filteredEquipments.length === 0) {
      setSelectedLimitedEquipments([])
    }
  },[filteredEquipments])

  return (
    <FilterSection title={t("filter_comp.equipment")} requiredText={`Max ${maxOptions} vehicles`} className="font-bold">
      <MultiSelect
        options={options}
        hasSelectAll={false}
        value={selectedLimitedEquipments ?? []}
        onChange={setSelectedLimitedEquipments}
        labelledBy="Select"
      />
    </FilterSection>
  )
}
export default LimitedEquipmentFilter
