import { SetState } from "zustand"
import { DatapliedState } from "."
import { GetMeResponse } from "../types/getMe"
import { Organization } from "../types/userManagement/userOrganizations"

export interface AuthSlice {
  loggedIn?: boolean
  accessToken?: string
  myOrganizations?: Organization[]
  me?: GetMeResponse
  setAccessToken: (accessToken?: string) => void
  setLoggedIn: (loggedIn: boolean) => void
  setMyOrgs: (orgs: Organization[]) => void
  setMe: (me: GetMeResponse) => void
}

const createAuthSlice = (set: SetState<DatapliedState>) => ({
  accessToken: undefined,
  setAccessToken: (accessToken?: string) => {
    set(() => ({ accessToken }))
  },
  setLoggedIn: (loggedIn: boolean) => {
    set(() => ({ loggedIn }))
  },
  setMyOrgs: (orgs: Organization[]) => {
    set(() => ({ myOrganizations: orgs }))
  },
  setMe: (me: GetMeResponse) => {
    set(() => ({ me }))
  }
})

export default createAuthSlice
