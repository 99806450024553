import { PropsWithoutRef } from "react"
import {
  NavLink,
  NavLinkProps
} from "react-router-dom"

type Props = {
  active: boolean
  menuVisible: boolean
} & PropsWithoutRef<NavLinkProps>
const NavItem = ({ active, menuVisible, ...rest }: Props) => {
  const activeStlyes = `bg-lightBlue text-primary ${menuVisible ? "": "rounded-r-none"}`
  return (
    <NavLink
      className={`block py-1.5 md:py-2.5 px-2 md:px-4 rounded-lg transition duration-200 ${
        active
          ? activeStlyes
          : "rounded bg-transparent text-white"
      }`}
      {...rest}
    />
  )
}

export default NavItem
