import { format, fromUnixTime } from "date-fns"
import { useTranslation } from "react-i18next"
import shallow from "zustand/shallow"
import ElectricVehicleCheckbox from "../../../components/Filter/Shared/ElectricVehicleCheckbox"
import useGeneralOverview from "../../../hooks/queries/useGeneralOverview"
import useStore from "../../../store"
import DashboardContainer from "../../DashboardContainer"
import OverviewChart from "./OverviewChart"
import OverviewFilters from "./OverviewFilters"

const GeneralOverview = () => {
  const { data, loading, getData } = useGeneralOverview()
  const { t } = useTranslation()

  const { filterMenuVisible, isElectricVehicle, startDate, endDate } = useStore(
    (state) => ({
      filterMenuVisible: state.filterMenuVisible,
      isElectricVehicle: state.isElectricVehicle,
      startDate: state.startDate,
      endDate: state.endDate
    }),
    shallow
  )

  return (
    <DashboardContainer
      title={t("home.overview")}
      optionalLabel={
        <span className="text-gray-600">
          {data &&
            `Start date: ${format(
              fromUnixTime(startDate as number),
              "dd-MM-yyyy"
            )}, End date: ${format(
              fromUnixTime(endDate as number),
              "dd-MM-yyyy"
            )}`}
        </span>
      }
    >
      <div
        className="flex space-x-2 justify-between"
        style={{ height: "calc(100% - 6rem)" }}
      >
        <div
          className={`w-full flex flex-col ${
            filterMenuVisible ? "w-full lg:w-10/12" : "w-full"
          } space-y-4`}
        >
          <div className="flex flex-col md:flex-row md:space-x-4">
            <OverviewChart
              firstKey="travel"
              secondKey="working"
              data={data?.distance_chart_data}
              loading={loading}
              title={t("overview.distance")}
              description={t("overview.in_km")}
            />
            <OverviewChart
              firstKey="travel"
              secondKey="working"
              loading={loading}
              data={data?.time_chart_data}
              title={t("overview.time")}
              description={t("overview.in_hours")}
            />
          </div>
          <div className="flex flex-col md:flex-row md:space-x-4">
            <OverviewChart
              firstKey="travel"
              secondKey="working"
              loading={loading}
              data={
                isElectricVehicle
                  ? data?.energy_usage_chart_data
                  : data?.fuel_usage_chart_data
              }
              title={
                isElectricVehicle ? "Electricity use" : t("overview.fuel_use")
              }
              description={
                isElectricVehicle ? "in kWh" : t("overview.in_liters")
              }
              additionalTitle={<ElectricVehicleCheckbox key={"vh1"} />}
            />
            <OverviewChart
              firstKey="water_usage"
              loading={loading}
              data={data?.water_usage_chart_data}
              title={t("overview.water_use")}
              description={t("overview.in_liters")}
            />
          </div>
        </div>
        {filterMenuVisible && (
          <div
            className={`transform shadow-xl absolute right-0 bottom-4 top-32 w-72 mx-auto z-20 transition duration-200 ease-in-out lg:shadow-none lg:top-0 lg:bottom-0 lg:right-auto lg:relative lg:w-80 ${
              filterMenuVisible ? "translate-x-0" : "translate-x-full"
            }`}
          >
            <OverviewFilters onApplyFilter={getData} />
          </div>
        )}
      </div>
    </DashboardContainer>
  )
}

export default GeneralOverview
