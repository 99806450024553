import { SVGProps } from "react"

interface Props {
  color: string
  direction: "up" | "down"
  svgProps?: SVGProps<SVGSVGElement>
}
const Arrow = ({ color, direction, svgProps }: Props) => (
  <svg
    width={17}
    height={17}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...svgProps}
    transform={direction == "down" ? "scale(-1, 1)" : "scale(1, -1)"}
  >
    <path
      d="M17 8.5C17 13.1956 13.1956 17 8.5 17C3.80443 17 3.32594e-07 13.1956 7.43094e-07 8.5C1.15359e-06 3.80443 3.80444 -1.15359e-06 8.5 -7.43094e-07C13.1956 -3.32594e-07 17 3.80443 17 8.5ZM6.99194 4.52419L6.99194 8.5L4.5619 8.5C4.19516 8.5 4.01008 8.94556 4.27056 9.20262L8.20867 13.1202C8.36976 13.2812 8.62681 13.2812 8.7879 13.1202L12.7294 9.20262C12.9899 8.94214 12.8048 8.5 12.4381 8.5L10.0081 8.5L10.0081 4.52419C10.0081 4.29798 9.82298 4.1129 9.59678 4.1129L7.40323 4.1129C7.17702 4.1129 6.99194 4.29798 6.99194 4.52419Z"
      fill="white"
    />
  </svg>
)

export default Arrow
