import { useEffect, useMemo, useState } from "react"
import { Column, useTable } from "react-table"
import DeleteIcon from "../../../../assets/icons/trash.svg"
import useRemoveOrgFromUser from "../../../../hooks/mutations/useRemoveOrgFromUser"
import useUserOrganizations from "../../../../hooks/queries/userManagement/useUserOrganizations"
import { User } from "../../../../types/userManagement/dealers"
import {
  Organization
} from "../../../../types/userManagement/userOrganizations"
import AddOrganizationDealer from "./AddOrganizationDealer"
import DeleteOrgModal from "./DeleteOrgModal"

interface Props {
  selectedDealerId?: number
  addOrgModalOpen: boolean
  setAddOrgModalOpen: (b: boolean) => void
}
const ConnectedOrganizations = ({ selectedDealerId, addOrgModalOpen, setAddOrgModalOpen }: Props) => {
  const [selectedId, setSelectedId] = useState<any>()
  const [open, setOpen] = useState(false)
  const { getOrganizations, data } = useUserOrganizations()
  useEffect(() => {
    if (selectedDealerId) {
      getOrganizations(selectedDealerId)
    }
  }, [selectedDealerId])

  const columns: Column<Organization>[] = useMemo(() => {
    return [
      {
        Header: "Company Name",
        accessor: "organizationName"
      },

      {
        Header: "Status",
        accessor: "organization_active",
        Cell: ({
          cell: {
            row: { values }
          }
        }) => (
          <span className="rounded-xl px-2 py-1 text-sm">
            {values.organization_active ? "Active" : "Inactive"}
          </span>
        )
      },
      // {
      //   id: "1",
      //   Header: " ",
      //   Cell: () => (
      //     <div className="flex text-sm cursor-pointer" onClick={() => {}}>
      //       <img src={EditIcon} className="w-4 mr-2" />
      //       <div className="text-gray-500">Edit</div>
      //     </div>
      //   )
      // },
      {
        id: "2",
        Header: " ",
        Cell: ({ cell: { row } }: { cell: any }) => (
          <div
            className="flex text-sm cursor-pointer"
            onClick={() => {
              setSelectedId(row.original.id)
              setOpen(true)
              // removeOrgFromDealer(row.original)
            }}
          >
            <img src={DeleteIcon} className="w-4 mr-2" />
            <div className="text-gray-500">Remove from dealer</div>
          </div>
        )
      }
    ]
  }, [])

  const tableInstance = useTable({ columns, data: data?.organizations ?? [] })
  const { getTableBodyProps, headerGroups, rows, prepareRow } = tableInstance

  const { removeOrgFromUser, success: removeOrgSuccess } =
    useRemoveOrgFromUser()

  useEffect(() => {
    if (removeOrgSuccess) {
      setOpen(false)
      getOrganizations(selectedDealerId as number)
    }
  }, [removeOrgSuccess])

  const handleAddOrgClose = () => {
    setAddOrgModalOpen(false)
    getOrganizations(selectedDealerId as number)
  }

  const removeOrgFromDealer = () => {
    const formData = new FormData()
    formData.append("target_user_id", selectedDealerId!.toString())
    formData.append("target_organization_id", selectedId)
    removeOrgFromUser(formData)
    // navigate("/dashboard/user/add-member", { replace: true })
  }

  return (
    <div className="w-full">
      <div className="bg-white flex justify-between">
        <div className="flex space-x-2 divide-x-2 items-center px-4 py-2">
          <div className="text-lg">Connected Organizations</div>
          {/* <div className="text-gray-600 text-sm pl-2">5 total 2 inactive</div> */}
        </div>
        {/* <div
          className="bg-primary text-white px-4 py-1 text-sm cursor-pointer flex items-center"
          onClick={handleAddUser}
        >
          Add User
        </div> */}
      </div>
      <div className="space-y-4 overflow-x-auto">
        <table {...getTableBodyProps()} className="w-full">
          <thead className="bg-white sticky top-0 border-t border-b">
            {
              // Loop over the header rows
              headerGroups.map((headerGroup) => (
                // Apply the header row props
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {
                    // Loop over the headers in each row
                    headerGroup.headers.map((column) => (
                      // Apply the header cell props
                      <th
                        {...column.getHeaderProps()}
                        scope="col"
                        className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer hover:bg-gray-50"
                      >
                        {
                          // Render the header
                          column.render("Header")
                        }
                      </th>
                    ))
                  }
                </tr>
              ))
            }
          </thead>
          <tbody
            {...getTableBodyProps()}
            className="bg-white divide-y divide-gray-200"
          >
            {
              // Loop over the table rows
              rows.map((row) => {
                // Prepare the row for display
                prepareRow(row)
                return (
                  // Apply the row props
                  <tr {...row.getRowProps()}>
                    {
                      // Loop over the rows cells
                      row.cells.map((cell) => {
                        // Apply the cell props
                        return (
                          <td
                            {...cell.getCellProps()}
                            className="px-6 py-4 whitespace-nowrap"
                          >
                            {
                              // Render the cell contents
                              cell.render("Cell")
                            }
                          </td>
                        )
                      })
                    }
                  </tr>
                )
              })
            }
          </tbody>
        </table>
      </div>
      <AddOrganizationDealer visible={addOrgModalOpen} closeModal={handleAddOrgClose} selectedDealer={selectedDealerId} />
      <DeleteOrgModal visible={open} closeModal={() => {setOpen(false)}} onConfirm={removeOrgFromDealer} />
    </div>
  )
}

export default ConnectedOrganizations
