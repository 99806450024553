import React from "react"
import enFlag from "../../../assets/icons/lang/en.svg"
import frFlag from "../../../assets/icons/lang/fr.svg"
const Language = () => {
  const languageMenu = [
    { icon: enFlag, key: "en" },
    { icon: frFlag, key: "fr" }
  ]

  const handleLanguageClick = (lang: string) => {
    window.localStorage.setItem("lang", lang)
    window.location.reload()
  }
  return (
    <div className="flex flex-col items-center justify-center bg-white py-6">
      <div className="font-semibold text-2xl mb-4">Language</div>
      <div className="flex items-center justify-around">
        {languageMenu.map((lang) => (
          <div
            key={lang.key}
            className="px-4 py-2 cursor-pointer"
            onClick={() => handleLanguageClick(lang.key)}
          >
            <img src={lang.icon} alt="Language icon" style={{ width: 32 }} />
          </div>
        ))}
      </div>
    </div>
  )
}

export default Language
