import { useMemo } from "react"
import { useTranslation } from "react-i18next"
import { MultiSelect } from "react-multi-select-component"
import shallow from "zustand/shallow"
import useStore from "../../../store"
import FilterSection from "../FilterSection"

const BrandFilter = () => {
  const { t } = useTranslation()

  const { data, selectedBrands, setSelectedBrands } = useStore(
    (state) => ({
      data: state.vehicleMetadata,
      selectedBrands: state.selectedBrands,
      setSelectedBrands: state.setSelectedBrands
    }),
    shallow
  )

  const brandOptions = useMemo(() => {
    return (
      data?.brands.map((brand) => ({
        label: brand,
        value: brand
      })) || []
    )
  }, [data])

  return (
    <FilterSection title={t("filter_comp.brand")} className="font-bold">
      <MultiSelect
        ClearSelectedIcon={true}
        options={brandOptions}
        value={selectedBrands ?? []}
        onChange={setSelectedBrands}
        labelledBy="Select"
      />
    </FilterSection>
  )
}

export default BrandFilter
