import { useEffect, useState } from "react"
import apiClient from "../../../api"
import useStore from "../../../store"
import { DealerResponse } from "../../../types/userManagement/dealers"
import { DistrictResponse } from "../../../types/userManagement/district"

interface DealersData {
  loading: boolean
  error?: string
  data?: DistrictResponse[]
  getDistricts: () => void
}

const useDistricts = (): DealersData => {
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState<string>()
  const [data, setData] = useState<DistrictResponse[]>()

  const selectedOrganization = useStore((q) => q.selectedOrganization)

  useEffect(() => {
    getDistricts()
  }, [selectedOrganization])

  const getDistricts = async () => {
    setLoading(true)
    try {
      const { data } = await apiClient.get<DistrictResponse[]>(
        "/sweeper-api/districts"
      )
      setData(data)
    } catch (e: any) {
      setError(e)
    }
    setLoading(false)
  }

  return { loading, error, data, getDistricts }
}

export default useDistricts
