import React from "react"
import FilterHeader from "../components/Filter/FilterHeader"

interface Props {
  title: string
  hideFilter?: boolean
  optionalLabel?: React.ReactElement
  children: React.ReactElement | React.ReactElement[]
}
const DashboardContainer = ({
  title,
  hideFilter,
  optionalLabel,
  children
}: Props) => {
  return (
    <div className="h-full">
      <div className="flex items-center justify-between mb-3 space-x-2">
        <div
          className={`2xl:flex w-10/12 ${
            optionalLabel ? "items-center" : "items-start"
          }`}
        >
          <div className="text-2xl font-semibold">{title}</div>
          {optionalLabel && <div className="ml-4">{optionalLabel}</div>}
        </div>
        {/* <div className="hidden lg:block md:w-2/12"> */}
        <div className="hidden lg:block w-72 lg:w-80">
          {!hideFilter && <FilterHeader />}
        </div>
      </div>
      {children}
    </div>
  )
}

export default DashboardContainer
