import React, { Children, cloneElement, isValidElement } from "react"

interface Props {
  children: React.ReactElement | React.ReactElement[]
  className?: string
  title?: string
  description?: string
  inlineHeader?: boolean
  style?: React.CSSProperties
  rightTitle?: string
  additionalTitle?: string | React.ReactNode
  fullHeight?: boolean
}

const Card = ({
  children,
  className,
  style,
  title,
  description,
  fullHeight,
  rightTitle,
  additionalTitle,
  inlineHeader = false
}: Props) => {
  const showTitleAndDesc = title || description
  const multipleChildren = Array.isArray(children)
  return (
    <div
      className={`relative flex ${!multipleChildren && "flex-col"} ${
        fullHeight ? "h-full" : ""
      } space-x-4 bg-white p-4 mt-4 rounded-md ${className}`}
      style={style}
    >
      {showTitleAndDesc && (
        <CardHeader
          title={title}
          description={description}
          inlineHeader={inlineHeader}
          additionalTitle={additionalTitle}
        />
      )}
      {rightTitle && <div className="text-right">{rightTitle}</div>}
      {Children.map(children, (child) => {
        if (isValidElement(child)) {
          return cloneElement(child)
        }
      })}
    </div>
  )
}

const CardHeader = ({
  title,
  description,
  additionalTitle,
  inlineHeader
}: {
  title?: string
  description?: string
  additionalTitle?: string | React.ReactNode
  inlineHeader: boolean
}) => {
  title = title ?? ""
  description = description ?? ""
  if (inlineHeader) {
    return (
      <div className="mb-4">
        <span className="font-semibold">{title}</span>
        <span>{" " + description}</span>
        {additionalTitle && <div>{additionalTitle}</div>}
      </div>
    )
  }

  if (additionalTitle) {
    return (
      <div className="mb-4 flex justify-between">
        <div>
          <div className="text-xl font-bold">{title}</div>
          <div>{description}</div>
        </div>
        {additionalTitle}
      </div>
    )
  }

  return (
    <div className="mb-4">
      <div className="text-xl font-bold">{title}</div>
      <div>{description}</div>
    </div>
  )
}
export default Card
