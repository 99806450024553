import { useCallback, useEffect, useState } from "react"
import qs from "qs"
import apiClient from "../../api"
import useStore from "../../store"
import shallow from "zustand/shallow"
import { format } from "date-fns"
import { GeneralOverviewResponse } from "../../types/generalOverview"
import fromUnixTime from "date-fns/fromUnixTime"
import { useNotyf } from "../useNotyf"
import { getHourRangeParameter } from "../../util"

interface GeneralOverviewData {
  loading: boolean
  error?: string
  data?: GeneralOverviewResponse
  getData: () => void
}

const useGeneralOverview = (): GeneralOverviewData => {
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState<string>()
  const [data, setData] = useState<any>()
  const [pendingRequest, setPendingRequest] = useState(false)
  const { notyf } = useNotyf()

  const {
    selectedEquipments,
    startDate,
    endDate,
    timePeriod,
    selectedHours,
    filteredEquipments,
    vehicles
  } = useStore(
    (state) => ({
      vehicles: state.vehicles,
      selectedEquipments: state.selectedEquipments,
      filteredEquipments: state.filteredEquipments,
      startDate: state.startDate,
      endDate: state.endDate,
      timePeriod: state.timePeriod,
      selectedHours: state.selectedHours
    }),
    shallow
  )

  useEffect(() => {
    if (pendingRequest && startDate && endDate && !data) {
      getData()
    }
  }, [pendingRequest, startDate, endDate, data])

  useEffect(() => {
    getData()
  }, [])

  const buildRequestParams = useCallback(() => {
    if (!startDate || !endDate) {
      return null
    }
    const params: Record<string, string> = {
      date_start: format(fromUnixTime(startDate), "yyyy-MM-dd"),
      date_end: format(fromUnixTime(endDate), "yyyy-MM-dd")
    }
    // if (selectedDistricts?.length) {
    //   params["district_ids"] = selectedDistricts.map((d) => d.value).join(",")
    // }

    if (selectedEquipments?.length) {
      params["vehicle_ids"] = selectedEquipments.map((d) => d.value).join(",")
      params["vehicle_names_debug"] = selectedEquipments
        .map((d) => d.label)
        .join(",")
    }

    if (selectedHours?.length) {
      params["hours_filter"] = getHourRangeParameter(selectedHours)
    }

    // else {
    //   if (selectedBrands?.length) {
    //     params["brands_filter"] = selectedBrands.map((d) => d.value).join(",")
    //   }
    //   if (selectedTypes?.length) {
    //     params["types_filter"] = selectedTypes.map((d) => d.value).join(",")
    //   }
    //   if (selectedModels?.length) {
    //     params["models_filter"] = selectedModels.map((d) => d.value).join(",")
    //   }
    // }
    if (timePeriod) {
      params["half_of_day_filter"] = timePeriod
    }
    return params
  }, [
    // selectedDistricts,
    // selectedBrands,
    // selectedTypes,
    // selectedModels,
    selectedEquipments,
    startDate,
    endDate,
    timePeriod,
    selectedHours
  ])

  const getData = async () => {
    const requestParams = buildRequestParams()
    if (!requestParams) {
      notyf?.error("Please select start date and end date!")
      // setPendingRequest(true)
      setLoading(false)
      return
    }
    setLoading(true)

    try {
      const { data } = await apiClient.get(
        `/sweeper-api/charts/general_overview?${qs.stringify(requestParams)}`
      )
      setData(data)
    } catch (e: any) {
      setError(e)
    }
    setLoading(false)
    setPendingRequest(false)
  }

  return { loading, error, data, getData }
}

export default useGeneralOverview
