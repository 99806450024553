import { useEffect, useState } from "react"
import useForgotPassword from "../hooks/mutations/useForgotPassword"
import notyf from "../util/notyf"

const ForgotPassword = () => {
  const [email, setEmail] = useState("")

  const { success, requestForgetPassword } = useForgotPassword()

  useEffect(() => {
    if (success) {
      notyf.open({ message: "Please check your inbox", background: "green" })
    }
  }, [success])

  const handleSubmit = (e?: React.FormEvent<HTMLFormElement>) => {
    e?.preventDefault()
    e?.stopPropagation()

    if (email.trim().length == 0) {
      return notyf.error("Please provide your e-mail")
    }
    const formData = new FormData()
    formData.append("email_address", email)
    requestForgetPassword(formData)
  }
  return (
    <div className="flex items-center justify-center w-full h-full relative mb-20 max-w-lg mx-auto text-center">
      <div className=" mb-8 py-8 px-6 bg-white rounded shadow-form w-full">
        <span className="text-primary font-bold text-lg">Reset Password</span>
        <h3 className="mt-2 mb-6 text-2xl text-gray-500">
          {success ? "Please check your inbox" : "Please provide your e-mail"}
          {/* Please provide your current password and new password */}
        </h3>
        {!success && (
          <form onSubmit={handleSubmit}>
            <div className="mb-6 pr-4 flex items-center border rounded-lg">
              <input
                onChange={(e) => setEmail(e.target.value)}
                className="py-4 pl-4 w-full placeholder-blueGray-400 bg-white focus:outline-none border-0 rounded-l-lg"
                placeholder="Your e-mail"
                type="e-mail"
              />
            </div>
            <button
              className="w-full py-4 bg-primary hover:bg-primary rounded text-white text-sm font-bold"
              type="submit"
            >
              Reset password
            </button>
          </form>
        )}
      </div>
    </div>
  )
}

export default ForgotPassword
