import create from "zustand"
import createAuthSlice, { AuthSlice } from "./createAuthSlice"
import createCacheSlice, { CacheSlice } from "./createCacheSlice"

export type DatapliedState = AuthSlice & CacheSlice

const useStore = create<DatapliedState>((set, get) => ({
  ...createAuthSlice(set),
  ...createCacheSlice(set)
}))

export default useStore
