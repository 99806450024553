import { useEffect, useState } from "react"
import shallow from "zustand/shallow"
import apiClient from "../../api"
import useStore from "../../store"
import { DistrictResponse } from "../../types/districts"

interface DistrictsData {
  loading: boolean
  error?: string
  data: DistrictResponse[]
}

const useDistricts = (): DistrictsData => {
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState<string>()

  const { data, selectedOrganization, setDistricts, setSelectedDistricts } =
    useStore(
      (state) => ({
        data: state.districts,
        selectedOrganization: state.selectedOrganization,
        setDistricts: state.setDistricts,
        setSelectedDistricts: state.setSelectedDistricts
      }),
      shallow
    )

  useEffect(() => {
    if (selectedOrganization) {
      getDistricts()
    }
  }, [selectedOrganization])

  const getDistricts = async () => {
    // if (data && data.length > 0) {
    //   return
    // }
    setLoading(true)
    try {
      const { data } = await apiClient.get<DistrictResponse[]>(
        "/sweeper-api/districts"
      )
      setDistricts(data)
      setSelectedDistricts(
        data.map((district) => ({
          label: district.name,
          value: district.district_id
        }))
      )
    } catch (e: any) {
      setError(e)
    }
    setLoading(false)
  }

  return { loading, error, data }
}

export default useDistricts
