import { useEffect, useState } from "react"
import shallow from "zustand/shallow"
import apiClient from "../../api"
import useStore from "../../store"

export interface VehicleMetadataResponse {
  brands: string[]
  models: string[]
  types: string[]
}

interface VehicleMetadata {
  loading: boolean
  error?: string
  data?: VehicleMetadataResponse
}

const useVehicleMetadata = (): VehicleMetadata => {
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState<string>()

  const {
    data,
    setVehicleMetadata,
    selectedOrganization,
    setSelectedBrands,
    setSelectedTypes,
    setSelectedModels
  } = useStore(
    (state) => ({
      data: state.vehicleMetadata,
      setVehicleMetadata: state.setVehicleMetadata,
      selectedOrganization: state.selectedOrganization,
      setSelectedBrands: state.setSelectedBrands,
      setSelectedTypes: state.setSelectedTypes,
      setSelectedModels: state.setSelectedModels
    }),
    shallow
  )

  useEffect(() => {
    if (selectedOrganization) {
      getVehicleMetadata()
    }
  }, [selectedOrganization])

  const getVehicleMetadata = async () => {
    if (loading) {
      return
    }
    setLoading(true)
    try {
      const { data } = await apiClient.get<VehicleMetadataResponse>(
        "/sweeper-api/dashboards/vehicle_filter_metadata"
      )

      setVehicleMetadata(data)
      setSelectedBrands(
        data.brands.map((brand) => ({ label: brand, value: brand }))
      )
      setSelectedModels(
        data.models.map((model) => ({ label: model, value: model }))
      )
      setSelectedTypes(data.types.map((type) => ({ label: type, value: type })))
    } catch (e: any) {
      setError(e)
    }
    setLoading(false)
  }

  return { loading, error, data }
}

export default useVehicleMetadata
