import { useEffect, useState } from "react"
import { Route, Routes, useNavigate } from "react-router-dom"
import { refreshToken } from "../../api"
import NavBar from "../../components/NavBar"
import MobileNavBar from "../../components/NavBar/MobileNavBar"
import TopNavBar from "../../components/NavBar/TopNavBar"
import useDistricts from "../../hooks/queries/useDistricts"
import useGetMe from "../../hooks/queries/useGetMe"
import useMyOrganizations from "../../hooks/queries/userManagement/useMyOrganizations"
import useVehicleMetadata from "../../hooks/queries/useVehicleMetadata"
import useVehicles from "../../hooks/queries/useVehicles"
import { useAuth } from "../../hooks/useAuth"
import useCachedFilter from "../../hooks/useCachedFilter"
import { useNotyf } from "../../hooks/useNotyf"
import useStore from "../../store"
import DailyReport from "./DailyReport"
import DownloadData from "./DownloadData"
import FleetComparison from "./FleetComparison"
import HeatMap from "./HeatMap"
import HeatMapDistrict from "./HeatMapDistrict"
import HomePage from "./Home"
import Maintenance from "./Maintenance"
import Overview from "./Overview"
import UserRoutes from "./User"

const Dashboard = () => {
  const [mobileNavVisible, setMobileNavVisible] = useState(false)
  const { loggedIn } = useAuth()
  const navigate = useNavigate()
  const { filterMenuVisible, toggleFilterMenuVisible } = useStore((state) => ({
    filterMenuVisible: state.filterMenuVisible,
    toggleFilterMenuVisible: state.toggleFilterMenuVisible
  }))

  useNotyf()
  useVehicles()
  useDistricts()
  useVehicleMetadata()
  useCachedFilter()
  useGetMe()

  const handleMobileNavToggle = () => {
    setMobileNavVisible((visible) => !visible)
  }

  useEffect(() => {
    const init = async () => {
      if (typeof loggedIn == "boolean" && loggedIn === false) {
        navigate("/", { replace: true })
      } else if (typeof loggedIn == "undefined") {
        navigate("/loading")
      }
    }

    init()
  }, [loggedIn])

  const handleBackgroundClick = () => {
    if (filterMenuVisible) {
      toggleFilterMenuVisible()
    }
    if (mobileNavVisible) {
      setMobileNavVisible(false)
    }
  }

  return (
    <div className="flex w-full h-full bg-lightBlue">
      <NavBar />
      <MobileNavBar
        visible={mobileNavVisible}
        onToggleMobileNav={handleMobileNavToggle}
      />
      <div className="w-full overflow-x-hidden">
        <TopNavBar onToggleMobileNav={handleMobileNavToggle} />
        <div className="w-full p-5" style={{ height: "calc(100% - 4rem)" }}>
          {(mobileNavVisible || filterMenuVisible) && (
            <div
              onClick={handleBackgroundClick}
              className="block lg:hidden absolute inset-x-0 bottom-0 top-0 bg-black bg-opacity-50 z-10 transition ease-in-out"
            ></div>
          )}
          <Routes>
            {/* dashboard routes */}
            <Route path="/" element={<HomePage />} />
            <Route path="overview" element={<Overview />} />
            <Route path="fleet-comparison" element={<FleetComparison />} />
            <Route path="heatmap" element={<HeatMap />} />
            <Route path="heatmap-district" element={<HeatMapDistrict />} />
            <Route path="daily-report" element={<DailyReport />} />
            <Route path="maintenance" element={<Maintenance />} />
            {/* <Route path="download-data" element={<DownloadData />} /> */}
            {/* user routes */}
            {/* <Route path="user/change-password" element={<ChangePassword />} />
            <Route path="user/add-member" element={<AddUser />} /> */}
            <Route path="user/*" element={<UserRoutes />} />
          </Routes>
        </div>
      </div>
    </div>
  )
}

export default Dashboard
