import React, { useEffect, useMemo, useState } from "react"
import { useTranslation } from "react-i18next"
import {
  Bar,
  BarChart,
  CartesianGrid,
  Legend,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis
} from "recharts"
import { chartColors } from "../../constants"

interface Props {
  data: any
  firstKey: string
  secondKey?: string
  stack?: boolean
}

const keysToLabel: Record<string, string> = {
  travel: "Travel",
  working: "Working",
  water_usage: "Water Usage",
  usage_per_km: "Usage per KM",
  average_usage: "Average use in kW"
}

const renderColorfulLegendText = (value: string, entry: any) => {
  return <span className="text-primary">{value}</span>
}

const DPBarChart = ({ data, firstKey, secondKey, stack }: Props) => {
  const { t } = useTranslation()
  const [tickArray, setTickArray] = useState<number[]>([])

  const maxValue = useMemo(() => {
    let maxValue = 0
    if (data && data.length) {
      const secondKeyHasValue = !!secondKey

      for (const item of data) {
        const firstKeyValue = parseInt(item[firstKey])

        if (maxValue < firstKeyValue) {
          maxValue = firstKeyValue
        }

        if (secondKeyHasValue) {
          const secondKeyValue = parseInt(item[secondKey])

          if (maxValue < secondKeyValue) {
            maxValue = secondKeyValue
          }
        }
      }
    }
    return maxValue
  }, [data])

  const intervalTicks = (maxValue: number) => {
    const digitsCount = Math.trunc(maxValue).toString().length
    const divider = Math.pow(10, digitsCount - 1)
    const roundValue = Math.ceil(maxValue / divider) * divider
    const slice = Math.trunc(roundValue / 4)
    const ticks = [0, slice, slice * 2, slice * 3, roundValue]
    return ticks
  }

  useEffect(() => {
    const ticks = intervalTicks(maxValue)
    setTickArray(ticks)
  }, [maxValue])

  if (!data || data?.length < 1) {
    return (
      <div className="w-1/2 mx-auto px-5 py-4 bg-primary bg-opacity-10 rounded-md">
        <div className="text-xl text-center text-gray-500">
          {t("chart_comp.no_data")}
        </div>
      </div>
    )
  }

  return (
    <div style={{ width: "100%", height: "220px", position: "relative" }}>
      <div
        style={{
          width: "100%",
          height: "100%",
          position: "absolute",
          top: 0,
          left: 0
        }}
      >
        <ResponsiveContainer
          width="95%"
          minHeight="220px"
          height="100%"
          debounce={1}
        >
          <BarChart
            data={data}
            margin={{
              left: 10
            }}
            barGap={-10}
          >
            <CartesianGrid strokeDasharray="2 2" />
            <XAxis
              dataKey="name"
              tickFormatter={(label) => label.substring(0, 8)}
            />
            <YAxis
              interval="preserveStartEnd"
              allowDataOverflow
              ticks={tickArray}
            />
            <Tooltip />
            <Legend formatter={renderColorfulLegendText} />
            <Bar
              stackId={stack ? "a" : undefined}
              dataKey={firstKey}
              name={keysToLabel[firstKey]}
              fill={chartColors.yellow}
              radius={[3, 3, 0, 0]}
            />
            {secondKey && (
              <Bar
                stackId={stack ? "a" : undefined}
                dataKey={secondKey}
                name={keysToLabel[secondKey]}
                fill={chartColors.orange}
                radius={[3, 3, 0, 0]}
              />
            )}
          </BarChart>
        </ResponsiveContainer>
      </div>
    </div>
  )
}

export default DPBarChart
