interface Props {
  active?: boolean
}
const GeneralOverviewIcon = ({ active }: Props) => {
  return (
    <svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3 3H11V13H3V3ZM21 3H13V9H21V3ZM9 11V5H5V11H9ZM19 7V5H15V7H19ZM19 13V19H15V13H19ZM9 19V17H5V19H9ZM21 11H13V21H21V11ZM3 15H11V21H3V15Z"
        fill={active ? "#0055A0" : "#fff"}
      />
    </svg>
  )
}

export default GeneralOverviewIcon
