import { getUnixTime, subDays, subMonths } from "date-fns"
import { useEffect } from "react"
import { Option } from "react-multi-select-component/dist/types/lib/interfaces"
import shallow from "zustand/shallow"
import { Hour } from "../components/Filter/Shared/HourFilter"
import useStore from "../store"
import { EnumHeatMapMeasure } from "../types/heatMap"

interface CachedFilterData {
  selectedDistricts?: Option[]

  selectedTypes?: Option[]
  selectedEquipments?: Option[]
  timePeriod?: string
  startDate?: number
  endDate?: number
  heatMapMeasure?: EnumHeatMapMeasure

  setSelectedDistricts: (districts: Option[]) => void
  setSelectedTypes: (types: Option[]) => void
  setSelectedEquipments: (equipments: Option[]) => void
  setTimePeriod: (period: string) => void
  setStartDate: (startDate: number) => void
  setEndDate: (endDate: number) => void
  setHeatMapMeasure: (heatMapMeasure: EnumHeatMapMeasure) => void
}

const useCachedFilter = (): CachedFilterData => {
  const {
    vehicles,
    vehicleMetadata,
    districts,
    // shared filters
    selectedDistricts,
    selectedBrands,
    selectedTypes,
    selectedModels,
    selectedEquipments,
    selectedLimitedEquipments,
    filteredEquipments,
    timePeriod,
    startDate,
    endDate,
    heatMapMeasure,

    setSelectedDistricts,
    setSelectedBrands,
    setSelectedTypes,
    setSelectedModels,
    setSelectedEquipments,
    setSelectedLimitedEquipments,
    setFilteredEquipments,
    setTimePeriod,
    setStartDate,
    setEndDate,
    setHeatMapMeasure,
    setHeatMapDistrictMeasure,
    setDailyReportDate
  } = useStore(
    (state) => ({
      vehicles: state.vehicles,
      vehicleMetadata: state.vehicleMetadata,
      districts: state.districts,
      selectedDistricts: state.selectedDistricts,
      selectedBrands: state.selectedBrands,
      selectedTypes: state.selectedTypes,
      selectedModels: state.selectedModels,
      selectedEquipments: state.selectedEquipments,
      selectedLimitedEquipments: state.selectedLimitedEquipments,
      selectedOrganization: state.selectedOrganization,
      filteredEquipments: state.filteredEquipments,
      timePeriod: state.timePeriod,
      startDate: state.startDate,
      endDate: state.endDate,
      heatMapMeasure: state.heatMapMeasure,

      setSelectedDistricts: state.setSelectedDistricts,
      setSelectedBrands: state.setSelectedBrands,
      setSelectedModels: state.setSelectedModels,
      setSelectedTypes: state.setSelectedTypes,
      setSelectedEquipments: state.setSelectedEquipments,
      setSelectedLimitedEquipments: state.setSelectedLimitedEquipments,
      setFilteredEquipments: state.setFilteredEquipments,
      setTimePeriod: state.setTimePeriod,
      setStartDate: state.setStartDate,
      setEndDate: state.setEndDate,
      setHeatMapMeasure: state.setHeatMapMeasure,
      setHeatMapDistrictMeasure: state.setHeatMapDistrictMeasure,
      setDailyReportDate: state.setDailyReportDate
    }),
    shallow
  )

  // place default filter values below
  useEffect(() => {
    const now = new Date()
    setStartDate(getUnixTime(subMonths(now, 1)))
    setEndDate(getUnixTime(now))
    setDailyReportDate(getUnixTime(subDays(new Date(), 1)))
    setHeatMapMeasure(EnumHeatMapMeasure.SweepingFuel)
    setHeatMapDistrictMeasure("by_sweep_hours")
  }, [])

  //
  useEffect(() => {
    if (vehicles) {
      const brands = selectedBrands?.map((b) => b.value)
      const models = selectedModels?.map((b) => b.value)
      const types = selectedTypes?.map((b) => b.value)
      const districts = selectedDistricts?.map((b) => b.value)

      const filteredEquipments = vehicles.filter(
        (v) =>
          brands?.includes(v.brand) &&
          models?.includes(v.model) &&
          types?.includes(v.type) &&
          districts?.includes(v.district.district_id)
      )

      setFilteredEquipments(filteredEquipments)
    }
  }, [
    vehicles,
    selectedBrands,
    selectedModels,
    selectedTypes,
    selectedDistricts
  ])

  useEffect(() => {
    if (filteredEquipments?.length) {
      if (!selectedEquipments?.length) {
        setSelectedEquipments(
          filteredEquipments.map((vehicle) => ({
            label: vehicle.name,
            value: vehicle.equipment_id
          }))
        )
        return
      }
      const filteredEqIds = filteredEquipments.map((eq) => eq.equipment_id)
      const selectedEqs: Option[] = []

      selectedEquipments?.map((eq) => {
        if (filteredEqIds.includes(eq.value)) {
          selectedEqs.push(eq)
        }
      })

      setSelectedEquipments(selectedEqs)
    } else {
      setSelectedEquipments([])
    }
  }, [filteredEquipments])

  useEffect(() => {
    if (filteredEquipments?.length) {
      if (!selectedLimitedEquipments?.length) {
        setSelectedLimitedEquipments(
          filteredEquipments.slice(0, 10).map((vehicle) => ({
            label: vehicle.name,
            value: vehicle.equipment_id
          }))
        )
        return
      }
      const filteredEqIds = filteredEquipments.map((eq) => eq.equipment_id)
      const selectedEqs: Option[] = []

      selectedLimitedEquipments?.map((eq) => {
        if (filteredEqIds.includes(eq.value)) {
          selectedEqs.push(eq)
        }
      })

      setSelectedLimitedEquipments(selectedEqs)
    } else {
      setSelectedLimitedEquipments([])
    }
  }, [filteredEquipments])

  // filter for district
  useEffect(() => {
    if (districts && districts.length > 0 && !selectedDistricts) {
      // select the first district by default
      // setSelectedDistricts([
      //   {
      //     label: districts[0].name,
      //     value: districts[0].district_id
      //   }
      // ])

      //select all districts by default
      setSelectedDistricts(
        districts.map((district) => ({
          label: district.name,
          value: district.district_id
        }))
      )
    }
  }, [districts, selectedDistricts])

  return {
    selectedDistricts,
    selectedTypes,
    selectedEquipments,
    timePeriod,
    startDate,
    endDate,
    heatMapMeasure,

    setSelectedDistricts,
    setSelectedTypes,
    setSelectedEquipments,
    setTimePeriod,
    setStartDate,
    setEndDate,
    setHeatMapMeasure
  }
}

export default useCachedFilter
