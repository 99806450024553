import { deepEqual } from "fast-equals"
import React, { useCallback, useEffect, useMemo, useState } from "react"
import useShapeFile from "../../hooks/queries/userManagement/useShapeFile"
import { By } from "../../types/heatMapDistrict"

interface Props {
  map?: google.maps.Map
  data: By
}

const districtColors: Record<string, Object> = {
  high: {
    strokeColor: "#FF0000",
    strokeOpacity: 1,
    strokeWeight: 2,
    fillColor: "#FF0000",
    fillOpacity: 0.5
  },
  medium: {
    strokeColor: "#FFD700",
    strokeOpacity: 1,
    strokeWeight: 2,
    fillColor: "#FFD700",
    fillOpacity: 0.5
  },
  low: {
    strokeColor: "#00A066",
    strokeOpacity: 1,
    strokeWeight: 2,
    fillColor: "#00A066",
    fillOpacity: 0.5
  }
}

const Polygon = React.memo(
  ({ map, data }: Props) => {
    const { data: shapeFile } = useShapeFile()
    const [infoWindow, setInfoWindow] = useState<google.maps.InfoWindow>()

    const handleDistrictClick = useCallback(
      (e: google.maps.Data.MouseEvent) => {
        const { latLng, feature } = e

        const infoWindow: google.maps.InfoWindow = new google.maps.InfoWindow({
          position: latLng
        })

        infoWindow.setContent(
          generateInfoWindowContent(feature.getId() as string)
        )

        setInfoWindow(infoWindow)
      },
      []
    )

    const generateInfoWindowContent = (neighbourhood: string) => {
      return `
      <div style="font-size: 14px">
      ${neighbourhood}
      </div>
      `
    }

    const getCenter = useCallback((features: google.maps.Data.Feature[]) => {
      const bound = new google.maps.LatLngBounds()
      features.forEach((feature) => {
        const featureCoords = feature.getGeometry()
        if (featureCoords) {
          featureCoords.forEachLatLng((latlng) => bound.extend(latlng))
        }
      })

      return bound.getCenter()
    }, [])

    useEffect(() => {
      let center
      if (map && shapeFile?.shapefile_json) {
        const shapeFileRes = map.data.addGeoJson(
          shapeFile?.shapefile_json as object
        )

        center = getCenter(shapeFileRes)
      }
      if (data && map) {
        map.data.setStyle((feature) => {
          const neighborhoodName: string = feature.getId() as string

          for (const key in data) {
            if (data[key].includes(neighborhoodName)) {
              return districtColors[key]
            }
          }
          console.warn("neighborhoodName not found ", neighborhoodName, data)

          // return default if district
          // not found in given response
          // but is already on json file
          return {
            strokeColor: "gray",
            strokeOpacity: 1,
            strokeWeight: 2,
            fillColor: "gray",
            fillOpacity: 0.1
          }
        })

        map.data.addListener("click", handleDistrictClick)
        if (center) {
          map.setCenter(center)
        }
      }

      return () => {
        if (map) {
          map.data.forEach((feature) => map.data.remove(feature))
          google.maps.event.clearListeners(map, "click")
        }
      }
    }, [map, data, handleDistrictClick, shapeFile])

    useEffect(() => {
      if (map && infoWindow) {
        infoWindow.open(map)
      }

      return () => {
        if (infoWindow) {
          infoWindow.open(null)
          // setInfoWindow(undefined)
        }
      }
    }, [map, infoWindow])

    return null
  },
  (prev, next) => deepEqual(prev.data, next.data)
)

export default Polygon
