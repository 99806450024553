import { useEffect, useState } from "react"
import apiClient from "../../../api"
import { OrganizationResponse } from "../../../types/userManagement/organizations"

interface OrganizationData {
  loading: boolean
  error?: string
  data?: OrganizationResponse
}

const useOrganizationsNoDealer = (): OrganizationData => {
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState<string>()
  const [data, setData] = useState<OrganizationResponse>()

  useEffect(() => {
    init()
  }, [])

  const init = async () => {
    setLoading(true)
    setError("true")
    setData(undefined)
    try {
      const { data } = await apiClient.get<OrganizationResponse>(
        "/user-management/su/getOrganizationsWithNoDealer"
      )
      setData(data)
    } catch (e: any) {
      setError(e)
    }
    setLoading(false)
  }

  return { loading, error, data }
}

export default useOrganizationsNoDealer
