import { useEffect, useState } from "react"
import useChangePassword from "../../../hooks/mutations/useChangePassword"
import notyf from "../../../util/notyf"

const ChangePassword = () => {
  const { changePassword,success  } = useChangePassword()
  const [pass, setPass] = useState("")
  const [oldPass, setOldPass] = useState("")

  const handleSubmit = (e?: React.FormEvent<HTMLFormElement>) => {
    e?.preventDefault()
    e?.stopPropagation()
    changePassword(oldPass, pass)
  }

  useEffect(() => {
    if(success) {
      notyf.open({message: "Password changed successfully"})
    }
  }, [success])

  return (
    <div className="relative mb-20 max-w-lg mx-auto text-center">
    
      <div className="mb-8 py-8 px-6 bg-white rounded shadow-form">
        <span className="text-primary font-bold text-lg">Change Password</span>
        <h3 className="mt-2 mb-6 text-2xl text-gray-500">
        Unavailable
          {/* Please provide your current password and new password */}
        </h3>
        <form onSubmit={handleSubmit}>
          <div className="mb-6 pr-4 flex items-center border rounded-lg">
            <input
              onChange={(e) => setOldPass(e.target.value)}
              className="py-4 pl-4 w-full placeholder-blueGray-400 bg-white focus:outline-none border-0 rounded-l-lg"
              placeholder="Your current password"
              type="password"
            />
          </div>

          <div className="mb-6 pr-4 flex items-center border rounded-lg">
            <input
              onChange={(e) => setPass(e.target.value)}
              className="py-4 pl-4 w-full placeholder-blueGray-400 bg-white focus:outline-none border-0 rounded-l-lg"
              placeholder="Your new password"
              type="password"
            />
          </div>
          <button
            className="w-full py-4 bg-primary hover:bg-primary rounded text-white text-sm font-bold"
            type="submit"
          >
            Change password
          </button>
        </form>
      </div>
    </div>
  )
}

export default ChangePassword
