import { useState } from "react"
import apiClient from "../../api"

interface FinalizeShapefileData {
  loading: boolean
  error?: string
  success?: boolean
  finalizeShapefile: (propertyName: string) => void
}

const useFinalizeShapefile = (): FinalizeShapefileData => {
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState<string>()
  const [success, setSuccess] = useState<boolean>()

  const finalizeShapefile = async (propertyName: string) => {
    setLoading(true)
    setError(undefined)
    setSuccess(false)
    try {
      await apiClient.post(
        `/sweeper-api/shapeFiles/finalize?neighborhood_property_name=${propertyName}`
      )
      setSuccess(true)
    } catch (e: any) {
      setError(e)
    }
    setLoading(false)
  }

  return { loading, error, success, finalizeShapefile }
}

export default useFinalizeShapefile
