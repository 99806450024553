import { useState } from "react"
import apiClient from "../../api"

interface ChangePasswordData {
  loading: boolean
  error?: string
  success?: boolean
  requestPasswordReset: (userId: string) => void
}

const useRequestPasswordReset = (): ChangePasswordData => {
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState<string>()
  const [success, setSuccess] = useState<boolean>()

  const requestPasswordReset = async (userId: string) => {
    setLoading(true)
    setError(undefined)
    setSuccess(false)
    try {
      var bodyFormData = new FormData()
      bodyFormData.append("target_user_id", userId)
      await apiClient.post(
        `user-management/requestPasswordReset`,
        bodyFormData,
        {
          withCredentials: true
        }
      )

      setSuccess(true)
    } catch (e: any) {
      setError(e)
    }
    setLoading(false)
  }

  return { loading, error, success, requestPasswordReset }
}

export default useRequestPasswordReset
