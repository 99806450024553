import { Option } from "react-multi-select-component/dist/types/lib/interfaces"

export const getHourRangeParameter = (hours: Option[]): string => {
  const orderedHours = hours.sort((h1, h2) => (h1.value > h2.value ? 1 : -1))
  return orderedHours.map((hour) => getRange(hour.label)).join(",")
}

const getRange = (hourLabel: string) => {
  const splittedValue = hourLabel.split("-")
  return (
    parseInt(splittedValue[0].split(":")[0].trim()) +
    "-" +
    parseInt(splittedValue[1].split(":")[0].trim())
  )
}
