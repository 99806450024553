import { useTranslation } from "react-i18next"
import shallow from "zustand/shallow"
import ChevronBottom from "../../assets/icons/chevron_bottom.svg"
import useStore from "../../store"

const FilterHeader = () => {
  const { t } = useTranslation()

  const { filterMenuVisible, toggleFilterMenuVisible } = useStore((state) => ({
    filterMenuVisible: state.filterMenuVisible,
    toggleFilterMenuVisible: state.toggleFilterMenuVisible
  }), shallow)

  return (
    <div className="flex items-center justify-between bg-primary px-6 py-4 rounded-md">
      <div className="text-white font-bold text-lg">
        {t("filter_comp.filter")}{" "}
      </div>
      <div
        className="px-2 py-4 cursor-pointer"
        onClick={toggleFilterMenuVisible}
      >
        <img src={ChevronBottom} alt="Arrow bottom" style={{ transform: filterMenuVisible ? "rotate(0deg)": "rotate(180deg)" }} />
      </div>
    </div>
  )
}

export default FilterHeader
