import { format, fromUnixTime, roundToNearestMinutes } from "date-fns"
import { useMemo } from "react"
import { useTranslation } from "react-i18next"
import {
  Area,
  AreaChart,
  CartesianGrid,
  Legend,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis
} from "recharts"

interface DataProps {
  name: string
  soc?: number
  kw?: number
}
interface Props {
  data: DataProps[]
}
const TEN_MINS = 5 * 60

const DPAreaChartBiaxial = ({ data }: Props) => {
  const { t } = useTranslation()

  const processedData = useMemo<DataProps[]>(() => {
    if (!data || data?.length < 1) {
      return []
    }
    const result: DataProps[] = []
    for (let i = 0; i < data.length - 1; i++) {
      const nextTime = parseInt(data[i + 1].name)
      const currentTime = parseInt(data[i].name)
      if (nextTime - currentTime > TEN_MINS) {
        let timeDiff = nextTime - currentTime

        const parts = Math.floor(timeDiff / TEN_MINS)

        for (let j = 1; j < parts; j++) {
          result.push({ name: (currentTime + TEN_MINS * j).toString() })
        }
      } else {
        result.push(data[i])
        result.push(data[i + 1])
      }
    }
    return result
  }, [data])

  if (processedData.length < 1) {
    return (
      <div className="flex w-full h-full items-center justify-center">
        <div className=" w-1/2  mx-auto px-5 py-4 bg-primary bg-opacity-10 rounded-md">
          <div className="text-xl text-center text-gray-500">
            {t("chart_comp.no_data")}
          </div>
        </div>
      </div>
    )
  }

  const formatXAxisLabel = (value: any) => {
    return format(
      roundToNearestMinutes(fromUnixTime(value), {
        nearestTo: 30
      }),
      "HH:mm"
    )
  }

  return (
    <ResponsiveContainer width="100%">
      <AreaChart
        width={500}
        height={400}
        data={processedData}
        margin={{
          top: 10,
          right: 30,
          left: 0,
          bottom: 0
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="name" tickFormatter={formatXAxisLabel} />
        <YAxis
          yAxisId="left"
          label={{
            value: "Level",
            angle: -90,
            position: "insideLeft"
          }}
        />
        <YAxis
          yAxisId="right"
          orientation="right"
          label={{
            value: "kW",
            angle: -90,
            position: "insideRight"
          }}
        />
        <Tooltip labelFormatter={(label, _) => <div>{format(fromUnixTime(parseInt(label)),"HH:mm:ss" )}</div> }/>
        <Legend />
        <Area
          yAxisId="left"
          type="monotoneX"
          dataKey="soc"
          stroke="#00A066"
          fill="#00a06638"
          name="battery level"
          activeDot={{ r: 5 }}
          strokeWidth={1.5}
          dot={false}
        />
        <Area
          yAxisId="right"
          type="monotoneX"
          dataKey="kw"
          stroke="#0055A0"
          fill="#0055a038"
          name="power use"
          activeDot={{ r: 5 }}
          strokeWidth={1.5}
          dot={false}
        />
      </AreaChart>
    </ResponsiveContainer>
  )
}

export default DPAreaChartBiaxial
