import bgImg from "../../assets/icons/nav/bg.png"
import useStore from "../../store"
import FilterIcon from "../../assets/icons/filter.svg"
import { useLocation } from "react-router-dom"
import { useMemo } from "react"

interface Props {
  onToggleMobileNav: () => void
}

const TopNavBar = ({ onToggleMobileNav }: Props) => {
  const location = useLocation()
  const toggleFilterMenuVisible = useStore(
    (state) => state.toggleFilterMenuVisible
  )

  const showFilterMobile = useMemo(() => {
    if (
      location.pathname == "/dashboard" ||
      location.pathname == "/dashboard/download-data"
    ) {
      return false
    }
    return true
  }, [location])

  return (
    <div
      className="flex bg-primary w-full h-16 justify-between"
      style={{
        background: `linear-gradient(90deg, #0055A0 6.04%, rgba(196, 196, 196, 0) 67.5%), url(${bgImg}) center`
      }}
    >
      <button
        className="p-5 focus:outline-none lg:hidden"
        onClick={onToggleMobileNav}
      >
        <svg
          className="h-5 w-5"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 20 20"
          stroke="#fff"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M4 6h20M4 12h20M4 18h20"
          />
        </svg>
      </button>
      {process.env.REACT_APP_SHOW_BANNER == "true" && (
        <div className="px-4 text-lg text-white bg-red-800 bg-opacity-50 w-full">
          This dashboard is still in development and the data should therefore
          not be taken as fact. If you encounter any bugs, errors or suggestions
          for improvement, please contact{" "}
          <a href="mailto:server-admin@fes.fayat.com" className="underline">
            server-admin@fes.fayat.com
          </a>
        </div>
      )}
      {showFilterMobile && (
        <button
          className="p-5 focus:outline-none lg:hidden z-20"
          onClick={toggleFilterMenuVisible}
        >
          <img src={FilterIcon} alt="filter" className="w-6 h-7" />
        </button>
      )}
    </div>
  )
}

export default TopNavBar
