import { Column, useSortBy, useTable } from "react-table"
import Scrollbars from "react-custom-scrollbars"
import { useEffect, useMemo, useState } from "react"
import RotateRightIcon from "../../../../assets/icons/rotate-right.svg"
import DeleteIcon from "../../../../assets/icons/trash.svg"
import EditIcon from "../../../../assets/icons/edit.svg"
import { useNavigate } from "react-router-dom"
import DealerModal from "./DealerModal"
import useDealers from "../../../../hooks/queries/userManagement/useDealers"
import useDeleteUser from "../../../../hooks/mutations/useDeleteUser"
import { DealerResponse, User } from "../../../../types/userManagement/dealers"
import AddDealerModal from "./AddDealerModal"
import DeleteModal from "../DeleteModal"
import { useNotyf } from "../../../../hooks/useNotyf"

const Dealers = () => {
  const columns: Column<User>[] = useMemo(() => {
    return [
      {
        Header: "Name",
        accessor: "first_name",
        Cell: ({
          cell: {
            row: { original }
          }
        }) => (
          <div className="text-sm w-20">
            <div className="text-gray-800 font-semibold">
              {original.first_name}
            </div>{" "}
            <div className="text-gray-600">{original.last_name}</div>
          </div>
        )
      },
      {
        Header: "Email",
        accessor: "email"
      },
      {
        Header: "Nr. org",
        accessor: "organization_count"
      },
      {
        Header: "Status",
        accessor: "user_active",
        Cell: ({
          cell: {
            row: { values }
          }
        }) => <div>{values.user_active ? "Active" : "Inactive"}</div>
      },
      {
        id: "0",
        Header: " ",
        Cell: () => (
          <div className="flex text-sm cursor-pointer">
            <img src={RotateRightIcon} className="w-4 mr-2" />
            <div className="text-gray-500">Reset password</div>
          </div>
        )
      },
      {
        id: "1",
        Header: " ",
        Cell: ({ cell: { row } }: { cell: any }) => (
          <div
            className="flex text-sm cursor-pointer"
            onClick={() => handleEditDealer(row.original)}
          >
            <img src={EditIcon} className="w-4 mr-2" />
            <div className="text-gray-500">Edit Dealer</div>
          </div>
        )
      },
      {
        id: "2",
        Header: " ",
        Cell: ({ cell: { row } }: { cell: any }) => (
          <div
            className="flex text-sm cursor-pointer"
            onClick={() => handleEditClick(row.original)}
          >
            <img src={EditIcon} className="w-4 mr-2" />
            <div className="text-gray-500">Edit Organizations</div>
          </div>
        )
      },
      {
        id: "4",
        Header: " ",
        Cell: ({ cell: { row } }: { cell: any }) => (
          <div
            className="flex text-sm cursor-pointer z-20"
            onClick={() => {
              setSelectedDealer(row.original)
              setDeleteIsOpen(true)
            }}
          >
            <img src={DeleteIcon} className="w-4 mr-2" />
            <div className="text-gray-500">Delete</div>
          </div>
        )
      }
    ]
  }, [])

  const { notyf } = useNotyf()
  const [isOpen, setIsOpen] = useState(false)
  const [deleteIsOpen, setDeleteIsOpen] = useState(false)

  const [addDealerModalOpen, setAddDealerModalOpen] = useState(false)
  const [selectedDealer, setSelectedDealer] = useState<User>()
  const [filteredValue, setFilteredValue] = useState("")

  const { data, error, loading, getDealers } = useDealers()
  const { deleteUser, success: deleteSuccess } = useDeleteUser()
  const filteredData = useMemo<DealerResponse>(() => {
    if (data?.users.length && filteredValue.length > 0) {
      return {
        users: data.users.filter((user) => {
          return Object.keys(user).some((key) => {
            if (typeof key == "string") {
              const userProperty = user[key as keyof User]
              if (typeof userProperty == "string") {
                return userProperty.toLowerCase().indexOf(filteredValue) != -1
              }
            }
          })
        })
      }
    }
    if (data) {
      return data
    }
    return { users: [] } as DealerResponse
  }, [filteredValue, data])

  const tableInstance = useTable(
    { columns, data: filteredData.users },
    useSortBy
  )

  const activeInactive = useMemo(() => {
    if (!data?.users) {
      return [0, 0]
    }

    const allUsers = data.users.length
    const inactiveUsers = data.users.filter((q) => !q.user_active)
    return [allUsers, inactiveUsers.length]
  }, [data])

  const { getTableBodyProps, headerGroups, rows, prepareRow } = tableInstance

  useEffect(() => {
    if (deleteSuccess) {
      notyf?.open({ message: "User deleted successfully", background: "green" })
      setSelectedDealer(undefined)
      getDealers()
    }
  }, [deleteSuccess])

  const closeModal = () => {
    setIsOpen(false)
    setSelectedDealer(undefined)
    getDealers()
  }

  const openModal = () => {
    setIsOpen(true)
  }

  const handleEditClick = (row: User) => {
    setSelectedDealer(row)

    openModal()
  }

  const handleEditDealer = (row: User) => {
    setSelectedDealer(row)
    setAddDealerModalOpen(true)
  }

  const handleAddDealerCloseModal = () => {
    getDealers()
    setAddDealerModalOpen(false)
  }

  const handleDelete = (userId?: number) => {
    if (userId) {
      setDeleteIsOpen(false)
      deleteUser(userId)
    }
  }

  return (
    <div className="w-full">
      <div className="bg-white flex justify-between">
        <div className="flex space-x-2 divide-x-2 items-center px-4 py-2">
          <div className="text-lg">Dealers</div>
          <div className="text-gray-600 text-sm pl-2">
            {activeInactive[0]} total {activeInactive[1]} inactive
          </div>
          <div className="pl-2">
            <input
              autoComplete="false"
              value={filteredValue}
              type="text"
              className="appearance-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none sm:text-sm"
              placeholder="Filter dealers"
              onChange={(e) => setFilteredValue(e.target.value)}
            />
          </div>
        </div>
        <div
          className="bg-primary text-white px-4 py-1 text-sm cursor-pointer flex items-center"
          onClick={() => {
            setSelectedDealer(undefined)
            setAddDealerModalOpen(true)
          }}
        >
          Add Dealer
        </div>
      </div>
      <div className="space-y-4">
        <table {...getTableBodyProps()} className="w-full">
          <thead className="bg-white sticky top-0 border-t border-b">
            {
              // Loop over the header rows
              headerGroups.map((headerGroup) => (
                // Apply the header row props
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {
                    // Loop over the headers in each row
                    headerGroup.headers.map((column) => (
                      // Apply the header cell props
                      <th
                        {...column.getHeaderProps(
                          column.getSortByToggleProps()
                        )}
                        scope="col"
                        className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer hover:bg-gray-50"
                      >
                        {column.render("Header")}
                        <span>
                          {column.isSorted
                            ? column.isSortedDesc
                              ? " 🔽"
                              : " 🔼"
                            : ""}
                        </span>
                      </th>
                    ))
                  }
                </tr>
              ))
            }
          </thead>
          <tbody
            {...getTableBodyProps()}
            className="bg-white divide-y divide-gray-200"
          >
            {
              // Loop over the table rows
              rows.map((row) => {
                // Prepare the row for display
                prepareRow(row)
                return (
                  // Apply the row props
                  <tr
                    {...row.getRowProps()}
                    className="cursor-pointer hover:bg-gray-100"
                  >
                    {
                      // Loop over the rows cells
                      row.cells.map((cell) => {
                        // Apply the cell props
                        return (
                          <td
                            {...cell.getCellProps()}
                            className="px-6 py-4 whitespace-nowrap"
                          >
                            {
                              // Render the cell contents
                              cell.render("Cell")
                            }
                          </td>
                        )
                      })
                    }
                  </tr>
                )
              })
            }
          </tbody>
        </table>
      </div>
      <DealerModal
        visible={isOpen}
        closeModal={closeModal}
        selectedDealer={selectedDealer}
      />
      <AddDealerModal
        visible={addDealerModalOpen}
        closeModal={handleAddDealerCloseModal}
        selectedDealer={selectedDealer}
      />

      <DeleteModal
        visible={deleteIsOpen}
        closeModal={() => setDeleteIsOpen(false)}
        userId={selectedDealer?.id}
        onConfirm={handleDelete}
      />
    </div>
  )
}

export default Dealers
