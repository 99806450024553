import { useState } from "react"
import apiClient from "../../api"

interface UploadShapeFileData {
  loading: boolean
  error?: string
  success?: boolean
  uploadShapeFile: (formData: FormData) => void
}

const useUploadShapeFile = (): UploadShapeFileData => {
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState<string>()
  const [success, setSuccess] = useState<boolean>()

  const uploadShapeFile = async (formData: FormData) => {
    setLoading(true)
    setError(undefined)
    setSuccess(false)
    try {
      await apiClient.post("/sweeper-api/shapeFiles", formData, {
        withCredentials: true
      })
      setSuccess(true)
    } catch (e: any) {
      setError(e)
    }
    setLoading(false)
  }

  return { loading, error, success, uploadShapeFile }
}

export default useUploadShapeFile
