import { format, fromUnixTime } from "date-fns"
import qs from "qs"
import { useCallback, useEffect, useState } from "react"
import shallow from "zustand/shallow"
import apiClient from "../../api"
import useStore from "../../store"
import { HeatMapDistrictResponse } from "../../types/heatMapDistrict"
import { getHourRangeParameter } from "../../util"

interface HeatMapDistrictData {
  loading: boolean
  error?: string
  data?: HeatMapDistrictResponse
  heatMapDistrictMeasure?: keyof HeatMapDistrictResponse
  getData: () => void
}

const useHeatMapDistrict = (): HeatMapDistrictData => {
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState<string>()
  const [data, setData] = useState<HeatMapDistrictResponse>()

  const {
    selectedEquipments,
    startDate,
    endDate,
    timePeriod,
    heatMapDistrictMeasure,
    selectedHours
  } = useStore(
    (state) => ({
      selectedEquipments: state.selectedEquipments,
      startDate: state.startDate,
      endDate: state.endDate,
      timePeriod: state.timePeriod,
      heatMapDistrictMeasure: state.heatMapDistrictMeasure,
      selectedHours: state.selectedHours
    }),
    shallow
  )

  useEffect(() => {
    getData()
  }, [])

  const buildRequestParams = useCallback(() => {
    const params: Record<string, string> = {}

    if (selectedEquipments?.length) {
      params["vehicle_ids"] = selectedEquipments.map((d) => d.value).join(",")
    }
    // else {
    //   if (selectedBrands?.length) {
    //     params["brands_filter"] = selectedBrands.map((d) => d.value).join(",")
    //   }
    //   if (selectedTypes?.length) {
    //     params["types_filter"] = selectedTypes.map((d) => d.value).join(",")
    //   }
    //   if (selectedModels?.length) {
    //     params["models_filter"] = selectedModels.map((d) => d.value).join(",")
    //   }
    // }
    // if (selectedDistricts?.length) {
    //   params["district_ids"] = selectedDistricts.map((d) => d.value).join(",")
    // }
    if (timePeriod) {
      params["half_of_day_filter"] = timePeriod
    }
    if (startDate) {
      params["date_start"] = format(fromUnixTime(startDate), "yyyy-MM-dd")
    }
    if (endDate) {
      params["date_end"] = format(fromUnixTime(endDate), "yyyy-MM-dd")
    }
    if (selectedHours?.length) {
      params["hours_filter"] = getHourRangeParameter(selectedHours)
    }

    return params
  }, [
    // selectedDistricts,
    // selectedBrands,
    // selectedTypes,
    // selectedModels,
    selectedHours,
    selectedEquipments,
    startDate,
    endDate,
    timePeriod
  ])

  const getData = async () => {
    const reqParams = buildRequestParams()

    if (!reqParams) {
      return
    }
    setLoading(true)
    try {
      const { data } = await apiClient.get<HeatMapDistrictResponse>(
        `/sweeper-api/dashboards/neighborhood_map?${qs.stringify(reqParams)}`
      )
      setData(data)
    } catch (e: any) {
      setError(e)
    }
    setLoading(false)
  }

  return { loading, error, data, heatMapDistrictMeasure, getData }
}

export default useHeatMapDistrict
