import { useState } from "react"
import { Scrollbars } from "react-custom-scrollbars"
import ChevronBottom from "../../../assets/icons/chevron_bottom.svg"
import { DailyTotalsData } from "../../../types/dailyReport"
import { useTranslation } from "react-i18next"

interface Props {
  data?: DailyTotalsData
  isElectricVehicle?: boolean
}

const Row = ({ title, value }: { title: string; value: any }) => (
  <div className="flex items-center justify-between">
    <div className="font-bold text-white">{title}</div>
    <div className="text-white">{value}</div>
  </div>
)

// make scroll hidden when mouse is not hovered
const MapDailyData = ({ data, isElectricVehicle }: Props) => {
  const { t } = useTranslation()
  const [visible, setVisible] = useState(true)
  if (!data) {
    return null
  }

  const toggleVisible = () => setVisible((visible) => !visible)

  const getTotalTime = (travelTime: string, sweepTime: string) => {
    const sum = parseFloat(travelTime) + parseFloat(sweepTime)
    return sum.toFixed(2)
  }

  return (
    <div
      className="absolute top-10"
      style={{ width: visible ? "17rem" : "4rem" }}
    >
      <div className="mt-4 mr-2">
        <div className="bg-primary px-4 py-5 rounded-2xl">
          <div
            style={{
              position: "absolute",
              bottom: 18,
              width: "82%",
              height: 30,
              background: "linear-gradient(180deg, #0055a03d 0%, #0055A0 100%)",
              zIndex: 2
            }}
          ></div>
          <div
            className={`flex items-center justify-between ${
              visible ? "mb-4" : "mb-0"
            }`}
          >
            <img
              alt="img"
              src={ChevronBottom}
              className=" p-1 cursor-pointer"
              onClick={toggleVisible}
              style={{
                width: visible ? 24 : 32,
                transform: visible ? "rotate(180deg)" : "rotate(0deg)"
              }}
            />
            {visible && (
              <div className="font-bold text-white">
                {t("daily_report.daily_data")}
              </div>
            )}
          </div>
          {visible && (
            <Scrollbars
              style={{ width: "100%", height: "10rem", marginBottom: 5 }}
            >
              <div
                style={{
                  height: 20,
                  position: "absolute",
                  backgroundImage:
                    "radial-gradient(farthest-side at 50% 100%, rgba(0, 0, 0, 0.4) 0%, rgba(0, 0, 0, 0) 100%)"
                }}
              />
              <div className="space-y-1 max-h-60 pr-3">
                <Row
                  title={t("daily_report.total_time")}
                  value={getTotalTime(
                    data.total_travel_time,
                    data.total_sweep_hours
                  )}
                />
                <Row
                  title={t("daily_report.working_time")}
                  value={data.total_sweep_hours}
                />
                <Row
                  title={t("daily_report.sweep_distance")}
                  value={data.total_sweep_distance}
                />
                <Row
                  title="Travel distance"
                  value={data.total_travel_distance}
                />
                <Row
                  title={t("daily_report.total_distance")}
                  value={data.total_distance}
                />
                <Row
                  title={t("daily_report.sweep_total_distance")}
                  value={data.sweep_dist_percentage}
                />
                {!isElectricVehicle && (
                  <Row
                    title={t("daily_report.liters_fuel_used")}
                    value={data.total_fuel_consumption}
                  />
                )}
                <Row
                  title={t("daily_report.liters_water_used")}
                  value={data.total_water_consumption}
                />
                <Row
                  title={t("daily_report.water_fillup_liters")}
                  value={data.water_usage_per_km_swept}
                />
                {!isElectricVehicle && (
                  <Row
                    title={t("daily_report.fuel_fillup_count")}
                    value={data.total_fuel_fill_ups}
                  />
                )}
                <Row
                  title={t("daily_report.water_fillup_count")}
                  value={data.total_water_fill_ups}
                />
                <Row
                  title={t("daily_report.third_brush_hours")}
                  value={data.third_brush_hours}
                />
                {isElectricVehicle && (
                  <>
                    <Row
                      title={"Total kWh"}
                      value={data.total_battery_consumption}
                    />
                    <Row
                      title={"Average kW"}
                      value={data.average_battery_consumption}
                    />
                  </>
                )}

                <div className="h-3" />
                {/* <Row title={t("daily_report.brush_hours")} value={"ASK"} />
                <Row title={t("daily_report.turbine_hours")} value={"ASK"} />
                <Row title={t("daily_report.ramp_hours")} value={"ASK"} /> */}
              </div>
            </Scrollbars>
          )}
        </div>
      </div>
    </div>
  )
}

export default MapDailyData
