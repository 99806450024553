import {
  BrowserRouter, Route,
  Routes
} from "react-router-dom"
import CreateProfile from "./pages/CreateProfile"
import Dashboard from "./pages/dashboard"
import ForgotPassword from "./pages/ForgotPassword"
import LoadingPage from "./pages/Loading"
import Login from "./pages/Login"
import ResetPassword from "./pages/ResetPassword"

function App() {
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route path="/reset-password/:token" element={<ResetPassword />} />
          <Route path="/create-profile/:token" element={<CreateProfile />} />
          <Route path="/loading" element={<LoadingPage />} />
          <Route path="dashboard/*" element={<Dashboard />} />
        </Routes>
      </BrowserRouter>
    </>
  )
}

export default App
