import DatePicker from "react-datepicker"

interface Props {
  value: Date | null | undefined
  onChange: (date: Date) => void
}

const DateFilter = ({ value, onChange }: Props) => {
  return (
    <div>
      <DatePicker
        dateFormat="dd-MM-yyyy"
        selected={value ?? new Date()}
        onChange={onChange}
      />
    </div>
  )
}

export default DateFilter
