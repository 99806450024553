import { useEffect, useMemo, useState } from "react"
import { useLocation, useNavigate, useParams } from "react-router-dom"
import useForgotPassword from "../hooks/mutations/useForgotPassword"
import useUpdatePassword from "../hooks/mutations/useUpdatePassword"
import useCheckResetToken from "../hooks/queries/useCheckResetToken"
import notyf from "../util/notyf"

const ResetPassword = () => {
  const [mounted, setMounted] = useState(false)
  const [password, setPassword] = useState("")
  const [repeatPassword, setRepeatPassword] = useState("")
  const navigate = useNavigate()

  const { token } = useParams()
  const { data, checkToken } = useCheckResetToken()
  const { updatePassword, success } = useUpdatePassword()

  useEffect(() => {
    if (!token) {
      return
    }
    checkToken(token)
  }, [token])

  useEffect(() => {
    if (data) {
      setMounted(true)
    }
  }, [data])

  useEffect(() => {
    if (success) {
      notyf.open({
        message: "Password was changed successfully",
        background: "green"
      })
      navigate("/")
    }
  }, [success])

  const handleSubmit = (e?: React.FormEvent<HTMLFormElement>) => {
    e?.preventDefault()
    e?.stopPropagation()

    if (password.trim().length == 0 || repeatPassword.trim().length === 0) {
      return notyf.error("Please fill all the fields")
    }
    if (password !== repeatPassword) {
      return notyf.error("Password does not match")
    }
    if (!token) {
      return
    }
    updatePassword(token, password)
  }
  if (!mounted) {
    return null
  }
  return (
    <div className="flex items-center justify-center w-full h-full relative mb-20 max-w-lg mx-auto text-center">
      <div className=" mb-8 py-8 px-6 bg-white rounded shadow-form w-full">
        <div className="text-primary font-bold text-2xl mb-4">
          Reset Password
        </div>
        <form onSubmit={handleSubmit}>
          <div className="mb-6 pr-4">
            <input
              onChange={(e) => setPassword(e.target.value)}
              className="block border border-grey-light w-full p-3 rounded mb-4"
              placeholder="Your new password"
              type="password"
            />
            <input
              onChange={(e) => setRepeatPassword(e.target.value)}
              className="block border border-grey-light w-full p-3 rounded mb-4"
              placeholder="Repeat new password"
              type="password"
            />
          </div>
          <button
            className="w-full py-4 bg-primary hover:bg-primary rounded text-white text-sm font-bold"
            type="submit"
          >
            Change password
          </button>
        </form>
      </div>
    </div>
  )
}

export default ResetPassword
