interface Props {
  active?: boolean
}
const MaintenanceIcon = ({ active }: Props) => {
  return (
    <svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18 15.75v-5c0-3.07-1.63-5.64-4.5-6.32v-.68c0-.83-.67-1.5-1.5-1.5s-1.5.67-1.5 1.5v.68C7.64 5.11 6 7.67 6 10.75v5l-2 2v1h16v-1l-2-2Zm-6 6c1.1 0 2-.9 2-2h-4c0 1.1.9 2 2 2Zm-4-5h8v-6c0-2.48-1.51-4.5-4-4.5s-4 2.02-4 4.5v6ZM7.58 3.83 6.15 2.4c-2.4 1.83-3.98 4.65-4.12 7.85h2a8.445 8.445 0 0 1 3.55-6.42Zm14.39 6.42h-2a8.495 8.495 0 0 0-3.54-6.42l1.42-1.43c2.39 1.83 3.97 4.65 4.12 7.85Z"
        fill={active ? "#0055A0" : "#fff"}
      />
    </svg>
  )
}

export default MaintenanceIcon
