import { Notyf } from "notyf"
import { Option } from "react-multi-select-component/dist/types/lib/interfaces"
import { SetState } from "zustand"
import { DatapliedState } from "."
import { VehicleMetadataResponse } from "../hooks/queries/useVehicleMetadata"
import { DistrictResponse } from "../types/districts"
import { EnumHeatMapMeasure } from "../types/heatMap"
import { HeatMapDistrictResponse } from "../types/heatMapDistrict"
import { VehicleResponse } from "../types/vehicle"

export interface CacheSlice {
  selectedOrganization?: number
  notyf?: Notyf
  vehicleMetadata?: VehicleMetadataResponse
  districts: DistrictResponse[]
  vehicles: VehicleResponse[]
  filterMenuVisible: boolean

  // filters only for daily report
  // not shared with other pages
  dailyReportDate?: number
  dailyReportEquipmentId?: string
  dailyReportTimePeriod?: string
  dailyReportTimestamp?: Date
  isElectricVehicle?: boolean

  //selected options
  selectedDistricts?: Option[]
  selectedBrands?: Option[]

  selectedModels?: Option[]
  selectedTypes?: Option[]
  selectedEquipments?: Option[]
  filteredEquipments?: VehicleResponse[]
  selectedLimitedEquipments?: Option[]
  selectedHours?: Option[]
  timePeriod?: string
  startDate?: number
  endDate?: number
  heatMapMeasure?: EnumHeatMapMeasure
  heatMapDistrictMeasure?: keyof HeatMapDistrictResponse

  setSelectedOrganization: (orgId: number) => void

  toggleFilterMenuVisible: () => void
  setDailyReportDate: (date: number) => void
  setDailyReportEquipmentId: (equipmentId: string) => void
  setDailyReportTimePeriod: (period?: string) => void
  setDailyReportTimestamp: (ts?: Date) => void

  // shared filters
  setDistricts: (districts: DistrictResponse[]) => void
  setVehicles: (vehicles: VehicleResponse[]) => void
  setVehicleMetadata: (metadata?: VehicleMetadataResponse) => void
  setTimePeriod: (period?: string) => void
  setStartDate: (startDate: number) => void
  setEndDate: (endDate: number) => void
  setHeatMapMeasure: (heatMapMeasure: EnumHeatMapMeasure) => void
  setHeatMapDistrictMeasure: (
    heatMapDistrictMeasure: keyof HeatMapDistrictResponse
  ) => void

  // update selected value on shared filters
  setSelectedDistricts: (districts: Option[]) => void
  setSelectedBrands: (brands: Option[]) => void
  setSelectedModels: (models: Option[]) => void
  setSelectedTypes: (types: Option[]) => void
  setSelectedEquipments: (equipments: Option[]) => void
  setSelectedHours: (hours?: Option[]) => void
  setFilteredEquipments: (equipments: VehicleResponse[]) => void
  setSelectedLimitedEquipments: (equipments: Option[]) => void

  setIsElectricVehicle: (b: boolean) => void

  // notification library
  setNotyf: (notyf: Notyf) => void
}

const createCacheSlice = (set: SetState<DatapliedState>) => ({
  districts: [],
  vehicles: [],
  filterMenuVisible: true,
  setDistricts: (districts: DistrictResponse[]) => {
    set(() => ({ districts }))
  },
  setVehicles: (vehicles: VehicleResponse[]) => {
    set(() => ({ vehicles }))
  },
  setVehicleMetadata: (metadata?: VehicleMetadataResponse) => {
    set(() => ({ vehicleMetadata: metadata }))
  },
  toggleFilterMenuVisible: () => {
    set((state) => ({ filterMenuVisible: !state.filterMenuVisible }))
  },
  setTimePeriod: (period?: string) => {
    set(() => ({ timePeriod: period }))
  },
  setStartDate: (startDate: number) => {
    set(() => ({ startDate }))
  },
  setEndDate: (endDate: number) => {
    set(() => ({ endDate }))
  },
  setDailyReportDate: (date: number) => {
    set(() => ({ dailyReportDate: date }))
  },
  setDailyReportEquipmentId: (equipmentId: string) => {
    set(() => ({ dailyReportEquipmentId: equipmentId }))
  },
  setDailyReportTimePeriod: (period?: string) => {
    set(() => ({ dailyReportTimePeriod: period }))
  },
  setDailyReportTimestamp: (ts?: Date) => {
    set(() => ({ dailyReportTimestamp: ts }))
  },
  setSelectedDistricts: (districts: Option[]) => {
    set(() => ({ selectedDistricts: districts }))
  },
  setSelectedBrands: (brands: Option[]) => {
    set(() => ({ selectedBrands: brands }))
  },
  setSelectedModels: (models: Option[]) => {
    set(() => ({ selectedModels: models }))
  },
  setSelectedTypes: (types: Option[]) => {
    set(() => ({ selectedTypes: types }))
  },
  setSelectedEquipments: (equipments: Option[]) => {
    set(() => ({ selectedEquipments: equipments }))
  },
  setFilteredEquipments: (equipments: VehicleResponse[]) => {
    set(() => ({ filteredEquipments: equipments }))
  },
  setSelectedLimitedEquipments: (equipments: Option[]) => {
    set(() => ({ selectedLimitedEquipments: equipments }))
  },
  setSelectedHours: (hours?: Option[]) => {
    set(() => ({ selectedHours: hours }))
  },
  setHeatMapMeasure: (heatMapMeasure: EnumHeatMapMeasure) => {
    set(() => ({ heatMapMeasure }))
  },
  setHeatMapDistrictMeasure: (
    heatMapDistrictMeasure: keyof HeatMapDistrictResponse
  ) => {
    set(() => ({ heatMapDistrictMeasure }))
  },
  setNotyf: (notyf: Notyf) => {
    set(() => ({ notyf }))
  },
  setSelectedOrganization: (orgId: number) => {
    set(() => ({ selectedOrganization: orgId }))
  },
  setIsElectricVehicle: (b: boolean) => {
    set(() => ({ isElectricVehicle: b }))
  }
})

export default createCacheSlice
