import { useState } from "react"
import apiClient from "../../api"

interface DeleteDistrictData {
  loading: boolean
  error?: string
  success?: boolean
  detachVehicle: (vehicleId: string) => void
}

const useDetachVehicle = (): DeleteDistrictData => {
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState<string>()
  const [success, setSuccess] = useState<boolean>()

  const detachVehicle = async (vehicleId: string) => {
    setLoading(true)
    setError(undefined)
    setSuccess(false)
    try {
      var bodyFormData = new FormData()
      bodyFormData.append("target_vehicle_id", vehicleId.toString())
      await apiClient.post(
        "/sweeper-api/districts/setVehicleNoDistrict",
        bodyFormData,
        {
          withCredentials: true
        }
      )
      setSuccess(true)
    } catch (e: any) {
      setError(e)
    }
    setLoading(false)
  }

  return { loading, error, success, detachVehicle }
}

export default useDetachVehicle
