import { useState } from "react"
import apiClient from "../../api"
import notyf from "../../util/notyf"

interface SignUpWithInviteData {
  loading: boolean
  error?: string
  success?: boolean
  signup: (formData: FormData) => void
}

const useSignUpWithInvite = (): SignUpWithInviteData => {
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState<string>()
  const [success, setSuccess] = useState<boolean>()

  const signup = async (formData: FormData) => {
    setLoading(true)
    setError(undefined)
    setSuccess(false)
    try {
      await apiClient.post("/user-management/signupWithInvitation", formData, {
        withCredentials: true
      })
      setSuccess(true)
    } catch (e: any) {
      if (e?.response?.data?.detail) {
        notyf.error(e.response.data.detail)
      }
      setError(e)
    }
    setLoading(false)
  }

  return { loading, error, success, signup }
}

export default useSignUpWithInvite
