import { useEffect } from "react"
import { useNavigate } from "react-router-dom"
import { refreshToken } from "../api"
import useMyOrganizations from "../hooks/queries/userManagement/useMyOrganizations"
import { useAuth } from "../hooks/useAuth"

const LoadingPage = () => {
  const { loggedIn } = useAuth()
  const navigate = useNavigate()
  const { getOrganizations } = useMyOrganizations()

  useEffect(() => {
    const init = async () => {
      if (typeof loggedIn == "boolean" && loggedIn === false) {
        navigate("/", { replace: true })
      } else if (typeof loggedIn == "undefined") {
        // refresh token

        try {
          const refreshData = await refreshToken()

          getOrganizations(refreshData as string)
        } catch (e) {
        }
      }
    }

    init()
  }, [loggedIn])

  return (
    <div className="flex flex-col w-screen h-screen items-center justify-center bg-primary">
      <div className="text-3xl text-white"> Please wait...</div>
      <svg
        version="1.1"
        id="L4"
        xmlns="http://www.w3.org/2000/svg"
        x="0px"
        y="0px"
        viewBox="0 0 100 100"
        enable-background="new 0 0 0 0"
        style={{ maxWidth: 400, marginLeft: "11rem" }}
      >
        <circle fill="#fff" stroke="none" cx="6" cy="50" r="6">
          <animate
            attributeName="opacity"
            dur="1s"
            values="0;1;0"
            repeatCount="indefinite"
            begin="0.1"
          ></animate>
        </circle>
        <circle fill="#fff" stroke="none" cx="26" cy="50" r="6">
          <animate
            attributeName="opacity"
            dur="1s"
            values="0;1;0"
            repeatCount="indefinite"
            begin="0.2"
          ></animate>
        </circle>
        <circle fill="#fff" stroke="none" cx="46" cy="50" r="6">
          <animate
            attributeName="opacity"
            dur="1s"
            values="0;1;0"
            repeatCount="indefinite"
            begin="0.3"
          ></animate>
        </circle>
      </svg>
    </div>
  )
}

export default LoadingPage
