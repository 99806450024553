import Card from "../../../../components/Cards"
import Filter from "../../../../components/Filter"
import FilterGroup from "../../../../components/Filter/FilterGroup"
import FilterSection from "../../../../components/Filter/FilterSection"
import ColorfulLabel from "../../../../components/Filter/Input/ColorfulLabel"
import { PageFilterProps } from "../../../../types/pageFilter"
import DailyReportDateFilter from "./DateFilter"
import DailyReportEquipmentFilter from "./EquipmentFilter"
import MorningAfternoonFilter from "./MorningAfternoonFilter"

const DailyReportFilters = ({ onApplyFilter }: PageFilterProps) => {
  return (
    <Filter>
      <Card className="sm:mb-0 lg:mb-4">
        <FilterGroup onApplyFilter={onApplyFilter}>
          <DailyReportDateFilter />
          <DailyReportEquipmentFilter />
          <MorningAfternoonFilter />
          <FilterSection title="Map coloring" className="font-bold">
            <ColorfulLabel color="#64CA3A" title="Travel" />
            <ColorfulLabel color="#FA8925" title="Working" />
            <ColorfulLabel color="#F00" title="Off" />
          </FilterSection>
        </FilterGroup>
      </Card>
    </Filter>
  )
}
export default DailyReportFilters
