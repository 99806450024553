interface Props {
  active?: boolean
}
const HeatmapIcon = ({ active }: Props) => {
  return (
    <svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        id="icon/image/flare_24px"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11 1H13V7H11V1ZM9.17 7.76L7.05 5.64L5.64 7.05L7.76 9.17L9.17 7.76ZM7 11H1V13H7V11ZM18.36 7.05L16.95 5.64L14.83 7.76L16.24 9.17L18.36 7.05ZM17 11H23V13H17V11ZM12 9C10.34 9 9 10.34 9 12C9 13.66 10.34 15 12 15C13.66 15 15 13.66 15 12C15 10.34 13.66 9 12 9ZM16.95 18.36L14.83 16.24L16.24 14.83L18.36 16.95L16.95 18.36ZM5.64 16.95L7.05 18.36L9.17 16.24L7.76 14.83L5.64 16.95ZM13 23H11V17H13V23Z"
        fill={active ? "#0055A0" : "#fff"}
      />
    </svg>
  )
}

export default HeatmapIcon
