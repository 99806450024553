import { useMemo } from "react"
import { useTranslation } from "react-i18next"
import { ActionMeta, SingleValue } from "react-select"
import shallow from "zustand/shallow"
import FilterSection from "../../../../components/Filter/FilterSection"
import SelectFilterInput from "../../../../components/Filter/Input/SelectFilterInput"
import useStore from "../../../../store"
import { VehicleResponse } from "../../../../types/vehicle"

const DailyReportEquipmentFilter = () => {
  const { t } = useTranslation()

  const { vehicles, dailyReportEquipmentId, setDailyReportEquipmentId } =
    useStore(
      (state) => ({
        vehicles: state.vehicles,
        dailyReportEquipmentId: state.dailyReportEquipmentId,
        setDailyReportEquipmentId: state.setDailyReportEquipmentId
      }),
      shallow
    )

  const handleFilterChange = (
    value: SingleValue<VehicleResponse>,
    _: ActionMeta<VehicleResponse>
  ) => {
    if (value) {
      setDailyReportEquipmentId(value.equipment_id)
    }
  }

  const defaultValue = useMemo<any>(() => {
    for (const vehicle of vehicles) {
      if (vehicle.equipment_id === dailyReportEquipmentId) {
        return { name: vehicle.name, id: dailyReportEquipmentId }
      }
    }
  }, [dailyReportEquipmentId, vehicles])

  return (
    <FilterSection title={t("daily_report.equipment")} className="font-bold">
      <SelectFilterInput
        defaultValue={defaultValue}
        options={vehicles}
        getOptionLabel={(item) => item.name}
        getOptionValue={(item) => item.id.toString()}
        maxMenuHeight={300}
        onChange={handleFilterChange}
      />
    </FilterSection>
  )
}

export default DailyReportEquipmentFilter
