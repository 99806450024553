import { Column, useSortBy, useTable } from "react-table"
import Scrollbars from "react-custom-scrollbars"
import { useEffect, useMemo, useState } from "react"
import RotateRightIcon from "../../../../assets/icons/rotate-right.svg"
import DeleteIcon from "../../../../assets/icons/trash.svg"
import EditIcon from "../../../../assets/icons/edit.svg"
import { useNavigate } from "react-router-dom"
import useCityAdmins from "../../../../hooks/queries/userManagement/useCityAdmins"
import {
  CityAdminClass,
  CityAdminsResponse,
  Organization
} from "../../../../types/userManagement/cityAdmins"
import WarnIcon from "../../../../assets/icons/warn.svg"
import AddCityAdminModal from "./AddCityAdminModal"
import DeleteModal from "../DeleteModal"
import { useNotyf } from "../../../../hooks/useNotyf"
import useDeleteUser from "../../../../hooks/mutations/useDeleteUser"
import useDemoteCityAdmin from "../../../../hooks/mutations/useDemoteCityAdmin"

const CityAdmins = () => {
  const columns: Column<Organization>[] = useMemo(() => {
    return [
      {
        Header: "Company Name",
        accessor: "organization_name",
        Cell: ({
          cell: {
            row: { values }
          }
        }) => (
          <div
            style={{
              maxWidth: 200,
              textOverflow: "ellipsis",
              overflow: "hidden"
            }}
          >
            {values.organization_name}
          </div>
        )
      },
      {
        Header: "Company status",
        accessor: "organization_active",
        Cell: ({
          cell: {
            row: { original }
          }
        }: {
          cell: any
        }) => <div>{original.organization_active ? "Active" : "Inactive"}</div>
      },
      {
        Header: "Dealer",
        accessor: "dealer_name",
        Cell: ({
          cell: {
            row: { values }
          }
        }) => (
          <div className="flex">
            <span className={values.dealer_name !== "NA" ? "" : "text-red-600"}>
              {values.dealer_name == "NA" ? (
                <div className="flex">
                  <img src={WarnIcon} className="w-4 mr-2" /> No Dealer
                </div>
              ) : (
                values.dealer_name
              )}
            </span>
          </div>
        )
      },
      {
        Header: "City Admin Email",
        accessor: "city_admin",
        Cell: ({
          cell: {
            row: { values }
          }
        }) => (
          <div className="flex">
            <span
              className={`text-sm ${
                values.city_admin !== "NA" ? "" : "text-red-600"
              }`}
            >
              {values.city_admin == "NA" ? (
                <div className="flex">
                  {" "}
                  <img src={WarnIcon} className="w-4 mr-2" /> No City Admin
                </div>
              ) : (
                values.city_admin.email
              )}{" "}
            </span>
          </div>
        )
      },

      {
        Header: "City Admin Name",
        Cell: ({
          cell: {
            row: { values }
          }
        }: {
          cell: any
        }) => (
          <div className="flex">
            <span
              className={`text-sm ${
                values.city_admin !== "NA" ? "" : "text-red-600"
              }`}
            >
              {values.city_admin == "NA" ? (
                <div className="flex">
                  {" "}
                  <img src={WarnIcon} className="w-4 mr-2" /> No City Admin
                </div>
              ) : (
                values.city_admin.first_name
              )}{" "}
            </span>
          </div>
        )
      },
      {
        Header: "City admin Status",
        // accessor: "active",
        Cell: ({
          cell: {
            row: { original }
          }
        }: {
          cell: any
        }) => (
          <div>
            {original.city_admin == "NA"
              ? "NA"
              : original.city_admin.user_active
              ? "Active"
              : "Inactive"}
          </div>
        )
      },

      {
        id: "1",
        Header: " ",
        Cell: ({ cell: { row } }: { cell: any }) => (
          <div
            className="flex text-sm cursor-pointer"
            onClick={() => handleEditCityAdmin(row.original)}
          >
            <img src={EditIcon} className="w-4 mr-2" />
            <div className="text-gray-500">Edit</div>
          </div>
        )
      },
      {
        id: "2",
        Header: " ",
        Cell: ({
          cell: {
            row: { original }
          }
        }: {
          cell: any
        }) => (
          <div
            className="flex text-sm cursor-pointer"
            onClick={() => {
              setSelectedUser(original.city_admin)
              setDeleteIsOpen(true)
            }}
          >
            <img src={DeleteIcon} className="w-4 mr-2" />
            <div className="text-gray-500">Demote</div>
          </div>
        )
      },
      {
        id: "3",
        Header: "  ",
        Cell: ({ cell: { row } }: { cell: any }) => (
          <div
            className={`bg-primary text-white px-4 py-1 text-sm cursor-pointer items-center ${
              row.original.city_admin === "NA" ? "flex" : "hidden"
            }`}
            onClick={() => {
              setSelectedOrganizationRow(row.original.organization_id)
              setIsOpen(true)
            }}
          >
            Add City Admin
          </div>
        )
      }
    ]
  }, [])

  const [isOpen, setIsOpen] = useState(false)
  const [deleteIsOpen, setDeleteIsOpen] = useState(false)
  const [selectedUser, setSelectedUser] = useState<CityAdminClass | null>(null)
  const [selectedOrganizationRow, setSelectedOrganizationRow] = useState<
    number | null
  >(null)
  const [filteredValue, setFilteredValue] = useState("")
  const { data, error, loading, getCityAdmins } = useCityAdmins()

  const filteredData = useMemo<CityAdminsResponse>(() => {
    if (data?.organizations.length && filteredValue.length > 0) {
      return {
        organizations: data.organizations.filter((org) => {
          return Object.keys(org).some((key) => {
            if (typeof key == "string") {
              const userProperty = org[key as keyof Organization]
              if (typeof userProperty == "string") {
                return userProperty.toLowerCase().indexOf(filteredValue) != -1
              }
            }
          })
        })
      }
    }
    if (data) {
      return data
    }
    return { organizations: [] } as CityAdminsResponse
  }, [filteredValue, data])

  const tableInstance = useTable(
    { columns, data: filteredData.organizations },
    useSortBy
  )
  const { notyf } = useNotyf()
  // const { deleteUser, success: successDelete } = useDeleteUser()
  const { demoteCityAdmin, success: successDemote } = useDemoteCityAdmin()
  const { getTableBodyProps, headerGroups, rows, prepareRow } = tableInstance

  useEffect(() => {
    if (successDemote) {
      notyf?.open({ message: "User demoted successfully", background: "green" })
      setSelectedUser(null)
      getCityAdmins()
    }
  }, [successDemote])

  const handleDeleteClick = (userId?: number) => {
    if (userId) {
      setDeleteIsOpen(false)
      demoteCityAdmin(userId)
    }
  }
  const handleModalClose = () => {
    setIsOpen(false)
    getCityAdmins()
    setSelectedUser(null)
  }

  const handleEditCityAdmin = (org: Organization) => {
    if (typeof org.city_admin == "string") {
      return
    }
    setSelectedUser(org.city_admin as CityAdminClass)
    setIsOpen(true)
  }

  return (
    <div className="w-full h-full">
      <div className="bg-white flex justify-between">
        <div className="flex space-x-2 divide-x-2 items-center px-4 py-2">
          <div className="text-lg">City Admins</div>
          {/* <div className="text-gray-600 text-sm pl-2">5 total 2 inactive</div> */}
          <div className="pl-2">
            <input
              autoComplete="false"
              value={filteredValue}
              type="text"
              className="appearance-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none sm:text-sm"
              placeholder="Filter city admins"
              onChange={(e) => setFilteredValue(e.target.value)}
            />
          </div>
        </div>
      </div>
      {/* <div className="space-y-4 overflow-x-auto"> */}
      <Scrollbars
        autoHide
        style={{ width: "100%", height: "calc(100% - 3rem)" }}
        className="space-y-4 h-full"
      >
        <table {...getTableBodyProps()} className="w-full">
          <thead className="bg-white sticky top-0 border-b">
            {
              // Loop over the header rows
              headerGroups.map((headerGroup) => (
                // Apply the header row props
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {
                    // Loop over the headers in each row
                    headerGroup.headers.map((column) => (
                      // Apply the header cell props
                      <th
                        {...column.getHeaderProps(
                          column.getSortByToggleProps()
                        )}
                        scope="col"
                        className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer hover:bg-gray-50"
                      >
                        {column.render("Header")}
                        <span>
                          {column.isSorted
                            ? column.isSortedDesc
                              ? " 🔽"
                              : " 🔼"
                            : ""}
                        </span>
                      </th>
                    ))
                  }
                </tr>
              ))
            }
          </thead>
          <tbody
            {...getTableBodyProps()}
            className="bg-white divide-y divide-gray-200"
          >
            {
              // Loop over the table rows
              rows.map((row) => {
                // Prepare the row for display
                prepareRow(row)
                return (
                  // Apply the row props
                  <tr {...row.getRowProps()}>
                    {
                      // Loop over the rows cells
                      row.cells.map((cell) => {
                        // Apply the cell props
                        return (
                          <td
                            {...cell.getCellProps()}
                            className="px-6 py-4 whitespace-nowrap"
                          >
                            {
                              // Render the cell contents
                              cell.render("Cell")
                            }
                          </td>
                        )
                      })
                    }
                  </tr>
                )
              })
            }
          </tbody>
        </table>
      </Scrollbars>
      {/* </div> */}
      <AddCityAdminModal
        visible={isOpen}
        closeModal={handleModalClose}
        selectedUser={selectedUser}
        selectedOrganizationId={selectedOrganizationRow}
      />
      <DeleteModal
        title="Are you sure you want to demote city admin?"
        visible={deleteIsOpen}
        closeModal={() => setDeleteIsOpen(false)}
        userId={selectedUser?.id}
        onConfirm={handleDeleteClick}
      />
    </div>
  )
}

export default CityAdmins
