import { format, fromUnixTime } from "date-fns"
import qs from "qs"
import { useCallback, useEffect, useState } from "react"
import shallow from "zustand/shallow"
import apiClient from "../../api"
import useStore from "../../store"
import { FleetComparisonResponse } from "../../types/fleetComparison"
import { getHourRangeParameter } from "../../util"
import { useNotyf } from "../useNotyf"

interface FleetComparisonData {
  loading: boolean
  error?: string
  data?: FleetComparisonResponse
  getData: () => void
}

const useFleetComparison = (): FleetComparisonData => {
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState<string>()
  const [data, setData] = useState<any>()
  const [pendingRequest, setPendingRequest] = useState(false)
  const { notyf } = useNotyf()

  const { selectedEquipments, startDate, endDate, timePeriod, selectedHours } =
    useStore(
      (state) => ({
        selectedEquipments: state.selectedLimitedEquipments,
        startDate: state.startDate,
        endDate: state.endDate,
        timePeriod: state.timePeriod,
        selectedHours: state.selectedHours
      }),
      shallow
    )

  useEffect(() => {
    if (pendingRequest && startDate && endDate && !data) {
      getData()
    }
  }, [pendingRequest, startDate, endDate, data])

  useEffect(() => {
    getData()
  }, [])

  const buildRequestParams = useCallback(() => {
    if (!startDate || !endDate) {
      return null
    }
    const params: Record<string, string> = {
      date_start: format(fromUnixTime(startDate), "yyyy-MM-dd"),
      date_end: format(fromUnixTime(endDate), "yyyy-MM-dd")
    }
    // if (selectedDistricts?.length) {
    //   params["district_ids"] = selectedDistricts.map((d) => d.value).join(",")
    // }

    if (selectedEquipments?.length) {
      params["vehicle_ids"] = selectedEquipments.map((d) => d.value).join(",")
      params["vehicle_names_debug"] = selectedEquipments
        .map((d) => d.label)
        .join(",")
    }
    if (selectedHours?.length) {
      params["hours_filter"] = getHourRangeParameter(selectedHours)
    }
    //  else {
    //   if (selectedBrands?.length) {
    //     params["brands_filter"] = selectedBrands.map((d) => d.value).join(",")
    //   }
    //   if (selectedTypes?.length) {
    //     params["types_filter"] = selectedTypes.map((d) => d.value).join(",")
    //   }
    //   if (selectedModels?.length) {
    //     params["models_filter"] = selectedModels.map((d) => d.value).join(",")
    //   }
    // }
    if (timePeriod) {
      params["half_of_day_filter"] = timePeriod
    }
    return params
  }, [
    // selectedDistricts,
    // selectedBrands,
    // selectedTypes,
    // selectedModels,
    selectedHours,
    selectedEquipments,
    startDate,
    endDate,
    timePeriod
  ])

  const getData = async () => {
    const requestParams = buildRequestParams()
    if (!requestParams) {
      notyf?.error("Please select start date and end date!")
      // setPendingRequest(true)
      setLoading(false)
      return
    }
    setLoading(true)

    try {
      const { data } = await apiClient.get(
        `/sweeper-api/charts/fleet_comparison?${qs.stringify(requestParams)}`
      )
      setData(data)
    } catch (e: any) {
      setError(e)
    }
    setLoading(false)
    setPendingRequest(false)
  }

  return { loading, error, data, getData }
}

export default useFleetComparison
