export interface HeatMapResponse {
  working_fuel_heatmap_data: string[]
  working_fuel_heatmap_data_v2: string[]
  working_water_heatmap_data: string[]
  working_hours_heatmap_data: string[]
  cache_hit: boolean
  cache_date: string
}

export enum EnumHeatMapMeasure {
  SweepingFuel = 1,
  SweepingWater,
  TimeWorking
}
