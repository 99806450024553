import React, {
  Children,
  cloneElement,
  isValidElement,
  useEffect,
  useRef,
  useState
} from "react"
import { useLocation } from "react-router-dom"
import useDeepCompareEffectForMaps from "../../hooks/maps/useDeepCompareEffectForMaps"

interface MapProps extends google.maps.MapOptions {
  loading?: boolean
  style: { [key: string]: string }
  onClick?: (e: google.maps.MapMouseEvent) => void
  onIdle?: (map: google.maps.Map) => void
}

const LoadingOverlay = () => {
  const { pathname } = useLocation()

  return (
    <div className={`absolute ${pathname.includes("daily-report") ? "top-4": "top-20"} bottom-4 left-0 right-4 bg-black bg-opacity-40`}>
      <div className="relative flex w-full h-full items-center justify-center">
        <div className="text-2xl font-bold text-white">Please wait...</div>
      </div>
    </div>
  )
}

const Map: React.FC<MapProps> = ({
  onClick,
  onIdle,
  children,
  style,
  loading,
  ...options
}) => {
  const ref = useRef<HTMLDivElement>(null)
  const [map, setMap] = useState<google.maps.Map>()

  useEffect(() => {
    if (ref.current && !map) {
      setMap(new window.google.maps.Map(ref.current, {}))
    }
  }, [ref, map])

  useDeepCompareEffectForMaps(() => {
    if (map) {
      map.setOptions(options)
    }
  }, [map, options])

  useEffect(() => {
   
    if (map) {
      ;["click", "idle"].forEach((eventName) =>
        google.maps.event.clearListeners(map, eventName)
      )

      if (onClick) {
        map.addListener("click", onClick)
      }

      if (onIdle) {
        map.addListener("idle", () => onIdle(map))
      }
    }
  }, [map, onClick, onIdle])

  return (
    <>
      <div ref={ref} style={style} />
      {loading && <LoadingOverlay />}
      {!loading &&
        Children.map(children, (child) => {
          if (isValidElement(child)) {
            // set the map prop on the child component
            return cloneElement(child, { map })
          }
        })}
    </>
  )
}

export default Map
