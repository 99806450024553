export interface HomeResponse {
  previous_week_date: string
  previous_week_number: string
  current_week_date: string
  current_week_number: string
  dashboard_data: DashboardData
  yesterday_box_data: YesterdayBoxData
  contribution_lists: ContributionLists
  cache_hit: boolean
  cache_date: string
}

export interface ContributionLists {
  active_vehicles: ActiveVehicle[]
  inactive_vehicles: any[]
  total_fuel_use: Total[]
  total_km_swept: Total[]
  total_km_worked: Total[]
  total_sweep_hours: Total[]
  total_co2_emissions: Total[]
  total_kwh_use: any[]
  total_water_use: Total[]
  avg_water_use: Total[]
  avg_engine_fuel_rate: Total[]
  avg_battery_usage_kw: Total[]
}

export interface ActiveVehicle {
  equipment_id: string
  equipment_name: string
  value?: string
}

export interface Total {
  equipment_id: string
  equipment_name: string
  value: string
  delta: number
  delta_color: DeltaColor
}

export enum DeltaColor {
  Green = "green",
  Red = "red"
}

export interface DashboardData {
  total_distance: string
  total_sweep_distance: string
  total_travel_time: string
  total_fuel_consumption: string
  total_electric_consumption_kwh: string
  total_co2_emission_kg: number
  total_water_consumption: string
  total_fuel_fill_ups: number
  fuel_usage_per_km_swept: string
  water_usage_per_km_swept: string
  average_battery_usage: number
  average_fuel_usage: string
  total_sweep_hours: string
  fuel_efficiency: string
  water_efficiency: string
  total_active_vehicles: number
  total_inactive_vehicles: number
  total_distance_delta: number
  total_sweep_distance_delta: number
  total_travel_time_delta: number
  total_fuel_consumption_delta: number
  total_electric_consumption_kwh_delta: number
  total_co2_emission_kg_delta: number
  total_water_consumption_delta: number
  total_fuel_fill_ups_delta: number
  fuel_usage_per_km_swept_delta: number
  water_usage_per_km_swept_delta: number
  average_battery_usage_delta: number
  average_fuel_usage_delta: number
  total_sweep_hours_delta: number
  fuel_efficiency_delta: number
  water_efficiency_delta: number
  total_active_vehicles_delta: number
  total_inactive_vehicles_delta: number
  total_sweep_distance_delta_color: DeltaColor
  total_fuel_consumption_delta_color: DeltaColor
  total_co2_emission_kg_delta_color: DeltaColor
  total_water_consumption_delta_color: DeltaColor
  water_usage_per_km_swept_delta_color: DeltaColor
  average_fuel_usage_delta_color: DeltaColor
  total_sweep_hours_delta_color: DeltaColor
  total_active_vehicles_delta_color: DeltaColor
  total_inactive_vehicles_delta_color: DeltaColor
}

export interface YesterdayBoxData {
  total_time: string
  total_sweep_hours: string
  total_sweep_distance: string
  sample_vehicle_id_for_daily_report_link: string
}
