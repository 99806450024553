import axios, { AxiosError } from "axios"
import useStore from "../store"
import qs from "qs"

const apiClient = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
  timeout: 140000
})

apiClient.interceptors.request.use(
  (request) => {
    const { loggedIn, accessToken, selectedOrganization } = useStore.getState()

    if (request.url && selectedOrganization) {
      // request.url = "a"
      const url = new URL(request.url, request.baseURL)
      const urlParams = qs.parse(new URLSearchParams(url.search).toString())
      urlParams["active_organization_id"] = selectedOrganization.toString()

      url.search = qs.stringify(urlParams)
      request.url = url.pathname + url.search
    }
    if (loggedIn && request.headers) {
      request.headers["Authorization"] = `Bearer ${accessToken}`
    }
    return request
  },
  (error) => {
    return Promise.reject(error)
  }
)

// TODO: implement refresh token
apiClient.interceptors.response.use(
  (res) => res,
  async (error: AxiosError) => {
    const { notyf } = useStore.getState()
    if (error?.response?.status === 400) {
      if (notyf) {
        const message = error.response.data.detail ?? error.response.data
        notyf.error({ message, duration: 5000 })
      }
    }
    if (error?.response?.status !== 401) {
      return Promise.reject(error)
    }
    if (error?.response?.status === 401 && error.config.url === "/token") {
      if (notyf) {
        notyf.error({ message: error.response.data.detail, duration: 5000 })
        return Promise.reject(error)
      }
    }

    if (error?.response?.status === 401) {
      try {
        useStore.getState().setAccessToken("abc")
        const newAccessToken = await refreshToken()
        if (error.config.headers) {
          error.config.headers["Authorization"] = `Bearer ${newAccessToken}`
        }
        return axios.request(error.config)
      } catch (e) {
        useStore.getState().setAccessToken(undefined)
        return Promise.reject(error)
      }
    }
  }
)

export const refreshToken = async () => {
  try {
    const { setAccessToken } = useStore.getState()

    const { data } = await axios.get(
      `${process.env.REACT_APP_API_BASE_URL}/token/refresh`,
      {
        withCredentials: true
      }
    )

    setAccessToken(data.access)

    return data.access
  } catch (e: any) {
    window.location.href = "/"
  }
}
export default apiClient
