import { fromUnixTime, getUnixTime } from "date-fns"
import { useTranslation } from "react-i18next"
import shallow from "zustand/shallow"
import useStore from "../../../store"
import FilterSection from "../FilterSection"
import DateFilter from "../Input/DateFilterInput"

const EndDateFilter = () => {
  const { t } = useTranslation()

  const { endDate, setEndDate } = useStore(
    (state) => ({
      endDate: state.endDate,
      setEndDate: state.setEndDate
    }),
    shallow
  )

  const handleDateChange = (date?: Date) => {
    if(!date) {
      return
    }
    setEndDate(getUnixTime(date))
  }

  return (
    <FilterSection title={t("filter_comp.end_date")} className="font-bold">
      <DateFilter
        onChange={handleDateChange}
        value={endDate ? fromUnixTime(endDate) : null}
      />
    </FilterSection>
  )
}

export default EndDateFilter
