import { useEffect, useState } from "react"
import shallow from "zustand/shallow"
import apiClient from "../../api"
import useStore from "../../store"
import { VehicleResponse } from "../../types/vehicle"
import { equipmentColors } from "../../util/equipmentColors"
import randomColors from "../../util/randomColor"

interface VehiclesData {
  loading: boolean
  error?: string
  data: VehicleResponse[]
}

const useVehicles = (): VehiclesData => {
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState<string>()

  const {
    data,
    setVehicles,
    setFilteredEquipments,
    setSelectedEquipments,
    setSelectedLimitedEquipments,
    selectedOrganization
  } = useStore(
    (state) => ({
      data: state.vehicles,
      setVehicles: state.setVehicles,
      setFilteredEquipments: state.setFilteredEquipments,
      setSelectedEquipments: state.setSelectedEquipments,
      setSelectedLimitedEquipments: state.setSelectedLimitedEquipments,
      selectedOrganization: state.selectedOrganization
    }),
    shallow
  )

  useEffect(() => {
    if (selectedOrganization) {
      getVehicles()
    }
  }, [selectedOrganization])

  const getVehicles = async () => {
    // if (data && data.length > 0) {
    //   setLoading(false)
    //   return
    // }
    setLoading(true)
    try {
      const { data } = await apiClient.get<VehicleResponse[]>(
        "/sweeper-api/vehicles"
      )
      data.forEach((equipment, index) => {
        equipmentColors[equipment.equipment_id] = randomColors[index % 10]
        equipmentColors[equipment.name] = randomColors[index % 10]
      })
      setVehicles(data)
      setFilteredEquipments(data)
      setSelectedEquipments(
        data.map((vehicle) => ({
          label: vehicle.name,
          value: vehicle.equipment_id
        }))
      )
    } catch (e: any) {
      setError(e)
    }
    setLoading(false)
  }

  return { loading, error, data }
}

export default useVehicles
