interface Props {
  active?: boolean
}
const FleetComparisonIcon = ({ active }: Props) => {
  return (
    <svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        id="icon/editor/bar_chart_24px"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.6 5H13.4V19H10.6V5ZM5 9.2H8V19H5V9.2ZM19 13H16.2V19H19V13Z"
        fill={active ? "#0055A0" : "#fff"}
      />
    </svg>
  )
}

export default FleetComparisonIcon
