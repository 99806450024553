interface Props {
  title: string
  color: string
}
const ColorfulLabel = ({ title, color }: Props) => {
  return (
    <div className="flex mb-2">
      <div className="p-3 rounded-md mr-2" style={{ backgroundColor: color }}></div>
      <div>{title}</div>
    </div>
  )
}

export default ColorfulLabel
