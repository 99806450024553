import { deepEqual } from "fast-equals"
import React, { useEffect, useMemo, useState } from "react"

interface Props {
  map?: google.maps.Map
  loading: boolean
  data?: string[]
}

interface CalculatedHeatmapdata {
  locationData: google.maps.visualization.WeightedLocation[]
  calculatedCenter: google.maps.LatLng
}
const parisCenterCoords: google.maps.LatLngLiteral = {
  lat: 48.8566,
  lng: 2.3522
}

const gradient = [
  "rgba(0, 255, 255, 0)",
  "rgba(0, 255, 255, 1)",
  "rgba(0, 191, 255, 1)",
  "rgba(0, 127, 255, 1)",
  "rgba(0, 63, 255, 1)",
  "rgba(0, 0, 255, 1)",
  "rgba(0, 0, 223, 1)",
  "rgba(0, 0, 191, 1)",
  "rgba(0, 0, 159, 1)",
  "rgba(0, 0, 127, 1)",
  "rgba(63, 0, 91, 1)",
  "rgba(127, 0, 63, 1)",
  "rgba(191, 0, 31, 1)",
  "rgba(255, 0, 0, 1)"
]

const HeatMapLayer = React.memo(
  ({ map, loading, data }: Props) => {
    const [mounted, setMounted] = useState(false)

    // prepare the data for gmap
    const heatmapData = useMemo<CalculatedHeatmapdata>(() => {
      const result: CalculatedHeatmapdata = {
        locationData: [],
        calculatedCenter: new google.maps.LatLng(parisCenterCoords)
      }

      const bound = new google.maps.LatLngBounds()

      if (loading || !data) {
        return result
      }

      for (const item of data) {
        const parts = item.split(",").map((item) => parseFloat(item))
        const latLngObject = new google.maps.LatLng(parts[0], parts[1])
        result.locationData.push({
          location: latLngObject,
          weight: parts[2]
        })
        bound.extend(latLngObject)
      }

      if (data.length > 0) {
        result.calculatedCenter = bound.getCenter()
      }
      return result
    }, [data, loading])

    useEffect(() => {
      let googleHeatMap: google.maps.visualization.HeatmapLayer

      if (map) {
        setMounted(true)
      }
      if (heatmapData && map) {
        googleHeatMap = new google.maps.visualization.HeatmapLayer({
          data: heatmapData.locationData,
          gradient,
          radius: 20,
          opacity: 0.7
        })

        googleHeatMap.setMap(map)
        map.setCenter(heatmapData.calculatedCenter)
        if (!mounted) {
          map.setZoom(13)
        }
      }

      return () => {
        if (googleHeatMap) {
          googleHeatMap.setMap(null)
        }
      }
    }, [map, heatmapData, mounted])

    return null
  },
  (prev, next) =>
    deepEqual(prev.data, next.data) && prev.loading === next.loading
)

export default HeatMapLayer
