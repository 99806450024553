import { useState } from "react"
import apiClient from "../../api"

interface UpdateTimezoneData {
  loading: boolean
  error?: string
  success?: boolean
  updateTimezone: (formData: FormData) => void
}

const useUpdateTimezone = (): UpdateTimezoneData => {
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState<string>()
  const [success, setSuccess] = useState<boolean>()

  const updateTimezone = async (formData: FormData) => {
    setLoading(true)
    setError(undefined)
    setSuccess(false)
    try {
      await apiClient.post(
        "user-management/updateOrganizationTimezone",
        formData,
        {
          withCredentials: true
        }
      )
      setSuccess(true)
    } catch (e: any) {
      setError(e)
    }
    setLoading(false)
  }

  return { loading, error, success, updateTimezone }
}

export default useUpdateTimezone
