import { useState } from "react"
import apiClient from "../../api"

interface DeleteDistrictData {
  loading: boolean
  error?: string
  success?: boolean
  deleteDistrict: (districtId: number) => void
}

const useDeleteDistrict = (): DeleteDistrictData => {
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState<string>()
  const [success, setSuccess] = useState<boolean>()

  const deleteDistrict = async (districtId: number) => {
    setLoading(true)
    setError(undefined)
    setSuccess(false)
    try {
      var bodyFormData = new FormData()
      bodyFormData.append("target_district_id", districtId.toString())
      await apiClient.post(
        "/sweeper-api/districts/deleteDistrict",
        bodyFormData,
        {
          withCredentials: true
        }
      )
      setSuccess(true)
    } catch (e: any) {
      setError(e)
    }
    setLoading(false)
  }

  return { loading, error, success, deleteDistrict }
}

export default useDeleteDistrict
