import { useEffect, useState } from "react"
import apiClient from "../../../api"
import useStore from "../../../store"
import { DealerResponse } from "../../../types/userManagement/dealers"

interface DealersData {
  loading: boolean
  error?: string
  data?: DealerResponse
  getDealers: () => void
}

const useDealers = (): DealersData => {
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState<string>()
  const [data, setData] = useState<DealerResponse>()

  const selectedOrganization = useStore((q) => q.selectedOrganization)

  useEffect(() => {
    getDealers()
  }, [selectedOrganization])

  const getDealers = async () => {
    setLoading(true)
    try {
      const { data } = await apiClient.get<DealerResponse>(
        "/user-management/su/getDealers"
      )
      setData(data)
    } catch (e: any) {
      setError(e)
    }
    setLoading(false)
  }

  return { loading, error, data, getDealers }
}

export default useDealers
