import { useEffect, useState } from "react"
import shallow from "zustand/shallow"
import apiClient from "../../api"
import useStore from "../../store"
import { InvitationMetadata } from "../../types/invitationMetadata"

interface GetInvitationMetadata {
  loading: boolean
  error?: string
  data?: InvitationMetadata
  getMetadata: (token: string) => void
}

const useGetInvitationMetadata = (): GetInvitationMetadata => {
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState<string>()
  const [data, setData] = useState<InvitationMetadata>()

  const getMetadata = async (token: string) => {
    setLoading(true)
    try {
      const { data } = await apiClient.get<InvitationMetadata>(
        `/user-management/getInvitationMetadata?invitation_token=${token}`
      )
      setData(data)
    } catch (e: any) {
      window.location.href = "/"
      setError(e)
    }
    setLoading(false)
  }

  return { loading, error, data, getMetadata }
}

export default useGetInvitationMetadata
