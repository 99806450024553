import { useState } from "react"
import apiClient from "../../api"

interface UpdatePasswordData {
  loading: boolean
  error?: string
  success?: boolean
  updatePassword: (token: string, password: string) => void
}

const useUpdatePassword = (): UpdatePasswordData => {
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState<string>()
  const [success, setSuccess] = useState<boolean>()

  const updatePassword = async (token: string, password: string) => {
    setLoading(true)
    setError(undefined)
    setSuccess(false)
    try {
      await apiClient.get(
        `user-management/resetPassword/${token}?new_password=${password}`
      )
      setSuccess(true)
    } catch (e: any) {
      setError(e)
    }
    setLoading(false)
  }

  return { loading, error, success, updatePassword }
}

export default useUpdatePassword
