import { useEffect, useMemo, useState } from "react"
import { SingleValue } from "react-select"
import SelectFilterInput from "../../../../components/Filter/Input/SelectFilterInput"
import useFinalizeShapefile from "../../../../hooks/mutations/useFinalizeShapefile"
import { useNotyf } from "../../../../hooks/useNotyf"
import { ShapefileResponse } from "../../../../types/userManagement/shapeFile"

interface Props {
  data: ShapefileResponse
  onSuccess: () => void
}
const ShapeFileDropdown = ({ data, onSuccess }: Props) => {
  const [selectedName, setSelectedName] = useState<string>()

  const { finalizeShapefile, success } = useFinalizeShapefile()
  const { notyf } = useNotyf()

  const options = useMemo<any>(() => {
    return data.file_preview_json.columns.map((c) => ({
      label: c,
      value: c
    }))
  }, [data.file_preview_json.columns])

  const defaultValue = useMemo<any>(() => {
    if(data.neighborhood_property_name) {
      return {label: data.neighborhood_property_name, value: data.neighborhood_property_name}
    }
    return null
  }, [data.neighborhood_property_name])
  
  const handleFilterChange = (value: SingleValue<any>) => {
    if (value) {
      setSelectedName(value.value)
    }
  }

  useEffect(() => {
    if (success) {
      notyf?.success("Name saved successfully")
      onSuccess()
    }
  }, [success])

  const handleUpdateClick = () => {
    if (selectedName) {
      finalizeShapefile(selectedName)
    }
  }
  return (
    <div className="flex w-full bg-white p-6 items-center justify-between">
      <div className="flex w-2/5 justify-between items-center">
        <div>Change name:</div>
        <div className="w-3/5">
          <SelectFilterInput
            defaultValue={defaultValue}
            options={options}
            getOptionLabel={(item) => item.label}
            getOptionValue={(item) => item.value}
            maxMenuHeight={300}
            onChange={handleFilterChange}
          />
        </div>
      </div>
      {/* <div className="w-3/5"> */}
      <div
        className="bg-primary rounded-md font-semibold text-white px-4 py-2 text-sm cursor-pointer flex items-center"
        onClick={handleUpdateClick}
      >
        Update name
        {/* </div> */}
      </div>
    </div>
  )
}

export default ShapeFileDropdown
