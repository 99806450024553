import { format, fromUnixTime } from "date-fns"
import { useTranslation } from "react-i18next"
import shallow from "zustand/shallow"
import ElectricVehicleCheckbox from "../../../components/Filter/Shared/ElectricVehicleCheckbox"
import useFleetComparison from "../../../hooks/queries/useFleetComparison"
import useStore from "../../../store"
import DashboardContainer from "../../DashboardContainer"
import FleetComparisonChart from "./FleetComparisonChart"
import FleetComparisonFilters from "./FleetComparisonFilters"

const FleetComparison = () => {
  const { t } = useTranslation()
  const { loading, data, getData } = useFleetComparison()

  const { isElectricVehicle, filterMenuVisible, startDate, endDate } = useStore(
    (state) => ({
      filterMenuVisible: state.filterMenuVisible,
      isElectricVehicle: state.isElectricVehicle,
      startDate: state.startDate,
      endDate: state.endDate
    }),
    shallow
  )

  return (
    <DashboardContainer
      title="Fleet Comparison"
      optionalLabel={
        <span className="text-gray-600">
          {data &&
            `Start date: ${format(
              fromUnixTime(startDate as number),
              "dd-MM-yyyy"
            )}, End date: ${format(
              fromUnixTime(endDate as number),
              "dd-MM-yyyy"
            )}`}
        </span>
      }
    >
      <div
        className="flex space-x-2 justify-between"
        style={{ height: "calc(100% - 6rem)" }}
      >
        <div
          className={`w-full flex flex-col ${
            filterMenuVisible ? "w-full lg:w-10/12" : "w-full"
          } space-y-4`}
        >
          <div className="flex flex-col md:flex-row md:space-x-4">
            <FleetComparisonChart
              firstKey="travel"
              secondKey="working"
              loading={loading}
              data={data?.distance_chart_data}
              title={t("fleet_comparison.distance")}
              description={"in Km"}
            />
            <FleetComparisonChart
              firstKey="travel"
              secondKey="working"
              loading={loading}
              data={
                isElectricVehicle
                  ? data?.battery_usage_chart_data
                  : data?.fuel_usage_chart_data
              }
              title={
                isElectricVehicle
                  ? "Electricity use in kWh"
                  : t("fleet_comparison.fuel_use")
              }
              description={isElectricVehicle ? "in kWh" : "in L"}
              additionalTitle={<ElectricVehicleCheckbox key="vh2" />}
            />
            <FleetComparisonChart
              firstKey={isElectricVehicle ? "average_usage" : "usage_per_km"}
              loading={loading}
              data={
                isElectricVehicle
                  ? data?.battery_usage_average_chart_data
                  : data?.fuel_usage_per_km_chart_data
              }
              title={
                isElectricVehicle
                  ? "Average kW per machine"
                  : t("fleet_comparison.fuel_use_per_km_clean")
              }
              description={isElectricVehicle ? "in kW" : "in L/Km cleaned"}
              additionalTitle={<ElectricVehicleCheckbox key="vh4" />}
            />
          </div>
          <div className="flex flex-col md:flex-row md:space-x-4">
            <FleetComparisonChart
              firstKey="travel"
              secondKey="working"
              loading={loading}
              data={data?.time_chart_data}
              title={t("fleet_comparison.time")}
              description={"in hours"}
            />
            <FleetComparisonChart
              firstKey="water_usage"
              loading={loading}
              data={data?.water_usage_chart_data}
              title={t("fleet_comparison.water_use")}
              description={"in L"}
            />
            <FleetComparisonChart
              firstKey="usage_per_km"
              loading={loading}
              data={data?.water_usage_per_km_chart_data}
              title={t("fleet_comparison.water_use_per_km_clean")}
              description={"in L/Km cleaned"}
            />
          </div>
        </div>
        {filterMenuVisible && (
          <div
            className={`transform absolute shadow-xl right-0 bottom-4 top-32 w-72 mx-auto z-20 transition duration-200 ease-in-out lg:shadow-none lg:top-0 lg:bottom-0 lg:right-auto lg:relative lg:w-80 ${
              filterMenuVisible ? "translate-x-0" : "translate-x-full"
            }`}
          >
            <FleetComparisonFilters onApplyFilter={getData} />
          </div>
        )}
      </div>
    </DashboardContainer>
  )
}

export default FleetComparison
