import Card from "../../../components/Cards"
import { GMapWrapper } from "../../../components/Maps"
import HeatMapLayer from "../../../components/Maps/HeatMapLayer"
import HeatMapFilters from "./HeatMapFilters"
import { useTranslation } from "react-i18next"
import useHeatMap from "../../../hooks/queries/useHeatMap"
import { useMemo } from "react"
import { EnumHeatMapMeasure } from "../../../types/heatMap"
import DashboardContainer from "../../DashboardContainer"
import useStore from "../../../store"
import { format, fromUnixTime } from "date-fns"
import shallow from "zustand/shallow"

// overlay for loading
const HeatMap = () => {
  const { t } = useTranslation()

  const { loading, data, heatMapMeasure, getData } = useHeatMap()
  const { filterMenuVisible, startDate, endDate } = useStore(
    (state) => ({
      filterMenuVisible: state.filterMenuVisible,
      startDate: state.startDate,
      endDate: state.endDate
    }),
    shallow
  )

  const measuredFilterData = useMemo(() => {
    if (loading || !data || !heatMapMeasure) {
      return []
    }

    switch (+heatMapMeasure) {
      case EnumHeatMapMeasure.TimeWorking:
        return data.working_hours_heatmap_data

      case EnumHeatMapMeasure.SweepingFuel:
        return data.working_fuel_heatmap_data_v2

      case EnumHeatMapMeasure.SweepingWater:
        return data.working_water_heatmap_data
      default:
        break
    }
  }, [heatMapMeasure, loading, data])

  const getTitle = () => {
    let title = ""
    if (heatMapMeasure) {
      switch (+heatMapMeasure) {
        case EnumHeatMapMeasure.TimeWorking:
          title = "Time Spent Working"
          break
        case EnumHeatMapMeasure.SweepingFuel:
          title = "Sweeping Energy Consumption"
          break
        case EnumHeatMapMeasure.SweepingWater:
          title = "Sweeping Water Consumption"
          break
      }
    }

    return title
  }
  return (
    <DashboardContainer
      title="Heatmap"
      optionalLabel={
        <span className="text-gray-600">
          {data &&
            `Start date: ${format(
              fromUnixTime(startDate as number),
              "dd-MM-yyyy"
            )}, End date: ${format(
              fromUnixTime(endDate as number),
              "dd-MM-yyyy"
            )}`}
        </span>
      }
    >
      <div
        className="flex space-x-2 justify-between"
        style={{ height: "calc(100% - 6rem)" }}
      >
        <div className={filterMenuVisible ? "w-full lg:w-10/12" : "w-full"}>
          <Card
            title={t("heatmap.sweeper_management")}
            description={t("heatmap.heatmap")}
            additionalTitle={getTitle()}
            inlineHeader
            className="h-full"
          >
            <GMapWrapper loading={loading}>
              <HeatMapLayer loading={loading} data={measuredFilterData} />
            </GMapWrapper>
          </Card>
        </div>
        {filterMenuVisible && (
          <div
            className={`transform absolute shadow-xl right-0 bottom-4 top-32 w-56 mx-auto z-20 transition duration-200 ease-in-out lg:shadow-none lg:top-0 lg:bottom-0 lg:right-auto lg:relative lg:w-80 ${
              filterMenuVisible ? "translate-x-0" : "translate-x-full"
            }`}
          >
            <HeatMapFilters onApplyFilter={getData} />
          </div>
        )}
      </div>
    </DashboardContainer>
  )
}

export default HeatMap
