import { useEffect, useMemo, useState } from "react"
import { Column, useSortBy, useTable } from "react-table"
import DeleteIcon from "../../../../assets/icons/trash.svg"
import useDetachVehicle from "../../../../hooks/mutations/useDetachVehicle"
import useManageVehicles from "../../../../hooks/queries/userManagement/useManageVehicles"
import { useNotyf } from "../../../../hooks/useNotyf"
import { VehicleResponse } from "../../../../types/vehicle"
import AddDistrictToVehicle from "./AddDistrictToVehicle"
import DeleteVehicleModal from "./DeleteVehicleModal"
import VehicleModal from "./VehicleModal"

const Vehicles = () => {
  const { data, loading, error, getVehicles: refetch } = useManageVehicles()
  const [isOpen, setIsOpen] = useState(false)
  const [districtModalOpen, setDistrictModalOpen] = useState(false)
  const [deleteIsOpen, setDeleteIsOpen] = useState(false)
  const [filteredValue, setFilteredValue] = useState("")
  const [selectedVehicle, setSelectedVehicle] =
    useState<VehicleResponse | null>(null)

  const filteredData = useMemo<VehicleResponse[]>(() => {
    if (data?.length && filteredValue.length > 0) {
      return data.filter((vehicle) => {
        return Object.keys(vehicle).some((key) => {
          if (typeof key == "string") {
            const vehicleProperty = vehicle[key as keyof VehicleResponse]
            if (typeof vehicleProperty == "string") {
              return vehicleProperty.toLowerCase().indexOf(filteredValue) != -1
            }
          }
        })
      })
    }
    if (data) {
      return data
    }
    return []
  }, [filteredValue, data])

  const columns: Column<VehicleResponse>[] = useMemo(() => {
    return [
      {
        Header: "Name",
        accessor: "name"
      },
      {
        Header: "Model",
        accessor: "model"
      },
      {
        Header: "type",
        accessor: "type"
      },
      {
        Header: "Workshop",
        accessor: "district",
        Cell: ({ cell: { row } }: { cell: any }) => (
          <div>{row.original.district.name}</div>
        )
      },
      {
        Header: "Workshop",
        Cell: ({ cell: { row } }: { cell: any }) => (
          <div
            className={
              "bg-primary justify-center text-white px-4 py-1 text-sm cursor-pointer items-center flex"
            }
            onClick={() => {
              setSelectedVehicle(row.original)
              setDistrictModalOpen(true)
            }}
          >
            Edit Workshop
          </div>
        )
      },
      // {
      //   id: "1",
      //   Header: " ",
      //   Cell: ({ cell: { row } }: { cell: any }) => (
      //     <div
      //       className="flex text-sm cursor-pointer"
      //       onClick={() => handleEditVehicle(row.original)}
      //     >
      //       <img src={EditIcon} className="w-4 mr-2" />
      //       <div className="text-gray-500">Edit</div>
      //     </div>
      //   )
      // },
      {
        id: "2",
        Header: " ",
        accessor: "id",
        Cell: ({
          cell: {
            row: { original }
          }
        }) => (
          <div
            className="flex text-sm cursor-pointer"
            onClick={() => {
              setSelectedVehicle(original)
              setDeleteIsOpen(true)
            }}
          >
            <img src={DeleteIcon} className="w-4 mr-2" />
            <div className="text-gray-500">Remove workshop</div>
          </div>
        )
      }
    ]
  }, [])

  const { notyf } = useNotyf()
  const {detachVehicle, success: successDelete} = useDetachVehicle()
  const tableInstance = useTable({ columns, data: filteredData }, useSortBy)
  const { getTableBodyProps, headerGroups, rows, prepareRow } = tableInstance

  const closeModal = () => {
    setSelectedVehicle(null)
    refetch()
    setIsOpen(false)
  }

  const openModal = () => {
    setIsOpen(true)
  }

  useEffect(() => {
    if (successDelete) {
      notyf?.open({ message: "District removed from vehicle", background: "green" })
      refetch()
    }
  }, [successDelete])

  const handleDeleteClick = (vehicleId?: string) => {
    if (vehicleId) {
      setDeleteIsOpen(false)
      detachVehicle(vehicleId.toString())
    }
  }

  const handleEditVehicle = (vehicle: VehicleResponse) => {
    setSelectedVehicle(vehicle)
    openModal()
  }

  const handleDistrictModalClose = () => {
    setDistrictModalOpen(false)
    refetch()
  }

  return (
    <div className="w-full">
      <div className="bg-white flex justify-between">
        <div className="flex space-x-2 divide-x-2 items-center px-4 py-2">
          <div className="text-lg">Vehicles</div>
          <div className="pl-2">
            <input
              autoComplete="false"
              value={filteredValue}
              type="text"
              className="appearance-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none sm:text-sm"
              placeholder="Filter vehicles"
              onChange={(e) => setFilteredValue(e.target.value)}
            />
          </div>
        </div>
        {/* <div
          className="bg-primary text-white px-4 py-1 text-sm cursor-pointer flex items-center"
          onClick={openModal}
        >
          Add Vehicle
        </div> */}
      </div>
      <div className="space-y-4 overflow-x-auto">
        <table {...getTableBodyProps()} className="w-full">
          <thead className="bg-white sticky top-0 border-t border-b">
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  // Add the sorting props to control sorting. For this example
                  // we can add them into the header props
                  <th
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer hover:bg-gray-50"
                  >
                    {column.render("Header")}
                    {/* Add a sort direction indicator */}
                    <span>
                      {column.isSorted
                        ? column.isSortedDesc
                          ? " 🔽"
                          : " 🔼"
                        : ""}
                    </span>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody
            {...getTableBodyProps()}
            className="bg-white divide-y divide-gray-200"
          >
            {
              // Loop over the table rows
              rows.map((row) => {
                // Prepare the row for display
                prepareRow(row)
                return (
                  // Apply the row props
                  <tr {...row.getRowProps()}>
                    {
                      // Loop over the rows cells
                      row.cells.map((cell) => {
                        // Apply the cell props
                        return (
                          <td
                            {...cell.getCellProps()}
                            className="px-6 py-4 whitespace-nowrap"
                          >
                            {
                              // Render the cell contents
                              cell.render("Cell")
                            }
                          </td>
                        )
                      })
                    }
                  </tr>
                )
              })
            }
          </tbody>
        </table>
      </div>

      <VehicleModal
        visible={isOpen}
        closeModal={closeModal}
        selectedVehicle={selectedVehicle}
      />
      <DeleteVehicleModal
        visible={deleteIsOpen}
        closeModal={() => setDeleteIsOpen(false)}
        vehicleId={selectedVehicle?.equipment_id}
        onConfirm={handleDeleteClick}
      />

      <AddDistrictToVehicle
        visible={districtModalOpen}
        closeModal={handleDistrictModalClose}
        selectedVehicleId={selectedVehicle?.equipment_id}
      />
    </div>
  )
}

export default Vehicles
