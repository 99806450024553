import { useState, useEffect } from "react"
import apiClient from "../../../api"
import useStore from "../../../store"
import { VehicleResponse } from "../../../types/vehicle"

interface VehiclesData {
  loading: boolean
  error?: string
  data?: VehicleResponse[]
  getVehicles: () => void
}

const useManageVehicles = (): VehiclesData => {
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState<string>()
  const [data, setData] = useState<VehicleResponse[]>()

  const selectedOrganization = useStore((q) => q.selectedOrganization)

  useEffect(() => {
    if (selectedOrganization) {
      getVehicles()
    }
  }, [selectedOrganization])

  const getVehicles = async () => {
    setLoading(true)
    try {
      const { data } = await apiClient.get<VehicleResponse[]>(
        "/sweeper-api/vehicles"
      )
      setData(data)
    } catch (e: any) {
      setError(e)
    }
    setLoading(false)
  }

  return { loading, error, data, getVehicles }
}

export default useManageVehicles
