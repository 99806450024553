const randomColors = [
  "#40C2DF",
  "#008C5A",
  "#FFD600",
  "#E80E00",
  "#8701C7",
  "#88C701",
  "#F68500",
  "#F878E8",
  "#294BD4",
  "#4E4A42"

  // "2a9d8f",
  // "e9c46a",
  // "e63946",
  // "118ab2",
  // "696eff",
  // "103783",
  // "392d69",
  // "0965c0",
  // "c81d77",
  // "3a7ff2",
  // "f4985a",
  // "fd4b2f",
  // "43c197"
]

export default randomColors
