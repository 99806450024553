import { useEffect, useState } from "react"
import shallow from "zustand/shallow"
import apiClient from "../../api"
import useStore from "../../store"
import { DistrictResponse } from "../../types/districts"
import { GetMeResponse } from "../../types/getMe"

interface GetMeData {
  loading: boolean
  error?: string
  me?: GetMeResponse
  userRole?: string
}

const useGetMe = (): GetMeData => {
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState<string>()

  const { me, setMe } = useStore((state) => ({
    me: state.me,
    setMe: state.setMe
  }))
  const userRole = me?.user_role

  useEffect(() => {
    getMe()
  }, [])

  const getMe = async () => {
    if (me) {
      return
    }
    setLoading(true)
    try {
      const { data } = await apiClient.get<GetMeResponse>("/user-management/me")
      setMe(data)
    } catch (e: any) {
      setError(e)
    }
    setLoading(false)
  }

  return { loading, error, me, userRole }
}

export default useGetMe
