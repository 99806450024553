import { useTranslation } from "react-i18next"
import shallow from "zustand/shallow"
import FilterSection from "../../../components/Filter/FilterSection"
import Checkbox from "../../../components/Input/Checkbox"
import useStore from "../../../store"
import { HeatMapDistrictResponse } from "../../../types/heatMapDistrict"

const MeasureFilter = () => {
  const { t } = useTranslation()

  const options: { key: keyof HeatMapDistrictResponse; label: string }[] = [
    {
      key: "by_sweep_hours",
      label: t("heatmap_district.time_working")
    },
    {
      key: "by_working_days",
      label: t("heatmap_district.vehicle_count")
    },
    {
      key: "by_sweep_hours_per_vehicle",
      label: t("heatmap_district.avg_time_working")
    }
  ]

  const { heatMapDistrictMeasure, setHeatMapDistrictMeasure } = useStore(
    (state) => ({
      heatMapDistrictMeasure: state.heatMapDistrictMeasure,
      setHeatMapDistrictMeasure: state.setHeatMapDistrictMeasure
    }),
    shallow
  )

  const handleCheckboxChange = (key: keyof HeatMapDistrictResponse) => {
    setHeatMapDistrictMeasure(key)
  }

  return (
    <FilterSection
      title={t("filter_comp.heatmap_measure")}
      className="font-bold"
    >
      {options.map((option) => (
        <Checkbox
          key={option.key}
          checked={option.key === heatMapDistrictMeasure}
          label={option.label}
          onChange={() => handleCheckboxChange(option.key)}
        />
      ))}
    </FilterSection>
  )
}

export default MeasureFilter
