import { useState } from "react"
import apiClient from "../../api"

interface DeleteUserData {
  loading: boolean
  error?: string
  success?: boolean
  deleteUser: (userId: number) => void
}

const useDeleteUser = (): DeleteUserData => {
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState<string>()
  const [success, setSuccess] = useState<boolean>()

  const deleteUser = async (userId: number) => {
    setLoading(true)
    setError(undefined)
    setSuccess(false)
    try {
      var bodyFormData = new FormData()
      bodyFormData.append("target_user_id", userId.toString())
      await apiClient.post("/user-management/su/deleteUser", bodyFormData, {
        withCredentials: true
      })
      setSuccess(true)
    } catch (e: any) {
      setError(e)
    }
    setLoading(false)
  }

  return { loading, error, success, deleteUser }
}

export default useDeleteUser
