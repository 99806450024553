import { useEffect, useMemo, useState } from "react"
import startIcon from "../../assets/icons/map/start.svg"
import endIcon from "../../assets/icons/map/end.svg"
import { isEqual } from "date-fns"
interface Props {
  map?: google.maps.Map
  data: Array<Array<number | null | string>>
  renderIcon?: "start" | "end"
  dailyReportTs?: Date
}

interface PolylineData {
  path: any
  color: string
  timestampMarker?: google.maps.MarkerOptions
  calculatedCenter?: google.maps.LatLng
}

// work : #FA8925
// travel: #64CA3A
// idle : #00A7C9
// offline color?
const polyineColorHash: Record<string, string> = {
  off: "red",
  travel: "#64CA3A",
  working: "#FA8925"
}

const parisCenterCoords: google.maps.LatLngLiteral = {
  lat: 48.8566,
  lng: 2.3522
}

const Polyline = ({ map, data, dailyReportTs, renderIcon }: Props) => {
  const [mounted, setMounted] = useState(false)

  const polylineData = useMemo<PolylineData>(() => {
    const result: google.maps.LatLngLiteral[] = []
    const bound = new google.maps.LatLngBounds()
    const color = polyineColorHash[data[0][4] as string]
    let timestampMarker: google.maps.MarkerOptions | undefined = undefined
    if (!data) {
      return { path: result, color: "" }
    }

    for (const item of data) {
      const latLngObj: google.maps.LatLngLiteral = {
        lat: item[2] as number,
        lng: item[3] as number
      }
      bound.extend(latLngObj)
      result.push(latLngObj)

      const itemDate = new Date(item[0] as string)
      if (dailyReportTs && isEqual(dailyReportTs, itemDate)) {
        timestampMarker = {
          position: latLngObj,
          icon: {
            path: google.maps.SymbolPath.CIRCLE,
            fillColor: "#f00",
            fillOpacity: 1,
            strokeOpacity: 1,
            strokeColor: "blue",
            scale: 5
          }
        }
      }
    }

    return {
      path: result,
      color: color ?? "red",
      calculatedCenter: bound.getCenter(),
      timestampMarker
    }
  }, [data, dailyReportTs])

  useEffect(() => {
    let line: google.maps.Polyline
    let marker: google.maps.Marker
    let tsMarker: google.maps.Marker

    if (polylineData && map) {
      map.setCenter(
        polylineData.calculatedCenter ??
          new google.maps.LatLng(parisCenterCoords)
      )
      if (!mounted) {
        map.setZoom(13.5)
      }
      setMounted(true)
      let startEndIcon: google.maps.MarkerOptions | null = null
      if (renderIcon) {
        startEndIcon = {
          icon: {
            url: renderIcon === "start" ? startIcon : endIcon,
            anchor: new google.maps.Point(25, 50),
            scaledSize: new google.maps.Size(50, 50)
          },
          position:
            renderIcon === "start"
              ? polylineData.path[0]
              : polylineData.path[polylineData.path.length - 1]
        }
      }

      const lineOptions: google.maps.PolylineOptions = {
        path: polylineData.path,
        strokeColor: polylineData.color,
        strokeOpacity: 0,
        icons: [
          {
            icon: {
              path: google.maps.SymbolPath.CIRCLE,
              // fillColor: polylineData.color,
              fillOpacity: 1,
              strokeOpacity: 1,
              scale: 2,
              strokeColor: polylineData.color
            },
            offset: "0",
            repeat: "10px"
          },
          {
            icon: {
              path: google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
              fillColor: "#fff",
              fillOpacity: 0.2,
              strokeOpacity: 1,
              strokeColor: "#00A7C9",
              scale: 3
            },
            offset: "85%"
          }
        ]
      }

      if (startEndIcon) {
        marker = new google.maps.Marker(startEndIcon)
        marker.setMap(map)
      }
      if (polylineData.timestampMarker) {
        tsMarker = new google.maps.Marker(polylineData.timestampMarker)
        tsMarker.setMap(map)
      }
      line = new google.maps.Polyline(lineOptions)

      line.setMap(map)
    }

    return () => {
      if (map && line) {
        if (marker) {
          marker.setMap(null)
        }
        if (tsMarker) {
          tsMarker.setMap(null)
        }
        line.setMap(null)
      }
    }
  }, [map, polylineData, mounted])

  return null
}

export default Polyline
