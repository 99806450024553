import { useMemo } from "react"
import { useTranslation } from "react-i18next"
import { MultiSelect } from "react-multi-select-component"
import shallow from "zustand/shallow"
import useStore from "../../../store"
import FilterSection from "../FilterSection"

const EquipmentFilter = () => {
  const { t } = useTranslation()

  const { filteredEquipments, selectedEquipments, setSelectedEquipments } = useStore(
    (state) => ({
      filteredEquipments: state.filteredEquipments,
      selectedEquipments: state.selectedEquipments,
      setSelectedEquipments: state.setSelectedEquipments
    }),
    shallow
  )

  const options = useMemo(() => {
    if (!filteredEquipments) {
      return []
    }

    return filteredEquipments.map((equipment) => ({
      label: equipment.name,
      value: equipment.equipment_id
    }))
  }, [filteredEquipments])

  return (
    <FilterSection title={t("filter_comp.equipment")} className="font-bold">
      <MultiSelect
        ClearSelectedIcon={true}
        options={options}
        value={selectedEquipments ?? []}
        onChange={setSelectedEquipments}
        labelledBy="Select"
      />
    </FilterSection>
  )
}
export default EquipmentFilter
