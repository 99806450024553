import React, { PropsWithoutRef } from "react"
import { NavLinkProps, useMatch, useResolvedPath } from "react-router-dom"
import { MenuItem } from "../../types/menu"
import NavItem from "./NavItem"

type Props = {
  menuVisible: boolean
  item: MenuItem
  onClick?: () => void
} & PropsWithoutRef<NavLinkProps>
const CustomLink = ({ menuVisible, item, onClick, ...rest }: Props) => {
  let resolved = useResolvedPath(rest.to)
  let match = useMatch({ path: resolved.pathname, end: true })

  const handleOnClick = () => {
    if (onClick) {
      onClick()
    }
  }

  return (
    <NavItem
      to={rest.to}
      active={!!match}
      menuVisible={menuVisible}
      onClick={handleOnClick}
    >
      <div className="flex items-center justify-start">
        <div className={menuVisible ? "mr-2" : "mr-0"}>
          {React.createElement(item.icon, { active: !!match })}
        </div>
        {menuVisible && <div>{item.title}</div>}
      </div>
    </NavItem>
  )
}

export default CustomLink
