import { useState } from "react"
import apiClient from "../../api"

interface ChangePasswordData {
  loading: boolean
  error?: string
  success?: boolean
  changePassword: (oldPass: string, newPass: string) => void
}

const useChangePassword = (): ChangePasswordData => {
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState<string>()
  const [success, setSuccess] = useState<boolean>()

  const changePassword = async (oldPass: string, newPass: string) => {
    setLoading(true)
    setError(undefined)
    setSuccess(false)
    try {
      await apiClient.put(
        `user-management/me/changePassword?old_password=${oldPass}&password=${newPass}`,
        null,
        {
          withCredentials: true
        }
      )

      setSuccess(true)
    } catch (e: any) {
      setError(e)
    }
    setLoading(false)
  }

  return { loading, error, success, changePassword }
}

export default useChangePassword
