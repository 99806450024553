import { useEffect, useMemo, useState } from "react"
import { Link, useLocation, useNavigate, useParams } from "react-router-dom"
import UserIcon from "../../assets/icons/user-profile.svg"
import useSignUpWithInvite from "../hooks/mutations/useSignUpWithInvite"
import useGetInvitationMetadata from "../hooks/queries/useGetInvitationMetadata"
import notyf from "../util/notyf"

const CreateProfile = () => {
  const [mounted, setMounted] = useState(false)
  const [firstName, setFirstName] = useState("")
  const [lastName, setLastName] = useState("")
  const [password, setPassword] = useState("")

  const { token } = useParams()
  const navigate = useNavigate()
  const { data, getMetadata } = useGetInvitationMetadata()
  const { success, signup } = useSignUpWithInvite()

  useEffect(() => {
    if (!token) {
      return
    }
    getMetadata(token)
  }, [])

  useEffect(() => {
    if (data) {
      setMounted(true)
    }
  }, [data])

  useEffect(() => {
    if (success) {
      notyf.success("Account created successfully! Please log in")
      navigate("/")
    }
  }, [success])

  const handleSubmit = (e?: React.FormEvent<HTMLFormElement>) => {
    e?.preventDefault()
    e?.stopPropagation()

    if (!token) {
      return
    }

    if (!firstName.trim() || !lastName.trim() || !password.trim()) {
      return notyf.error("Please complete all the fields")
    }
    const formData = new FormData()
    formData.append("invitation_token", token)
    formData.append("firstname", firstName)
    formData.append("lastname", lastName)
    formData.append("password", password)

    signup(formData)
  }

  if (!mounted) {
    return null
  }

  return (
    <div className="bg-grey-lighter min-h-screen flex flex-col">
      <div className="container max-w-sm mx-auto flex-1 flex flex-col items-center justify-center px-2">
        <div className="bg-white px-6 py-8 rounded shadow-md text-black w-full">
          <h1 className="mb-8 text-3xl text-center">Sign up</h1>
          <input
            type="text"
            className="block border border-grey-light w-full p-3 rounded mb-4"
            name="firstname"
            placeholder="First Name"
            onChange={(e) => setFirstName(e.target.value)}
          />

          <input
            type="text"
            className="block border border-grey-light w-full p-3 rounded mb-4"
            name="lastname"
            placeholder="Last Name"
            onChange={(e) => setLastName(e.target.value)}
          />

          <input
            type="text"
            className="block border border-grey-light w-full p-3 rounded mb-4"
            name="email"
            placeholder="Email"
            value={data?.invitation_email_address}
            disabled
          />

          <input
            type="password"
            className="block border border-grey-light w-full p-3 rounded mb-4"
            name="password"
            placeholder="Password"
            onChange={(e) => setPassword(e.target.value)}
          />

          <button
            type="submit"
            className="w-full text-center py-3 rounded bg-primary text-white focus:outline-none my-1"
            onClick={() => handleSubmit()}
          >
            Create Account
          </button>
        </div>

        <div className="text-grey-dark mt-6">
          Already have an account?
          <Link to="/">
            <a className="no-underline border-b border-blue text-blue ml-1">
              Log in
            </a>
          </Link>
          .
        </div>
      </div>
    </div>
  )
}

export default CreateProfile
