import { Dialog, Transition } from "@headlessui/react"
import { Fragment, useEffect, useState } from "react"
import { ActionMeta, SingleValue } from "react-select"
import RotateRightIcon from "../../../../assets/icons/rotate-right.svg"
import SelectFilterInput from "../../../../components/Filter/Input/SelectFilterInput"
import Checkbox from "../../../../components/Input/Checkbox"
import usePromoteCityAdmin from "../../../../hooks/mutations/usePromoteCityAdmin"
import useRequestPasswordReset from "../../../../hooks/mutations/useRequestPasswordReset"
import useUpdateUser from "../../../../hooks/mutations/useUpdateUser"
import useRegularUsers from "../../../../hooks/queries/userManagement/useRegularUsers"
import { useNotyf } from "../../../../hooks/useNotyf"
import { CityAdminClass } from "../../../../types/userManagement/cityAdmins"
import { RegularUser } from "../../../../types/userManagement/regularUsers"

interface Props {
  visible: boolean
  closeModal: () => void
  selectedUser: CityAdminClass | null
  selectedOrganizationId: number | null
}

const AddCityAdminModal = ({
  visible,
  closeModal,
  selectedUser,
  selectedOrganizationId
}: Props) => {
  const [email, setEmail] = useState<string>("")
  const [userId, setUserId] = useState<number>(0)
  const [password, setPassword] = useState<string>("")
  const [firstName, setFirstName] = useState<string>("")
  const [lastName, setLastName] = useState<string>("")
  const [active, setActive] = useState(false)
  // const [selectedOrganizations, setSelectedOrganizations] = useState<Option[]>(
  //   []
  // )
  const { notyf } = useNotyf()

  const { updateUser, success: updateSuccess } = useUpdateUser()
  const { data: regularUsersData, getUsers } = useRegularUsers()
  const { promoteRegularUser, success: promoteSuccess } = usePromoteCityAdmin()
  const { requestPasswordReset, success: resetSuccess } =
    useRequestPasswordReset()

  useEffect(() => {
    if (selectedUser) {
      setEmail(selectedUser.email)
      setFirstName(selectedUser.first_name)
      setLastName(selectedUser.last_name)
      setActive(selectedUser.user_active)
    } else {
      setEmail("")
      setFirstName("")
      setLastName("")
      setPassword("")
      // setSelectedOrganizations([])
    }
  }, [selectedUser])

  useEffect(() => {
    if (promoteSuccess || updateSuccess) {
      setEmail("")
      setPassword("")
      setFirstName("")
      setLastName("")
      setActive(false)
      // setSelectedOrganizations([])
      closeModal()
    }
  }, [promoteSuccess, updateSuccess])

  useEffect(() => {
    if (promoteSuccess) {
      notyf?.success("User was added successfully")
      closeModal()
    }
  }, [promoteSuccess])

  useEffect(() => {
    if (resetSuccess) {
      notyf?.success("Password reset successfully")
      closeModal()
    }
  }, [resetSuccess])

  useEffect(() => {
    if (updateSuccess) {
      notyf?.success("User was updated successfully")
      closeModal()
    }
  }, [updateSuccess])

  const handleEmailInput = (
    newValue: SingleValue<RegularUser>,
    _: ActionMeta<RegularUser>
  ) => {
    if (newValue) {
      setUserId(newValue.id)
    }
  }

  const handlePasswordInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(e.target.value)
  }

  const handleFirstNameInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFirstName(e.target.value)
  }

  const handleLastNameInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    setLastName(e.target.value)
  }

  const handleResetPassword = () => {
    if (!!selectedUser) {
      requestPasswordReset(selectedUser.id.toString())
    }
  }

  const handleConfirm = () => {
    if (!!selectedUser) {
      if (firstName.length == 0 || lastName.length == 0) {
        return notyf?.error({ message: "Please complete all the fields" })
      }
      const formData = new FormData()
      formData.append("first_name", firstName)
      formData.append("last_name", lastName)
      formData.append("user_active", active.toString())
      formData.append("target_user_id", selectedUser.id.toString())
      updateUser(formData)
    } else {
      promoteRegularUser(userId)
    }
  }

  return (
    <>
      <Transition appear show={visible} as={Fragment}>
        <Dialog
          as="div"
          className="fixed inset-0 z-10 overflow-y-auto"
          onClose={closeModal}
        >
          <Dialog.Overlay className="fixed inset-0 bg-black opacity-30" />
          <div className="min-h-screen px-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className="inline-block h-screen align-middle"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <div className="inline-block w-full max-w-lg p-6 my-8 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl">
                <div className="flex justify-between items-center">
                  <Dialog.Title
                    as="h3"
                    className="text-lg font-medium leading-6 text-gray-900"
                  >
                    {!!selectedUser ? "Edit" : "Add"} City Admin
                  </Dialog.Title>
                </div>

                <div className="mt-2">
                  {!selectedUser && (
                    <div className="mb-4">
                      <label className="sr-only">Email address</label>
                      <SelectFilterInput
                        options={regularUsersData?.users ?? []}
                        getOptionLabel={(item) => item.email}
                        getOptionValue={(item) => item.id.toString()}
                        maxMenuHeight={300}
                        onChange={handleEmailInput}
                      />
                    </div>
                  )}

                  {!!selectedUser && (
                    <div className="mb-4">
                      <label className="sr-only">First Name</label>
                      <input
                        value={firstName}
                        type="text"
                        className="appearance-none rounded-3xl relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-primary focus:border-primary focus:z-10 sm:text-sm"
                        placeholder="First Name"
                        onChange={handleFirstNameInput}
                      />
                    </div>
                  )}
                  {!!selectedUser && (
                    <div className="mb-4">
                      <label className="sr-only">Last Name</label>
                      <input
                        value={lastName}
                        type="text"
                        className="appearance-none rounded-3xl relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-primary focus:border-primary focus:z-10 sm:text-sm"
                        placeholder="Last Name"
                        onChange={handleLastNameInput}
                      />
                    </div>
                  )}
                </div>
                {!!selectedUser && (
                  <div className="mb-4">
                    <label className="sr-only">Password</label>
                    <input
                      type="password"
                      className="appearance-none rounded-3xl relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-primary focus:border-primary focus:z-10 sm:text-sm"
                      placeholder="Password"
                      onChange={handlePasswordInput}
                    />
                  </div>
                )}
                {!!selectedUser && (
                  <Checkbox
                    key="user_active"
                    checked={active}
                    label={"Active"}
                    onChange={() => setActive((active) => !active)}
                  />
                )}
                <div className="flex w-full items-center justify-between">
                  {!!selectedUser && (
                    <div
                      className="flex text-sm cursor-pointer"
                      onClick={handleResetPassword}
                    >
                      <img src={RotateRightIcon} className="w-4 mr-2" />
                      <div className="text-gray-500">Reset password</div>
                    </div>
                  )}
                  <button
                    type="button"
                    className="inline-flex justify-center px-4 py-2 text-sm font-medium text-blue-900 bg-blue-100 border border-transparent rounded-md hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-500"
                    onClick={handleConfirm}
                  >
                    Confirm
                  </button>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>
    </>
  )
}

export default AddCityAdminModal
