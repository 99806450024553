import {
  CartesianGrid,
  Legend,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis
} from "recharts"
import { equipmentColors } from "../../util/equipmentColors"
import { useTranslation } from "react-i18next"
import { format } from "date-fns"
import randomColors from "../../util/randomColor"

interface Props {
  data: any
  vehicleColors: Record<string, string>
}

const TimeSeriesChart = ({ data, vehicleColors}: Props) => {
  const { t } = useTranslation()
  if (!data || data?.length < 1) {
    return (
      <div className="flex w-full h-full items-center justify-center">
        <div className=" w-1/2  mx-auto px-5 py-4 bg-primary bg-opacity-10 rounded-md">
          <div className="text-xl text-center text-gray-500">
            {t("chart_comp.no_data")}
          </div>
        </div>
      </div>
    )
  }

  const vehicleKeys = Object.keys(data[0])
  const namePropertyIndex = vehicleKeys.indexOf("name")
  if (namePropertyIndex > -1) {
    vehicleKeys.splice(namePropertyIndex, 1)
  }
  
  const formatXAxisLabel = (value: any, index: number) => {
    return format(
      new Date(value),
      "MM/dd"
    )
  }

  return (
    <ResponsiveContainer width="95%" debounce={1}>
      <LineChart
        data={data}
        margin={{
          top: 5,
          right: 30,
          left: 20,
          bottom: 5
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        {/* <CartesianGrid/> */}
        <XAxis dataKey="name" tickFormatter={formatXAxisLabel}  />
        <YAxis/>
        <Tooltip position={{ y: -100 }} animationDuration={150} wrapperStyle={{ zIndex: 2 }} />
        {/* <Legend/> */}
        {vehicleKeys.map((vehicle) => (
          <Line
            key={vehicle}
            type="monotone"
            dataKey={vehicle}
            stroke={vehicleColors[vehicle]}
            activeDot={{ r: 5 }}
            strokeWidth={3}
            dot={false}
          />
        ))}
      </LineChart>
    </ResponsiveContainer>
  )
}

export default TimeSeriesChart
