import SummaryCard from "../../../components/Cards/SummaryCard"
import blueIcon from "../../../assets/icons/info-blue.svg"
import { useTranslation } from "react-i18next"
import { MaintenanceNeedList } from "../../../types/maintenance"
import { useCallback, useEffect } from "react"
import ReactTooltip from "react-tooltip"

interface Props {
  equipments?: MaintenanceNeedList[]
}
const MaintenanceCard = ({ equipments }: Props) => {
  const { t } = useTranslation()

  const getColor = useCallback((timer: number) => {
    if (timer > 50) {
      return "green"
    }

    if (timer >= 0 && timer < 50) {
      return "orange"
    }

    if (timer < 0) {
      return "red"
    }
  }, [])

  useEffect(() => {
    ReactTooltip.rebuild()
  })

  return (
    <SummaryCard
      title={t("maintenance.maintenance")}
      secondTitle="Hours until maintenance"
      bgColor="#FFF"
      textColor="#0055A0"
      iconSrc={blueIcon}
    >
      <div className="p-2">
        {equipments?.map((equipment) => (
          <div key={equipment.id} className="flex items-center justify-between border-b">
            <span data-for="maintenance" data-tip={equipment.name}>
              {equipment.name.slice(0, 8)}
            </span>
            <span
              style={{
                color: getColor(equipment.service_timer),
                paddingLeft: 2
              }}
            >
              {equipment.service_timer} hours
            </span>
          </div>
        ))}
      </div>
      <ReactTooltip id="maintenance"/>
    </SummaryCard>
  )
}

export default MaintenanceCard
