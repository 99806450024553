import { useTranslation } from "react-i18next"
import shallow from "zustand/shallow"
import FilterSection from "../../../../components/Filter/FilterSection"
import Checkbox from "../../../../components/Input/Checkbox"
import useStore from "../../../../store"

const MorningAfternoonFilter = () => {
  const { t } = useTranslation()

  const { dailyReportTimePeriod, setDailyReportTimePeriod } = useStore(
    (state) => ({
      dailyReportTimePeriod: state.dailyReportTimePeriod,
      setDailyReportTimePeriod: state.setDailyReportTimePeriod
    }),
    shallow
  )

  const options = [
    {
      key: "morning",
      label: t("daily_report.morning")
    },
    {
      key: "afternoon",
      label: t("daily_report.afternoon")
    }
  ]

  const handleCheckboxChange = (key: string) => {
    if (dailyReportTimePeriod === key) {
    return setDailyReportTimePeriod(undefined)
    }
    setDailyReportTimePeriod(key)
  }

  return (
    <FilterSection
      title={t("daily_report.morning_afternoon")}
      className="font-bold"
    >
      {options.map((option) => (
        <Checkbox
          key={option.key}
          checked={option.key === dailyReportTimePeriod}
          label={option.label}
          onChange={() => handleCheckboxChange(option.key)}
        />
      ))}
    </FilterSection>
  )
}

export default MorningAfternoonFilter
