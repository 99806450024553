import { ReactNode } from "react"

interface Props {
  title: string
  requiredText?: string
  className?: string
  children: ReactNode
}
const FilterSection = ({ title, requiredText, className, children }: Props) => {
  return (
    <div className="py-2">
      {requiredText && (
        <div className="flex flex-col mb-1">
          <span className={className}>{title}</span>
          <span className="text-red-700 text-xs">{requiredText}</span>
        </div>
      )}
      {!requiredText && <div className={`${className} mb-1`}>{title}</div>}

      {children}
    </div>
  )
}

export default FilterSection
