import shallow from "zustand/shallow"
import FilterSection from "../../../../components/Filter/FilterSection"
import DateFilter from "../../../../components/Filter/Input/DateFilterInput"
import useStore from "../../../../store"
import { useTranslation } from "react-i18next"
import { fromUnixTime, getUnixTime } from "date-fns"

const DailyReportDateFilter = () => {
  const { t } = useTranslation()

  const { dailyReportDate, setDailyReportDate } = useStore(
    (state) => ({
      dailyReportDate: state.dailyReportDate,
      setDailyReportDate: state.setDailyReportDate
    }),
    shallow
  )

  const handleDateChange = (date: Date) => {
    setDailyReportDate(getUnixTime(date))
  }

  return (
    <FilterSection title={t("daily_report.date")} className="font-bold">
      <DateFilter
        onChange={handleDateChange}
        value={dailyReportDate ? fromUnixTime(dailyReportDate) : null}
      />
    </FilterSection>
  )
}

export default DailyReportDateFilter
