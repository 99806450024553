import { useEffect, useMemo, useState } from "react"
import { Column, useTable } from "react-table"
import DeleteIcon from "../../../../assets/icons/trash.svg"
import useDetachVehicle from "../../../../hooks/mutations/useDetachVehicle"
import useRemoveOrgFromUser from "../../../../hooks/mutations/useRemoveOrgFromUser"
import useDistrictVehicles from "../../../../hooks/queries/district/useDistrictVehicles"
import useUserOrganizations from "../../../../hooks/queries/userManagement/useUserOrganizations"
import { User } from "../../../../types/userManagement/dealers"
import { DistrictVehicleResponse } from "../../../../types/userManagement/districtVehicle"
import {
  Organization
} from "../../../../types/userManagement/userOrganizations"
import AddVehicleDistrict from "./AddVehicleDistrict"
import AddOrganizationDealer from "./AddVehicleDistrict"
import DeleteVehicleRelModal from "./DeleteVehicleRelModal"

interface Props {
  selectedDistrictId?: number
  addVehicleModalOpen: boolean
  setAddVehicleModalOpen: (b: boolean) => void
}
const ConnectedVehicles = ({ selectedDistrictId, addVehicleModalOpen, setAddVehicleModalOpen }: Props) => {
  const [selectedId, setSelectedId] = useState<any>()
  const [open, setOpen] = useState(false)
  const { getVehicles, data } = useDistrictVehicles()

  useEffect(() => {
    if (selectedDistrictId) {
      getVehicles(selectedDistrictId)
    }
  }, [selectedDistrictId])

  const columns: Column<DistrictVehicleResponse>[] = useMemo(() => {
    return [
      {
        Header: "Vehicle Name",
        accessor: "name"
      },

      {
        Header: "Vehicle model",
        accessor: "model"
      },

      {
        Header: "Vehicle type",
        accessor: "type"
      },
      // {
      //   Header: "Status",
      //   accessor: "organization_active",
      //   Cell: ({
      //     cell: {
      //       row: { values }
      //     }
      //   }) => (
      //     <span className="rounded-xl px-2 py-1 text-sm">
      //       {values.organization_active ? "Active" : "Inactive"}
      //     </span>
      //   )
      // },
      // {
      //   id: "1",
      //   Header: " ",
      //   Cell: () => (
      //     <div className="flex text-sm cursor-pointer" onClick={() => {}}>
      //       <img src={EditIcon} className="w-4 mr-2" />
      //       <div className="text-gray-500">Edit</div>
      //     </div>
      //   )
      // },
      {
        id: "2",
        Header: " ",
        Cell: ({ cell: { row } }: { cell: any }) => (
          <div
            className="flex text-sm cursor-pointer"
            onClick={() => {
              setSelectedId(row.original.equipment_id)
              setOpen(true)
            }}
          >
            <img src={DeleteIcon} className="w-4 mr-2" />
            <div className="text-gray-500">Remove from workshop</div>
          </div>
        )
      }
    ]
  }, [])

  const tableInstance = useTable({ columns, data: data ?? [] })
  const { getTableBodyProps, headerGroups, rows, prepareRow } = tableInstance

  const { detachVehicle, success: detachVehicleSuccess } =
    useDetachVehicle()

  useEffect(() => {
    if (detachVehicleSuccess) {
      setOpen(false)
      getVehicles(selectedDistrictId as number)
    }
  }, [detachVehicleSuccess])

  const handleAddOrgClose = () => {
    setAddVehicleModalOpen(false)
    getVehicles(selectedDistrictId as number)
  }

  const removeVehicleFromDistrict = () => {
    detachVehicle(selectedId)
  }

  return (
    <div className="w-full">
      <div className="bg-white flex justify-between">
        <div className="flex space-x-2 divide-x-2 items-center px-4 py-2">
          <div className="text-lg">Connected Vehicles</div>
          {/* <div className="text-gray-600 text-sm pl-2">5 total 2 inactive</div> */}
        </div>
        {/* <div
          className="bg-primary text-white px-4 py-1 text-sm cursor-pointer flex items-center"
          onClick={handleAddUser}
        >
          Add User
        </div> */}
      </div>
      <div className="space-y-4 overflow-x-auto">
        <table {...getTableBodyProps()} className="w-full">
          <thead className="bg-white sticky top-0 border-t border-b">
            {
              // Loop over the header rows
              headerGroups.map((headerGroup) => (
                // Apply the header row props
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {
                    // Loop over the headers in each row
                    headerGroup.headers.map((column) => (
                      // Apply the header cell props
                      <th
                        {...column.getHeaderProps()}
                        scope="col"
                        className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer hover:bg-gray-50"
                      >
                        {
                          // Render the header
                          column.render("Header")
                        }
                      </th>
                    ))
                  }
                </tr>
              ))
            }
          </thead>
          <tbody
            {...getTableBodyProps()}
            className="bg-white divide-y divide-gray-200"
          >
            {
              // Loop over the table rows
              rows.map((row) => {
                // Prepare the row for display
                prepareRow(row)
                return (
                  // Apply the row props
                  <tr {...row.getRowProps()}>
                    {
                      // Loop over the rows cells
                      row.cells.map((cell) => {
                        // Apply the cell props
                        return (
                          <td
                            {...cell.getCellProps()}
                            className="px-6 py-4 whitespace-nowrap"
                          >
                            {
                              // Render the cell contents
                              cell.render("Cell")
                            }
                          </td>
                        )
                      })
                    }
                  </tr>
                )
              })
            }
          </tbody>
        </table>
      </div>
      <AddVehicleDistrict visible={addVehicleModalOpen} closeModal={handleAddOrgClose} selectedDistrictId={selectedDistrictId} />
      <DeleteVehicleRelModal visible={open} closeModal={() => {setOpen(false)}} onConfirm={removeVehicleFromDistrict} />

    </div>
  )
}

export default ConnectedVehicles
