import shallow from "zustand/shallow"
import useStore from "../../../store"
import Checkbox from "../../Input/Checkbox"
import FilterSection from "../FilterSection"
import { useTranslation } from "react-i18next"
import { hoursConfig } from "./HourFilter"

const MorningAfternoonFilter = () => {
  const { t } = useTranslation()

  const options = [
    {
      key: "morning",
      label: t("filter_comp.morning")
    },
    {
      key: "afternoon",
      label: t("filter_comp.afternoon")
    }
  ]

  const { timePeriod, selectedHours, setTimePeriod, setSelectedHours } =
    useStore(
      (state) => ({
        timePeriod: state.timePeriod,
        selectedHours: state.selectedHours,
        setTimePeriod: state.setTimePeriod,
        setSelectedHours: state.setSelectedHours
      }),
      shallow
    )

  const handleCheckboxChange = (key: string) => {

    if (timePeriod === key) {
      setTimePeriod(undefined)
      setSelectedHours([])
      return
    }

    if (key == "morning") {
      setSelectedHours(
        hoursConfig
          .filter((q) => q.key < 12)
          .map((h) => ({ label: h.long, value: h.key }))
      )
    } else if (key == "afternoon") {
      setSelectedHours(
        hoursConfig
          .filter((q) => q.key > 12)
          .map((h) => ({ label: h.long, value: h.key }))
      )
    } else {
      setSelectedHours([])
    }

    setTimePeriod(key)
  }

  return (
    <FilterSection
      title={t("filter_comp.morning_afternoon")}
      className="font-bold"
    >
      {options.map((option) => (
        <Checkbox
          key={option.key}
          checked={option.key === timePeriod}
          label={option.label}
          onChange={() => handleCheckboxChange(option.key)}
        />
      ))}
    </FilterSection>
  )
}

export default MorningAfternoonFilter
