interface Props {
  active?: boolean
}
const HomeIcon = ({ active }: Props) => {
  return (
    <svg width={32} height={32} fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.66663 16.6667L16 4.66669L29.3333 16.6667H25.3333V27.3334H17.3333V19.3334H14.6666V27.3334H6.66663V16.6667H2.66663ZM22.6666 14.2534L16 8.25335L9.33329 14.2534V24.6667H12V16.6667H20V24.6667H22.6666V14.2534Z"
        fill={active ? "#0055A0" : "#fff"}
      />
    </svg>
  )
}

export default HomeIcon
