import React from "react"
import { Route, Routes } from "react-router-dom"
import NavItem from "../../../components/NavBar/NavItem"
import AddMember from "./AddMember"
import ChangePassword from "./ChangePassword"
import Dealers from "./Dealers/Dealers"
import Language from "./Language"
import Profile from "./Profile"
import UserManagement from "./UserManagement"
import CityAdmins from "./CityAdmins/CityAdmins"
import FesAdmins from "./FesAdmins/FesAdmins"
import Districts from "./Districts/Districts"
import Vehicles from "./Vehicles/Vehicles"
import ShapeFile from "./Shapefile/ShapeFile"
import useGetMe from "../../../hooks/queries/useGetMe"
import Timezone from "./Timezone"

const UserRoutes = () => {
  const userRoutes = [
    {
      to: "profile",
      title: "Profile",
      component: Profile,
      allowedRoles: ["*"]
    },
    {
      to: "add-member",
      title: "Add Member",
      component: AddMember,
      allowedRoles: ["*"]
    },
    {
      to: "language",
      title: "Language",
      component: Language,
      allowedRoles: ["*"]
    },
    {
      to: "change-password",
      title: "Change password",
      component: ChangePassword,
      allowedRoles: ["*"]
    },
    {
      to: "timezone",
      title: "Change Timezone",
      component: Timezone,
      allowedRoles: ["ADMIN", "FES_ADMIN", "DEALER", "CITY_ADMIN"]
    },
    {
      to: "fes-admins",
      title: "FES Admins",
      component: FesAdmins,
      allowedRoles: ["ADMIN"]
    },
    {
      to: "dealers",
      title: "Dealers",
      component: Dealers,
      allowedRoles: ["ADMIN", "FES_ADMIN"]
    },
    {
      to: "city-admins",
      title: "City Admins",
      component: CityAdmins,
      allowedRoles: ["ADMIN", "FES_ADMIN", "DEALER"]
    },
    {
      to: "user-management",
      title: "User management",
      component: UserManagement,
      allowedRoles: ["ADMIN", "FES_ADMIN", "DEALER", "CITY_ADMIN"]
    },
    {
      to: "workshops",
      title: "Workshops",
      component: Districts,
      allowedRoles: ["ADMIN", "FES_ADMIN", "DEALER", "CITY_ADMIN"]
    },
    {
      to: "vehicles",
      title: "Vehicles",
      component: Vehicles,
      allowedRoles: ["ADMIN", "FES_ADMIN", "DEALER", "CITY_ADMIN"]
    },
    {
      to: "shapefile",
      title: "Shapefile",
      component: ShapeFile,
      allowedRoles: ["ADMIN", "FES_ADMIN", "DEALER", "CITY_ADMIN"]
    }
  ]

  const { userRole } = useGetMe()
  const routeIsAllowed = (allowedRoles: string[]) => {
    return allowedRoles.includes(userRole ?? "") || allowedRoles.includes("*")
  }

  return (
    <div className="flex w-full space-x-2 h-full">
      <div className="w-2/12 mr-4">
        <div className="max-w-lg mx-auto">
          <div className="bg-white w-48 rounded text-gray-900 text-sm font-medium">
            {userRoutes.map(
              (route, index) =>
                routeIsAllowed(route.allowedRoles) && (
                  <NavItem
                    key={route.to}
                    to={route.to}
                    active={false}
                    menuVisible={true}
                    className="p-0"
                  >
                    <div
                      className={`block px-4 py-2 border-b w-full cursor-pointer hover:bg-primary hover:text-white ${
                        index == userRoutes.length - 1
                          ? "rounded-b"
                          : index == 0
                          ? "rounded-t"
                          : ""
                      }`}
                    >
                      {route.title}
                    </div>
                  </NavItem>
                )
            )}
          </div>
        </div>
      </div>
      <div className="w-10/12 ">
        <Routes>
          {userRoutes.map((route) => (
            <Route
              key={route.to}
              path={route.to}
              element={React.createElement(route.component)}
            />
          ))}
        </Routes>
      </div>
    </div>
  )
}

export default UserRoutes
