import { useTranslation } from "react-i18next"
import shallow from "zustand/shallow"
import FilterSection from "../../../components/Filter/FilterSection"
import Checkbox from "../../../components/Input/Checkbox"
import useStore from "../../../store"
import { EnumHeatMapMeasure } from "../../../types/heatMap"

const MeasureFilter = () => {
  const { t } = useTranslation()

  const options = [
    {
      key: EnumHeatMapMeasure.TimeWorking,
      label:  t("heatmap.time_working")
    },
    {
      key: EnumHeatMapMeasure.SweepingFuel,
      label:  "Sweeping Energy Consumption"
    },
    {
      key: EnumHeatMapMeasure.SweepingWater,
      label:  "Sweeping Water Consumption"
    }
  ]

  const { heatMapMeasure, setHeatMapMeasure } = useStore(
    (state) => ({
      heatMapMeasure: state.heatMapMeasure,
      setHeatMapMeasure: state.setHeatMapMeasure
    }),
    shallow
  )

  const handleCheckboxChange = (key: EnumHeatMapMeasure) => {
    setHeatMapMeasure(key)
  }

  return (
    <FilterSection
      title={t("filter_comp.heatmap_measure")}
      className="font-bold"
    >
      {options.map((option) => (
        <Checkbox
          key={option.key}
          checked={option.key === heatMapMeasure}
          label={option.label}
          onChange={() => handleCheckboxChange(option.key)}
        />
      ))}
    </FilterSection>
  )
}

export default MeasureFilter
