import { useEffect, useMemo, useState } from "react"
import { useNavigate } from "react-router-dom"
import { Column, useSortBy, useTable } from "react-table"
import useDeleteUser from "../../../../hooks/mutations/useDeleteUser"
import useFesAdmins from "../../../../hooks/queries/userManagement/useFesAdmins"
import { useNotyf } from "../../../../hooks/useNotyf"
import {
  FesAdminsResponse,
  User
} from "../../../../types/userManagement/fesAdmins"
import FesAdminModal from "./FesAdminModal"

import DeleteIcon from "../../../../assets/icons/trash.svg"
import EditIcon from "../../../../assets/icons/edit.svg"
import DeleteModal from "../DeleteModal"

const FesAdmins = () => {
  const { data, loading, error, getData: refetch } = useFesAdmins()
  const [isOpen, setIsOpen] = useState(false)
  const [deleteIsOpen, setDeleteIsOpen] = useState(false)
  const [filteredValue, setFilteredValue] = useState("")
  const [selectedUser, setSelectedUser] = useState<User | null>(null)

  const filteredData = useMemo<FesAdminsResponse>(() => {
    if (data?.users.length && filteredValue.length > 0) {
      return {
        users: data.users.filter((user) => {
          return Object.keys(user).some((key) => {
            if (typeof key == "string") {
              const userProperty = user[key as keyof User]
              if (typeof userProperty == "string") {
                return userProperty.toLowerCase().indexOf(filteredValue) != -1
              }
            }
          })
        })
      }
    }
    if (data) {
      return data
    }
    return { users: [] } as FesAdminsResponse
  }, [filteredValue, data])

  const columns: Column<User>[] = useMemo(() => {
    return [
      {
        Header: "Name",
        accessor: "first_name"
      },
      {
        Header: "Email",
        accessor: "email"
      },
      {
        Header: "Status",
        accessor: "user_active",
        Cell: ({
          cell: {
            row: { values }
          }
        }) => <div>{values.user_active ? "Active" : "Inactive"}</div>
      },

      {
        id: "1",
        Header: " ",
        Cell: ({ cell: row }: { cell: any }) => (
          <div
            className="flex text-sm cursor-pointer"
            onClick={() => handleEditUser(row.row.original)}
          >
            <img src={EditIcon} className="w-4 mr-2" />
            <div className="text-gray-500">Edit</div>
          </div>
        )
      },
      {
        id: "2",
        Header: " ",
        accessor: "id",
        Cell: ({
          cell: {
            row: { original }
          }
        }) => (
          <div
            className="flex text-sm cursor-pointer"
            onClick={() => {
              setSelectedUser(original)
              setDeleteIsOpen(true)
            }}
          >
            <img src={DeleteIcon} className="w-4 mr-2" />
            <div className="text-gray-500">Delete</div>
          </div>
        )
      }
    ]
  }, [])

  const { notyf } = useNotyf()
  const { deleteUser, success: successDelete } = useDeleteUser()
  const tableInstance = useTable(
    { columns, data: filteredData.users },
    useSortBy
  )
  const { getTableBodyProps, headerGroups, rows, prepareRow } = tableInstance

  const activeInactive = useMemo(() => {
    if (!data?.users) {
      return [0, 0]
    }
    const allUsers = data.users.length
    const inactiveUsers = data.users.filter((q) => !q.user_active)
    return [allUsers, inactiveUsers.length]
  }, [data])

  const closeModal = () => {
    setSelectedUser(null)
    refetch()
    setIsOpen(false)
  }

  const openModal = () => {
    setIsOpen(true)
  }

  useEffect(() => {
    if (successDelete) {
      notyf?.open({ message: "User deleted successfully", background: "green" })
      refetch()
    }
  }, [successDelete])

  const handleDeleteClick = (userId?: number) => {
    if(userId) {
      setDeleteIsOpen(false)
      deleteUser(userId)
    }
  }

  const handleEditUser = (user: User) => {
    setSelectedUser(user)
    openModal()
  }

  return (
    <div className="w-full">
      <div className="bg-white flex justify-between">
        <div className="flex space-x-2 divide-x-2 items-center px-4 py-2">
          <div className="text-lg">FES Admins</div>

          <div className="text-gray-600 text-sm pl-2">
            {activeInactive[0]} total {activeInactive[1]} inactive
          </div>
          <div className="pl-2">
            <input
              autoComplete="false"
              value={filteredValue}
              type="text"
              className="appearance-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none sm:text-sm"
              placeholder="Filter Fes admins"
              onChange={(e) => setFilteredValue(e.target.value)}
            />
          </div>
        </div>
        <div
          className="bg-primary text-white px-4 py-1 text-sm cursor-pointer flex items-center"
          onClick={openModal}
        >
          Add FES Admin
        </div>
      </div>
      <div className="space-y-4 overflow-x-auto">
        <table {...getTableBodyProps()} className="w-full">
          <thead className="bg-white sticky top-0 border-t border-b">
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  // Add the sorting props to control sorting. For this example
                  // we can add them into the header props
                  <th
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer hover:bg-gray-50"
                  >
                    {column.render("Header")}
                    {/* Add a sort direction indicator */}
                    <span>
                      {column.isSorted
                        ? column.isSortedDesc
                          ? " 🔽"
                          : " 🔼"
                        : ""}
                    </span>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody
            {...getTableBodyProps()}
            className="bg-white divide-y divide-gray-200"
          >
            {
              // Loop over the table rows
              rows.map((row) => {
                // Prepare the row for display
                prepareRow(row)
                return (
                  // Apply the row props
                  <tr {...row.getRowProps()}>
                    {
                      // Loop over the rows cells
                      row.cells.map((cell) => {
                        // Apply the cell props
                        return (
                          <td
                            {...cell.getCellProps()}
                            className="px-6 py-4 whitespace-nowrap"
                          >
                            {
                              // Render the cell contents
                              cell.render("Cell")
                            }
                          </td>
                        )
                      })
                    }
                  </tr>
                )
              })
            }
          </tbody>
        </table>
      </div>

      <FesAdminModal
        visible={isOpen}
        closeModal={closeModal}
        selectedUser={selectedUser}
      />
      <DeleteModal
        visible={deleteIsOpen}
        closeModal={() => setDeleteIsOpen(false)}
        userId={selectedUser?.id}
        onConfirm={handleDeleteClick}
      />
    </div>
  )
}

export default FesAdmins
