import { useEffect, useMemo, useState } from "react"
import Scrollbars from "react-custom-scrollbars"
import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"
import shallow from "zustand/shallow"
import appIcon from "../../assets/icons/appIcon.svg"
import logoutIcon from "../../assets/icons/nav/logout.svg"
import { useAuth } from "../../hooks/useAuth"
import useStore from "../../store"
import { MenuItem } from "../../types/menu"
import CustomLink from "./CustomLink"
import DailyReportIcon from "./Icons/DailyReportIcon"
import FleetComparisonIcon from "./Icons/FleetComparisonIcon"
import GeneralOverviewIcon from "./Icons/GeneralOverviewIcon"
import HeatmapDistrictIcon from "./Icons/HeatmapDistrictIcon"
import HeatmapIcon from "./Icons/HeatmapIcon"
import HomeIcon from "./Icons/HomeIcon"
import MaintenanceIcon from "./Icons/MaintenanceIcon"
import SettingsIcon from "./Icons/SettingsIcon"

interface Props {
  visible: boolean
  onToggleMobileNav: () => void
}
const MobileNavBar = ({ visible, onToggleMobileNav }: Props) => {
  const { logout } = useAuth()
  const { t } = useTranslation()
  const navigate = useNavigate()
  const [filteredOrg, setFilteredOrg] = useState("")
  const [organizationMenuVisible, setOrganizationMenuVisible] = useState(false)

  const dashboardMenu: MenuItem[] = [
    {
      path: "/dashboard",
      title: t("nav_comp.home"),
      icon: HomeIcon
    },
    {
      path: "overview",
      title: t("nav_comp.general_overview"),
      icon: GeneralOverviewIcon
    },
    {
      path: "fleet-comparison",
      title: t("nav_comp.fleet_comparison"),
      icon: FleetComparisonIcon
    },
    {
      path: "heatmap",
      title: t("nav_comp.heatmap"),
      icon: HeatmapIcon
    },

    {
      path: "heatmap-district",
      title: t("nav_comp.heatmap_district"),
      icon: HeatmapDistrictIcon
    },
    {
      path: "daily-report",
      title: t("nav_comp.daily_report"),
      icon: DailyReportIcon
    },
    {
      path: "maintenance",
      title: t("nav_comp.maintenance"),
      icon: MaintenanceIcon
    }
    // {
    //   path: "download-data",
    //   title: t("nav_comp.download_data"),
    //   icon: DownloadIcon
    // }
  ]

  const userMenu: MenuItem[] = [
    {
      path: "user/profile",
      title: "Settings",
      icon: SettingsIcon
    }
  ]

  const { selectedOrganization, myOrganizations, setSelectedOrganization } =
    useStore(
      (state) => ({
        selectedOrganization: state.selectedOrganization,
        myOrganizations: state.myOrganizations,
        setSelectedOrganization: state.setSelectedOrganization
      }),
      shallow
    )

  useEffect(() => {
    if (
      !selectedOrganization &&
      myOrganizations &&
      myOrganizations.length > 0
    ) {
      setSelectedOrganization(myOrganizations[0].id)
    }
  }, [selectedOrganization, myOrganizations])

  const selectedOrganizationName = myOrganizations?.find(
    (q) => q.id === selectedOrganization
  )?.organizationName

  const handleFilterOrganization = (value: string) => {
    setFilteredOrg(value)
  }

  const availableOrgs = useMemo(() => {
    if (myOrganizations?.length && filteredOrg.length > 0) {
      return myOrganizations.filter((q) =>
        q.organizationName.toLowerCase().includes(filteredOrg.toLowerCase())
      )
    }
    return myOrganizations
  }, [filteredOrg, myOrganizations])

  return (
    <div
      className={`sidebar bg-primary z-20 text-blue-100 w-64 space-y-6 pb-7 px-2 absolute inset-y-0 left-0 transform lg:hidden transition duration-200 ease-in-out ${
        visible ? "" : "-translate-x-full"
      }`}
    >
      <div className="flex items-center w-full">
        <button
          className="p-5 focus:outline-none lg:hidden w-1/4"
          onClick={onToggleMobileNav}
        >
          <svg
            className="h-5 w-5"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 20 20"
            stroke="#fff"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M4 6h20M4 12h20M4 18h20"
            />
          </svg>
        </button>
        <div className="flex w-2/4 items-center justify-center">
          <img src={appIcon} alt="img" />
        </div>
      </div>
      <nav className="w-52">
        <div className="flex flex-col items-center justify-between h-full">
          <div className="relative text-left mb-4">
            <button
              type="button"
              className="inline-flex border border-white justify-center w-full text-sm rounded-md shadow-sm px-2 py-1 font-medium hover:bg-secondary transition-colors ease-in-out text-white"
              id="menu-button"
              aria-expanded="true"
              aria-haspopup="true"
              onClick={() =>
                availableOrgs && availableOrgs.length > 1
                  ? setOrganizationMenuVisible((visible) => !visible)
                  : () => {}
              }
            >
              {selectedOrganizationName}

              {availableOrgs && availableOrgs.length > 1 && (
                <svg
                  className="-mr-2 ml-2 h-6 w-5"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                  aria-hidden="true"
                >
                  <path
                    fillRule="evenodd"
                    d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                    clipRule="evenodd"
                  />
                </svg>
              )}
            </button>
            <div
              className={`absolute left-2 right-0 mt-2 w-48 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 divide-y divide-gray-100 focus:outline-none ${
                organizationMenuVisible ? "block" : "hidden"
              }`}
              role="menu"
              aria-orientation="vertical"
              aria-labelledby="menu-button"
              tabIndex={-1}
            >
              <Scrollbars style={{ width: "100%", height: 250 }}>
                <input
                  autoComplete="off"
                  value={filteredOrg}
                  name="organizationFilter"
                  type="text"
                  className="appearance-none text-sm rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none sm:text-sm"
                  placeholder="Filter organization"
                  onChange={(e) => handleFilterOrganization(e.target.value)}
                />
                {availableOrgs &&
                  availableOrgs.length > 0 &&
                  availableOrgs?.map((item) => (
                    <div className="p-1" role="none" key={item.id}>
                      <div
                        className="text-gray-700 font-semibold block px-4 py-2 text-xs hover:bg-secondary hover:text-white cursor-pointer"
                        onClick={() => {
                          setSelectedOrganization(item.id)
                          setOrganizationMenuVisible((visible) => !visible)
                          setFilteredOrg("")
                          onToggleMobileNav()
                          navigate("/dashboard")
                        }}
                      >
                        {item.organizationName}
                      </div>
                    </div>
                  ))}
              </Scrollbars>
            </div>
          </div>
          <div>
            {dashboardMenu.map((item) => (
              <CustomLink
                to={item.path}
                key={item.title}
                menuVisible={true}
                item={item}
                onClick={onToggleMobileNav}
              />
            ))}
          </div>

          <div className="absolute bottom-0 left-5">
            {userMenu.map((item) => (
              <CustomLink
                to={item.path}
                key={item.title}
                menuVisible={true}
                item={item}
                onClick={onToggleMobileNav}
              />
            ))}

            <div
              className="block py-1.5 md:py-2.5 px-2 md:px-4 rounded transition duration-200 cursor-pointer"
              onClick={logout}
            >
              <div className="flex items-center justify-start">
                <div className="mr-2">
                  <img src={logoutIcon} alt="icon" />
                </div>
                <div>{t("nav_comp.logout")}</div>
              </div>
            </div>
          </div>
        </div>
      </nav>
    </div>
  )
}

export default MobileNavBar
