import React from "react"

interface Props {
  label: string
  checked: boolean
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void
}

const Checkbox = ({ label, checked, onChange }: Props) => {
  return (
    <div className="max-w-sm mx-auto">
      <label className="inline-flex items-center">
        <input
          checked={checked}
          className="text-indigo-500 w-5 h-5 mr-2 focus:ring-indigo-400 focus:ring-opacity-25 border border-gray-300 rounded"
          type="checkbox"
          onChange={onChange}
        />
        {label}
      </label>
    </div>
  )
}

export default Checkbox
