import axios from "axios"
import { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import shallow from "zustand/shallow"
import apiClient from "../../../api"
import useStore from "../../../store"
import { UserOrganizationsResponse } from "../../../types/userManagement/userOrganizations"

interface MyOrganizationsData {
  loading: boolean
  error?: string
  getOrganizations: (token: string) => void
}

const useMyOrganizations = (): MyOrganizationsData => {
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState<string>()
  const navigate = useNavigate()

  const { myOrganizations, setLoggedIn, setSelectedEquipments, setMyOrgs } =
    useStore(
      (state) => ({
        myOrganizations: state.myOrganizations,
        setSelectedEquipments: state.setSelectedEquipments,
        setLoggedIn: state.setLoggedIn,
        setMyOrgs: state.setMyOrgs
      }),
      shallow
    )

  const getOrganizations = async (accessToken: string) => {
    if (myOrganizations?.length) {
      return
    }
    setLoading(true)
    try {
      const { data } = await axios.get<UserOrganizationsResponse>(
        `${process.env.REACT_APP_API_BASE_URL}/user-management/me/getRelatedOrganizations`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`
          }
        }
      )
      setMyOrgs(data.organizations)
      setSelectedEquipments([])
      setLoggedIn(true)
      navigate("/dashboard")
    } catch (e: any) {
      navigate("/loading")
      setError(e)
    }
    setLoading(false)
  }

  return { loading, error, getOrganizations }
}

export default useMyOrganizations
