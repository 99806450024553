import { useEffect, useState } from "react"
import apiClient from "../../api"
import useStore from "../../store"
import { HomeResponse } from "../../types/home"

interface OverviewData {
  loading: boolean
  error?: string
  data?: HomeResponse
}

const useOverview = (): OverviewData => {
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState<string>()
  const [data, setData] = useState<any>()

  const selectedOrganization = useStore((q) => q.selectedOrganization)

  useEffect(() => {
    if (selectedOrganization) {
      getOverview()
    }
  }, [selectedOrganization])

  const getOverview = async () => {
    setLoading(true)
    try {
      const { data } = await apiClient.get(
        "/sweeper-api/dashboards/overview_dashboard"
      )
      setData(data)
    } catch (e: any) {
      setError(e)
    }
    setLoading(false)
  }

  return { loading, error, data }
}

export default useOverview
