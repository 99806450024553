import Slider from "rc-slider/lib/Slider"
import { useMemo } from "react"
import "rc-slider/assets/index.css"
import { format } from "date-fns"
import useStore from "../../../store"
import shallow from "zustand/shallow"

interface Props {
  data?: Array<Array<number | null | string>>
}

const TimeSlider = ({ data }: Props) => {
  const { dailyReportTimestamp, setDailyReportTimestamp } = useStore(
    (state) => ({
      dailyReportTimestamp: state.dailyReportTimestamp,
      setDailyReportTimestamp: state.setDailyReportTimestamp
    }),
    shallow
  )

  const timestampValues = useMemo(() => {
    if (!data) {
      return []
    }

    return data.map((record) => record[0] ?? "")
  }, [data])

  const handleAfterChange = (value: number) => {
    const selectedTs = timestampValues[value]
    if (selectedTs) {
      setDailyReportTimestamp(new Date(selectedTs))
    }
  }

  const marks = useMemo(() => {
    if (timestampValues.length == 0) {
      return {}
    }

    const firstTimestamp = format(new Date(timestampValues[0]), "HH:mm")
    const lastTimeStamp = format(
      new Date(timestampValues[timestampValues.length - 1]),
      "HH:mm"
    )

    return {
      0: firstTimestamp,
      [timestampValues.length - 1]: lastTimeStamp
    }
  }, [timestampValues])

  return (
    <div className="absolute w-2/6 right-4 bottom-3">
      <div className="flex w-full h-full bg-white py-3 px-5 rounded-t-lg">
        <Slider
          min={0}
          max={timestampValues.length > 0 ? timestampValues.length - 1 : 1}
          trackStyle={{ backgroundColor: "#0055A0" }}
          railStyle={{ backgroundColor: "#0055A0" }}
          handleStyle={{
            borderColor: "#0055A0",
            borderWidth: 6,
            width: 24,
            height: 24,
            marginTop: -10
          }}
          dotStyle={{ backgroundColor: "#0055A0", borderColor: "#0055A0" }}
          onAfterChange={handleAfterChange}
          marks={marks}
        />
      </div>
    </div>
  )
}

export default TimeSlider
