import { Dialog, Transition } from "@headlessui/react"
import { Fragment, useEffect, useState } from "react"
import useInviteUser from "../../../hooks/mutations/useInviteUser"
import { useNotyf } from "../../../hooks/useNotyf"
import useStore from "../../../store"

interface Props {
  visible: boolean
  closeModal: () => void
}
const AddUserModal = ({ visible, closeModal }: Props) => {
  const [email, setEmail] = useState<string>("")

  const { inviteUser, success } = useInviteUser()
  const { notyf } = useNotyf()

  const selectedOrg = useStore((state) => state.selectedOrganization)

  useEffect(() => {
    if (success) {
      notyf?.success("User was invited successfully")
      closeModal()
    }
  }, [success])

  const handleEmailInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value)
    // setEmail(e.target.value)
  }

  const handleConfirm = (e?: React.FormEvent<HTMLFormElement>) => {
    e?.preventDefault()
    e?.stopPropagation()
    if (email.length == 0) {
      return notyf?.error({ message: "Please complete all the fields" })
    }
    if (selectedOrg) {
      const formData = new FormData()
      formData.append("email_address", email)
      formData.append("target_organization_id", selectedOrg?.toString())

      inviteUser(formData)
    }
  }

  return (
    <>
      <Transition appear show={visible} as={Fragment}>
        <Dialog
          as="div"
          className="fixed inset-0 z-10 overflow-y-auto"
          onClose={closeModal}
        >
          <Dialog.Overlay className="fixed inset-0 bg-black opacity-30" />
          <div className="min-h-screen px-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className="inline-block h-screen align-middle"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <div
                className="inline-block w-full max-w-lg p-6 my-8 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl"
                // style={{ minHeight: 425 }}
              >
                <div className="flex justify-between items-center">
                  {/* // search all organizations that dont have dealer, when button is clicked */}
                  <Dialog.Title
                    as="h3"
                    className="text-lg font-medium leading-6 text-gray-900"
                  >
                    Add User
                  </Dialog.Title>
                </div>
                <div className="mt-2">
                  {/* <div className="mb-4">
                    <MultiSelect
                      ClearSelectedIcon={true}
                      options={options}
                      value={selectedOrganizations ?? []}
                      onChange={setSelectedOrganizations}
                      labelledBy="Select organizations"
                    />
                  </div> */}
                  <form onSubmit={handleConfirm}>
                    <div className="mb-6 pr-4 flex items-center border rounded-lg">
                      <input
                        className="py-4 pl-4 mr-4 w-full placeholder-blueGray-400 bg-white focus:outline-none border-0 rounded-l-lg"
                        placeholder="Users's e-mail"
                        type="email"
                        onChange={handleEmailInput}
                      />
                      <svg
                        className="w-6 h-6 text-blueGray-300"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2"
                          d="M16 12a4 4 0 10-8 0 4 4 0 008 0zm0 0v1.5a2.5 2.5 0 005 0V12a9 9 0 10-9 9m4.5-1.206a8.959 8.959 0 01-4.5 1.207"
                        ></path>
                      </svg>
                    </div>
                    <button className="inline-flex justify-center px-4 py-2 text-sm font-medium text-blue-900 bg-blue-100 border border-transparent rounded-md hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-500">
                      Add User
                    </button>
                  </form>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>
    </>
  )
}

export default AddUserModal
