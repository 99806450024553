import { format, fromUnixTime } from "date-fns"
import qs from "qs"
import { useCallback, useEffect, useState } from "react"
import shallow from "zustand/shallow"
import apiClient from "../../api"
import useStore from "../../store"
import { EnumHeatMapMeasure, HeatMapResponse } from "../../types/heatMap"
import { getHourRangeParameter } from "../../util"
import { useNotyf } from "../useNotyf"

interface HeatMapData {
  loading: boolean
  error?: string
  data?: HeatMapResponse
  heatMapMeasure?: EnumHeatMapMeasure
  getData: () => void
}

const useHeatMap = (): HeatMapData => {
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState<string>()
  const [data, setData] = useState<HeatMapResponse>()
  const [pendingRequest, setPendingRequest] = useState(false)
  const { notyf } = useNotyf()

  const {
    selectedDistricts,

    selectedEquipments,
    startDate,
    endDate,
    timePeriod,
    heatMapMeasure,
    selectedHours
  } = useStore(
    (state) => ({
      selectedDistricts: state.selectedDistricts,

      selectedEquipments: state.selectedEquipments,
      startDate: state.startDate,
      endDate: state.endDate,
      timePeriod: state.timePeriod,
      heatMapMeasure: state.heatMapMeasure,
      selectedHours: state.selectedHours
    }),
    shallow
  )

  useEffect(() => {
    if (pendingRequest && selectedEquipments && selectedDistricts && !data) {
      getData()
    }
  }, [pendingRequest, selectedEquipments, selectedDistricts, data])

  useEffect(() => {
    getData()
  }, [])

  const buildRequestParams = useCallback(() => {
    if (!selectedEquipments?.length || !selectedDistricts?.length) {
      return null
    }
    const params: Record<string, string> = {
      vehicle_ids: selectedEquipments.map((d) => d.value).join(","),
      debug_vehicle_names: selectedEquipments.map((d) => d.label).join(","),
      district_ids: selectedDistricts.map((d) => d.value).join(",")
    }

    // if (selectedBrands?.length) {
    //   params["brands_filter"] = selectedBrands.map((d) => d.value).join(",")
    // }
    // if (selectedTypes?.length) {
    //   params["types_filter"] = selectedTypes.map((d) => d.value).join(",")
    // }
    // if (selectedModels?.length) {
    //   params["models_filter"] = selectedModels.map((d) => d.value).join(",")
    // }

    if (timePeriod) {
      params["half_of_day_filter"] = timePeriod
    }
    if (startDate) {
      params["date_start"] = format(fromUnixTime(startDate), "yyyy-MM-dd")
    }
    if (endDate) {
      params["date_end"] = format(fromUnixTime(endDate), "yyyy-MM-dd")
    }

    if (selectedHours?.length) {
      params["hours_filter"] = getHourRangeParameter(selectedHours)
    }

    return params
  }, [
    selectedDistricts,
    selectedHours,
    // selectedBrands,
    // selectedTypes,
    // selectedModels,
    selectedEquipments,
    startDate,
    endDate,
    timePeriod
  ])

  const getData = async () => {
    const requestParams = buildRequestParams()
    if (!requestParams) {
      notyf?.error("Please select at least one equipment and district")
      // setPendingRequest(true)
      setLoading(false)
      return
    }
    setLoading(true)
    try {
      const { data } = await apiClient.get<HeatMapResponse>(
        `/sweeper-api/dashboards/neighborhood_heatmap?${qs.stringify(
          requestParams
        )}`
      )
      setData(data)
    } catch (e: any) {
      setError(e)
    }
    setLoading(false)
    // setPendingRequest(false)
  }

  return { loading, error, data, heatMapMeasure, getData }
}

export default useHeatMap
