import ContentLoader from "react-content-loader"
import Card from "../../../components/Cards"
import DPBarChart from "../../../components/Charts/DPBarChart"
import {
  BatteryUsageAverage,
  EChartDatum,
  FuelUsageChartDatum,
  UsagePerKMChartDatum,
  WaterUsageChartDatum
} from "../../../types/fleetComparison"

interface Props {
  loading: boolean
  title: string
  description: string
  data?:
    | EChartDatum[]
    | FuelUsageChartDatum[]
    | UsagePerKMChartDatum[]
    | WaterUsageChartDatum[]
    | BatteryUsageAverage[]
  firstKey: string
  secondKey?: string
  additionalTitle?: React.ReactNode
}

const FleetComparisonChart = ({
  loading,
  data,
  title,
  description,
  firstKey,
  secondKey,
  additionalTitle
}: Props) => {
  return (
    <Card
      className="w-full md:w-6/12"
      title={title}
      description={description}
      additionalTitle={additionalTitle}
    >
      <div className="flex-grow" style={{ margin: "0", marginLeft: "-0.8rem" }}>
        {loading && (
          <ContentLoader viewBox="-40 0 240 220" height={220}>
            {/* Only SVG shapes */}
            <rect x="0" y="5" rx="5" ry="5" width="20" height="185" />
            <rect x="30" y="25" rx="5" ry="5" width="20" height="165" />
            <rect x="60" y="15" rx="5" ry="5" width="20" height="175" />
            <rect x="90" y="5" rx="5" ry="5" width="20" height="185" />
          </ContentLoader>
        )}
        {!loading && (
          <DPBarChart
            stack
            data={data}
            firstKey={firstKey}
            secondKey={secondKey}
          />
        )}
      </div>
    </Card>
  )
}

export default FleetComparisonChart
