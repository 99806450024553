import { Dialog, Transition } from "@headlessui/react"
import { Fragment, useEffect, useMemo, useState } from "react"
import { MultiSelect } from "react-multi-select-component"
import { Option } from "react-multi-select-component/dist/types/lib/interfaces"
import useAddOrgRelation from "../../../../hooks/mutations/useAddOrgRelation"
import useOrganizationsNoDealer from "../../../../hooks/queries/userManagement/useOrganizationsNoDealer"

interface Props {
  visible: boolean
  selectedDealer?: number
  closeModal: () => void
}

const AddOrganizationDealer = ({
  visible,
  selectedDealer,
  closeModal
}: Props) => {
  const [selectedOrganizations, setSelectedOrganizations] = useState<Option[]>(
    []
  )
  const { data } = useOrganizationsNoDealer()
  const { addOrgRelation, success } = useAddOrgRelation()

  const options = useMemo<Option[]>(() => {
    if (data?.organizations.length) {
      return data.organizations.map((org) => ({
        label: org.organizationName,
        value: org.id
      }))
    }

    return []
  }, [data])

  useEffect(() => {
    if(success) {
      closeModal()
    }
  }, [success])

  const handleConfirm = () => {
    const formData = new FormData()
    formData.append("target_user_id", selectedDealer!.toString())
    formData.append("related_organization_ids", selectedOrganizations.map((d) => d.value).join(","))
    
    addOrgRelation(formData)
  }

  return (
    <>
      <Transition appear show={visible} as={Fragment}>
        <Dialog
          as="div"
          className="fixed inset-0 z-10 overflow-y-auto"
          onClose={closeModal}
        >
          <Dialog.Overlay className="fixed inset-0 bg-black opacity-30" />
          <div className="min-h-screen px-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className="inline-block h-screen align-middle"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <div className="inline-block w-full max-w-screen-sm p-6 my-8 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl" 
              style={{minHeight: 450}}>
                <div className="flex justify-between items-center">
                  {/* // search all organizations that dont have dealer, when button is clicked */}
                  <Dialog.Title
                    as="h3"
                    className="text-lg font-medium leading-6 text-gray-900"
                  >
                    Add organization to dealer
                  </Dialog.Title>
                 
                </div>
                <div className="mt-2">
                  <MultiSelect
                    ClearSelectedIcon={true}
                    options={options}
                    value={selectedOrganizations ?? []}
                    onChange={setSelectedOrganizations}
                    labelledBy="Select organizations"
                  />
                </div>

                <div className="mt-4 flex items-center justify-end">
                  <button
                    type="button"
                    className="inline-flex justify-center px-4 py-2 text-sm font-medium text-blue-900 bg-blue-100 border border-transparent rounded-md hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-500"
                    onClick={handleConfirm}
                  >
                    Confirm
                  </button>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>
    </>
  )
}

export default AddOrganizationDealer
