import { useEffect, useState } from "react"
import apiClient from "../../../api"
import { DistrictVehicleResponse } from "../../../types/userManagement/districtVehicle"

interface UserOrganizationsData {
  loading: boolean
  error?: string
  data?: DistrictVehicleResponse[]
  getVehicles: (districtId: number) => void
}

const useDistrictVehicles = (): UserOrganizationsData => {
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState<string>()
  const [data, setData] = useState<DistrictVehicleResponse[]>([])

  const getVehicles = async (districtId: number) => {
    setLoading(true)
    try {
      const { data } = await apiClient.get<DistrictVehicleResponse[]>(
        `/sweeper-api/districts/${districtId}/vehicles`
      )
      setData(data)
    } catch (e: any) {
      setError(e)
    }
    setLoading(false)
  }

  return { loading, error, data, getVehicles }
}

export default useDistrictVehicles
