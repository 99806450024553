import { useMemo } from "react"
import { Option } from "react-multi-select-component/dist/types/lib/interfaces"
import Card from "../../../components/Cards"
import Filter from "../../../components/Filter"
import FilterGroup from "../../../components/Filter/FilterGroup"
import FilterSection from "../../../components/Filter/FilterSection"
import ColorfulLabel from "../../../components/Filter/Input/ColorfulLabel"
import BrandFilter from "../../../components/Filter/Shared/BrandFilter"
import DistrictFilter from "../../../components/Filter/Shared/DistrictFilter"
import EquipmentFilter from "../../../components/Filter/Shared/EquipmentFilter"
import LimitedEquipmentFilter from "../../../components/Filter/Shared/LimitedEquipmentFilter"
import ModelFilter from "../../../components/Filter/Shared/ModelFilter"
import TypeFilter from "../../../components/Filter/Shared/TypeFilter"
import { PageFilterProps } from "../../../types/pageFilter"
import randomColors from "../../../util/randomColor"

export type Props = {
  equipments?: Option[]
  vehicleColors: Record<string, string>
} & PageFilterProps
const MaintenanceFilters = ({ equipments, vehicleColors, ...rest }: Props) => {
  return (
    <Filter>
      <Card style={{marginTop: 0}}>
        <FilterGroup {...rest}>
          <DistrictFilter />
          <BrandFilter />
          <TypeFilter />
          <ModelFilter />
          <LimitedEquipmentFilter />
          <FilterSection title="Equipment name" className="font-bold">
            {equipments?.map((equipment) => (
              <ColorfulLabel
                key={equipment.key}
                color={vehicleColors[equipment.label]}
                title={equipment.label}
              />
            ))}
          </FilterSection>
        </FilterGroup>
      </Card>
    </Filter>
  )
}
export default MaintenanceFilters
