import { format } from "date-fns"
import { useEffect } from "react"
import ContentLoader from "react-content-loader"
import { useTranslation } from "react-i18next"
import shallow from "zustand/shallow"
import Card from "../../../components/Cards"
import DPAreaChart from "../../../components/Charts/DPAreaChart"
import DPAreaChartBiaxial from "../../../components/Charts/DPAreaChartBiaxial"
import { GMapWrapper } from "../../../components/Maps"
import useDailyReport from "../../../hooks/queries/useDailyReport"
import useStore from "../../../store"
import DashboardContainer from "../../DashboardContainer"
import DailyMapWrapper from "./DailyMapWrapper"
import DailyReportFilters from "./Filters"

const DailyReport = () => {
  const { t } = useTranslation()
  const { loading, data, getData } = useDailyReport()

  const {
    filterMenuVisible,
    dailyReportTimestamp,
    setDailyReportTimestamp
  } = useStore(
    (state) => ({
      filterMenuVisible: state.filterMenuVisible,
      dailyReportTimestamp: state.dailyReportTimestamp,
      setDailyReportTimestamp: state.setDailyReportTimestamp
    }),
    shallow
  )

  const handleApplyFilter = () => {
    setDailyReportTimestamp(undefined)
    getData()
  }

  return (
    <DashboardContainer title={t("daily_report.daily_report")}>
      <div
        className="flex space-x-0 md:space-x-2 justify-between"
        style={{ height: "calc(100% - 5rem)" }}
      >
        <div
          className={`flex flex-col h-full md:overflow-hidden ${
            filterMenuVisible ? "w-full lg:w-10/12" : "w-full"
          }`}
        >
          <Card
            className="h-4/6 relative min-h-mobile"
            rightTitle={
              dailyReportTimestamp
                ? `Timestamp: ${format(
                    dailyReportTimestamp,
                    "dd-MM-yyyy HH:mm"
                  )}`
                : undefined
            }
          >
            <GMapWrapper loading={loading}>
              <DailyMapWrapper
                loading={loading}
                data={data}
                dailyReportTs={dailyReportTimestamp}
              />
            </GMapWrapper>
          </Card>
          <div className="h-full flex flex-col md:h-2/6 items-start justify-between md:flex-row md:space-x-4 min-h-mobile">
            <Card
              title={t("daily_report.water_level")}
              description={"in percentage"}
              inlineHeader
              // style={{ width: "48%" }}
              className="h-full w-full md:w-6/12"
            >
              <>
                {loading && (
                  <ContentLoader viewBox="-40 0 240 220" height={220}>
                    {/* Only SVG shapes */}
                    <rect x="0" y="5" rx="5" ry="5" width="20" height="185" />
                    <rect x="30" y="25" rx="5" ry="5" width="20" height="165" />
                    <rect x="60" y="15" rx="5" ry="5" width="20" height="175" />
                    <rect x="90" y="5" rx="5" ry="5" width="20" height="185" />
                  </ContentLoader>
                )}
                {!loading && (
                  <DPAreaChart
                    data={data?.water_levels_chart_data ?? []}
                    label="Water Level"
                    strokeColor="#0055A0"
                    fillColor="#0055a038"
                  />
                )}
              </>
            </Card>
            <Card
              title={
                data?.is_electric_vehicle
                  ? "Battery level"
                  : t("daily_report.fuel_level")
              }
              description={"in percentage"}
              inlineHeader
              className="h-full w-full md:w-6/12"
            >
              <>
                {loading && (
                  <ContentLoader viewBox="-40 0 240 220" height={220}>
                    <rect x="0" y="5" rx="5" ry="5" width="20" height="185" />
                    <rect x="30" y="25" rx="5" ry="5" width="20" height="165" />
                    <rect x="60" y="15" rx="5" ry="5" width="20" height="175" />
                    <rect x="90" y="5" rx="5" ry="5" width="20" height="185" />
                  </ContentLoader>
                )}
                {!loading && (
                  <>
                    {data?.is_electric_vehicle && (
                      <DPAreaChartBiaxial
                        data={data?.battery_soc_kw_biaxial_chart_data ?? []}
                      />
                    )}
                    {!data?.is_electric_vehicle && (
                      <DPAreaChart
                        data={data?.fuel_levels_chart_data ?? []}
                        label="Fuel Level"
                        strokeColor="#00A066"
                        fillColor="#00a06638"
                      />
                    )}
                  </>
                )}
              </>
            </Card>
          </div>
        </div>
        {filterMenuVisible && (
          <div
            className={`transform absolute w-60 right-0 z-20 shadow-xl lg:shadow-none lg:right-auto lg:relative lg:w-80 ${
              filterMenuVisible ? "translate-x-0" : "translate-x-full"
            }`}
          >
            <DailyReportFilters onApplyFilter={handleApplyFilter} />
          </div>
        )}
      </div>
    </DashboardContainer>
  )
}
export default DailyReport
