import { useEffect, useMemo, useState } from "react"
import apiClient from "../../../api"
import useStore from "../../../store"
import { FesAdminsResponse } from "../../../types/userManagement/fesAdmins"

interface FesAdminsData {
  loading: boolean
  error?: string
  data?: FesAdminsResponse
  getData: () => void
}

const useFesAdmins = (): FesAdminsData => {
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState<string>()
  const [data, setData] = useState<FesAdminsResponse>({
    users: []
  })

  const selectedOrganization = useStore((q) => q.selectedOrganization)

  useEffect(() => {
    getData()
  }, [selectedOrganization])

  const getData = async () => {
    setLoading(true)
    try {
      const { data } = await apiClient.get<FesAdminsResponse>(
        "/user-management/su/getFesAdmins"
      )
      setData(data)
    } catch (e: any) {
      setError(e)
    }
    setLoading(false)
  }

  return { loading, error, data, getData }
}

export default useFesAdmins
