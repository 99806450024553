import React from "react"
import useStore from "../../../store"
import UserIcon from "../../../assets/icons/user-profile.svg"

const Profile = () => {
  const me = useStore((state) => state.me)
  if (!me) {
    return null
  }
  return (
    <div className="relative mb-20 max-w-lg mx-auto text-center">
      <div className="mb-8 py-8 px-6 bg-white rounded shadow-form flex items-center justify-around">
      <img src={UserIcon} className="w-12" alt="User" />
      <div className="flex flex-col items-start">
        <div className="w-full text-primary font-bold text-lg text-center">
          Welcome
        </div>
        <div className="text-lg">
          <strong>Name: {me.first_name || "-"} {me.last_name || "-"}</strong>
        </div>
        <div className="text-lg">
          <strong>Email: </strong> {me.email}
        </div>
        <div className="text-lg">
          <strong>Role: </strong> {me.user_role}
        </div>
        </div>
      </div>
    </div>
  )
}

export default Profile
