import { useMemo } from "react"
import { Column, useTable } from "react-table"
import { ShapefileResponse } from "../../../../types/userManagement/shapeFile"

interface Props {
  data: ShapefileResponse
}

const ShapeFileTable = ({ data }: Props) => {
  const columns: Column<any>[] = useMemo(() => {
    return data.file_preview_json.columns.map((column) => ({
      Header: column,
      accessor: column
    }))
  }, [])

  const tableInstance = useTable({ columns, data: data.file_preview_json.sample_rows })
  const { getTableBodyProps, headerGroups, rows, prepareRow } = tableInstance

  return (
    <div className="space-y-4 overflow-x-auto">
      <table {...getTableBodyProps()} className="w-full">
        <thead className="bg-white sticky top-0 border-t border-b">
          {
            // Loop over the header rows
            headerGroups.map((headerGroup) => (
              // Apply the header row props
              <tr {...headerGroup.getHeaderGroupProps()}>
                {
                  // Loop over the headers in each row
                  headerGroup.headers.map((column) => (
                    // Apply the header cell props
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer hover:bg-gray-50"
                    >
                      {column.render("Header")}
                      <span>
                        {column.isSorted
                          ? column.isSortedDesc
                            ? " 🔽"
                            : " 🔼"
                          : ""}
                      </span>
                    </th>
                  ))
                }
              </tr>
            ))
          }
        </thead>
        <tbody
          {...getTableBodyProps()}
          className="bg-white divide-y divide-gray-200"
        >
          {
            // Loop over the table rows
            rows.map((row) => {
              // Prepare the row for display
              prepareRow(row)
              return (
                // Apply the row props
                <tr {...row.getRowProps()}>
                  {
                    // Loop over the rows cells
                    row.cells.map((cell) => {
                      // Apply the cell props
                      return (
                        <td
                          {...cell.getCellProps()}
                          className="px-6 py-4 whitespace-nowrap"
                        >
                          {
                            // Render the cell contents
                            cell.render("Cell")
                          }
                        </td>
                      )
                    })
                  }
                </tr>
              )
            })
          }
        </tbody>
      </table>
    </div>
  )
}

export default ShapeFileTable
