import { useTranslation } from "react-i18next"
import { MultiSelect } from "react-multi-select-component"
import shallow from "zustand/shallow"
import useVehicleMetadata from "../../../hooks/queries/useVehicleMetadata"
import useStore from "../../../store"
import FilterSection from "../FilterSection"

const TypeFilter = () => {
  const data = useStore((state) => state.vehicleMetadata)
  const { t } = useTranslation()

  const { selectedTypes, setSelectedTypes } = useStore(
    (state) => ({
      selectedTypes: state.selectedTypes,
      setSelectedTypes: state.setSelectedTypes
    }),
    shallow
  )
  const typeOptions =
    data?.types.map((type) => ({ label: type, value: type })) || []

  return (
    <FilterSection title={t("filter_comp.type")} className="font-bold">
      <MultiSelect
        ClearSelectedIcon={true}
        options={typeOptions}
        value={selectedTypes ?? []}
        onChange={setSelectedTypes}
        labelledBy="Select"
      />
    </FilterSection>
  )
}

export default TypeFilter
