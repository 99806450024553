interface Props {
  active?: boolean
}
const DailyReportIcon = ({ active }: Props) => {
  return (
    <svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        id="icon/action/today_24px"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18 4H19C20.1 4 21 4.9 21 6V20C21 21.1 20.1 22 19 22H5C3.89 22 3 21.1 3 20V6C3 4.9 3.89 4 5 4H6V2H8V4H16V2H18V4ZM5 10V20H19V10H5ZM19 8H5V6H19V8ZM12 12H7V17H12V12Z"
        fill={active ? "#0055A0" : "#fff"}
      />
    </svg>
  )
}

export default DailyReportIcon
