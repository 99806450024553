import { useEffect, useState } from "react"
import apiClient from "../../../api"
import useStore from "../../../store"
import { CityAdminsResponse } from "../../../types/userManagement/cityAdmins"

interface FesAdminsData {
  loading: boolean
  error?: string
  data?: CityAdminsResponse
  getCityAdmins: () => void
}

const useCityAdmins = (): FesAdminsData => {
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState<string>()
  const [data, setData] = useState<CityAdminsResponse>()

  const selectedOrganization = useStore((q) => q.selectedOrganization)

  useEffect(() => {
    getCityAdmins()
  }, [selectedOrganization])

  const getCityAdmins = async () => {
    setLoading(true)
    try {
      const { data } = await apiClient.get<CityAdminsResponse>(
        "/user-management/su/getCityAdmins"
      )
      setData(data)
    } catch (e: any) {
      setError(e)
    }
    setLoading(false)
  }

  return { loading, error, data, getCityAdmins }
}

export default useCityAdmins
