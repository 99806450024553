import Scrollbars from "react-custom-scrollbars"
import Card from "../../../components/Cards"
import Filter from "../../../components/Filter"
import FilterGroup from "../../../components/Filter/FilterGroup"
import BrandFilter from "../../../components/Filter/Shared/BrandFilter"
import DistrictFilter from "../../../components/Filter/Shared/DistrictFilter"
import EndDateFilter from "../../../components/Filter/Shared/EndDateFilter"
import EquipmentFilter from "../../../components/Filter/Shared/EquipmentFilter"
import HourFilter from "../../../components/Filter/Shared/HourFilter"
import ModelFilter from "../../../components/Filter/Shared/ModelFilter"
import MorningAfternoonFilter from "../../../components/Filter/Shared/MorningAfternoonFilter"
import StartDateFilter from "../../../components/Filter/Shared/StartDateFilter"
import TypeFilter from "../../../components/Filter/Shared/TypeFilter"
import { PageFilterProps } from "../../../types/pageFilter"
import MeasureFilter from "./MeasureFilter"

const HeatMapDistrictFilters = (props: PageFilterProps) => {
  return (
    <Filter>
      <Card fullHeight>
        <Scrollbars autoHide style={{ width: "100%", height: "calc(100%)" }}>
          <FilterGroup {...props}>
            <DistrictFilter />
            <BrandFilter />
            <TypeFilter />
            <ModelFilter />
            <EquipmentFilter />
            <StartDateFilter />
            <EndDateFilter />
            <MorningAfternoonFilter />
            <HourFilter />
            <MeasureFilter />
          </FilterGroup>
        </Scrollbars>
      </Card>
    </Filter>
  )
}
export default HeatMapDistrictFilters
