import { Children, cloneElement, isValidElement, ReactNode } from "react"
import { useTranslation } from "react-i18next"

interface Props {
  children: ReactNode | ReactNode[]
  onApplyFilter: () => void
}
const FilterGroup = ({ children, onApplyFilter }: Props) => {
  const { t } = useTranslation()
  return (
    <div className="bg-white divide-y divide-gray-200">
      {Children.map(children, (child) => {
        if (isValidElement(child)) {
          return cloneElement(child)
        }
      })}

      <div className="pt-2">
        <button
          className="flex w-full items-center justify-center rounded-md border bg-primary bg-opacity-95 hover:bg-opacity-100 text-sm text-white px-3 py-2"
          type="button"
          onClick={onApplyFilter}
        >
         {t("filter_comp.apply")}
        </button>
      </div>
    </div>
  )
}

export default FilterGroup
