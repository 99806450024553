import {
  Area,
  AreaChart,
  CartesianGrid,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis
} from "recharts"

import { useTranslation } from "react-i18next"
import { format, fromUnixTime, parse, roundToNearestMinutes } from "date-fns"

interface Props {
  data: any[]
  label: string
  strokeColor?: string
  fillColor?: string
}

const DPAreaChart = ({ data, label, strokeColor, fillColor }: Props) => {
  const { t } = useTranslation()
  if (!data || data?.length < 1) {
    return (
      <div className="flex w-full h-full items-center justify-center">
        <div className=" w-1/2  mx-auto px-5 py-4 bg-primary bg-opacity-10 rounded-md">
          <div className="text-xl text-center text-gray-500">
            {t("chart_comp.no_data")}
          </div>
        </div>
      </div>
    )
  }

  const formatXAxisLabel = (value: any) => {
    return format(
      roundToNearestMinutes(fromUnixTime(value), {
        nearestTo: 30
      }),
      "HH:mm"
    )
  }

  return (
    <ResponsiveContainer width="100%">
      <AreaChart
        width={500}
        height={400}
        data={data}
        margin={{
          top: 10,
          right: 30,
          left: 0,
          bottom: 0
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="name" tickFormatter={formatXAxisLabel} />
        <YAxis />
        <Tooltip labelFormatter={(label, _) => <div>{format(fromUnixTime(parseInt(label)),"HH:mm:ss" )}</div> }/>
        <Area
          dataKey="level"
          stroke={strokeColor ?? "#8884d8"}
          fill={fillColor ?? "#8884d8"}
          name={label}
        />
      </AreaChart>
    </ResponsiveContainer>
  )
}

export default DPAreaChart
