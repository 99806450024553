import { useState } from "react"
import apiClient from "../../api"
import useStore from "../../store"

interface UploadShapeFileData {
  loading: boolean
  error?: string
  success?: boolean
  deleteShapeFile: () => void
}

const useDeleteShapeFile = (): UploadShapeFileData => {
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState<string>()
  const [success, setSuccess] = useState<boolean>()

  const selectedOrg = useStore((state) => state.selectedOrganization)

  const deleteShapeFile = async () => {
    setLoading(true)
    setError(undefined)
    setSuccess(false)
    try {
      await apiClient.delete(
        `/sweeper-api/shapeFiles?target_organization_id=${selectedOrg}`
      )
      setSuccess(true)
    } catch (e: any) {
      setError(e)
    }
    setLoading(false)
  }

  return { loading, error, success, deleteShapeFile }
}

export default useDeleteShapeFile
