import { useEffect, useState } from "react"
import apiClient from "../../../api"
import { ShapefileResponse } from "../../../types/userManagement/shapeFile"

interface ShapeFileData {
  loading: boolean
  error?: string
  data?: ShapefileResponse
  getShapeFile: () => void
}

const useShapeFile = (): ShapeFileData => {
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState<string>()
  const [data, setData] = useState<ShapefileResponse>()

  useEffect(() => {
    getShapeFile()
  }, [])

  const getShapeFile = async () => {
    setLoading(true)
    setError(undefined)
    try {
      const { data } = await apiClient.get<ShapefileResponse>(
        "/sweeper-api/shapeFiles"
      )
      if (
        data &&
        Object.keys(data).length === 0 &&
        Object.getPrototypeOf(data) === Object.prototype
      ) {
      } else {
        setData(data)
      }
    } catch (e: any) {
      setError(e)
      setData(undefined)
    }
    setLoading(false)
  }

  return { loading, error, data, getShapeFile }
}

export default useShapeFile
