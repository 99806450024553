import { useMemo } from "react"
import { useTranslation } from "react-i18next"
import { MultiSelect } from "react-multi-select-component"
import { Option } from "react-multi-select-component/dist/types/lib/interfaces"
import { SingleValue } from "react-select"
import shallow from "zustand/shallow"
import useStore from "../../../store"
import FilterSection from "../FilterSection"
import SelectFilterInput from "../Input/SelectFilterInput"

export interface Hour {
  key: number
  long: string
  short: string
}

export const hoursConfig: Hour[] = [
  {
    key: 0,
    long: "00:00 - 01:00",
    short: "12:00 AM"
  },
  {
    key: 1,
    long: "01:00 - 02:00",
    short: "1:00 AM"
  },
  {
    key: 2,
    long: "02:00 - 03:00",
    short: "12:00 AM"
  },
  {
    key: 3,
    long: "03:00 - 04:00",
    short: "12:00 AM"
  },
  {
    key: 4,
    long: "04:00 - 05:00",
    short: "4:00 AM"
  },
  {
    key: 5,
    long: "05:00 - 06:00",
    short: "5:00 AM"
  },
  {
    key: 6,
    long: "06:00 - 07:00",
    short: "6:00 AM"
  },
  {
    key: 7,
    long: "07:00 - 08:00",
    short: "7:00 AM"
  },
  {
    key: 8,
    long: "08:00 - 09:00",
    short: "8:00 AM"
  },
  {
    key: 9,
    long: "09:00 - 10:00",
    short: "9:00 AM"
  },
  {
    key: 10,
    long: "10:00 - 11:00",
    short: "10:00 AM"
  },
  {
    key: 11,
    long: "11:00 - 12:00",
    short: "11:00 AM"
  },
  {
    key: 12,
    long: "12:00 - 13:00",
    short: "12:00 PM"
  },
  {
    key: 13,
    long: "13:00 - 14:00",
    short: "1:00 PM"
  },
  {
    key: 14,
    long: "14:00 - 15:00",
    short: "2:00 PM"
  },
  {
    key: 15,
    long: "15:00 - 16:00",
    short: "3:00 PM"
  },
  {
    key: 16,
    long: "16:00 - 17:00",
    short: "4:00 PM"
  },
  {
    key: 17,
    long: "17:00 - 18:00",
    short: "5:00 PM"
  },
  {
    key: 18,
    long: "18:00 - 19:00",
    short: "6:00 PM"
  },
  {
    key: 19,
    long: "19:00 - 20:00",
    short: "7:00 PM"
  },
  {
    key: 20,
    long: "20:00 - 21:00",
    short: "8:00 PM"
  },
  {
    key: 21,
    long: "21:00 - 22:00",
    short: "9:00 PM"
  },
  {
    key: 22,
    long: "22:00 - 23:00",
    short: "10:00 PM"
  },
  {
    key: 23,
    long: "23:00 - 00:00",
    short: "11:00 PM"
  }
]

const HourFilter = () => {
  const { t } = useTranslation()

  const { timePeriod, selectedHours, setSelectedHours } = useStore(
    (state) => ({
      selectedHours: state.selectedHours,
      timePeriod: state.timePeriod,
      setSelectedHours: state.setSelectedHours
    }),
    shallow
  )

  const availableHours = useMemo(() => {
    return hoursConfig
        .map((h) => ({ label: h.long, value: h.key }))
  }, [])

  return (
    <FilterSection title={t("filter_comp.hour")} className="font-bold">
      <MultiSelect
        ClearSelectedIcon={true}
        options={availableHours}
        value={selectedHours ?? []}
        onChange={setSelectedHours}
        labelledBy="Select"
      />
    </FilterSection>
  )
}

export default HourFilter
