import Scrollbars from "react-custom-scrollbars"
import { useTranslation } from "react-i18next"
import Card from "../../../components/Cards"
import Filter from "../../../components/Filter"
import FilterGroup from "../../../components/Filter/FilterGroup"
import BrandFilter from "../../../components/Filter/Shared/BrandFilter"
import DistrictFilter from "../../../components/Filter/Shared/DistrictFilter"
import EndDateFilter from "../../../components/Filter/Shared/EndDateFilter"
import EquipmentFilter from "../../../components/Filter/Shared/EquipmentFilter"
import HourFilter from "../../../components/Filter/Shared/HourFilter"
import ModelFilter from "../../../components/Filter/Shared/ModelFilter"
import MorningAfternoonFilter from "../../../components/Filter/Shared/MorningAfternoonFilter"
import StartDateFilter from "../../../components/Filter/Shared/StartDateFilter"
import TypeFilter from "../../../components/Filter/Shared/TypeFilter"
import { PageFilterProps } from "../../../types/pageFilter"

const OverviewFilters = (props: PageFilterProps) => {
  const { t } = useTranslation()

  return (
    <Filter>
      <Card fullHeight>
        <Scrollbars autoHide style={{ width: "100%", height: "calc(100%)" }}>
          <FilterGroup {...props}>
            {/* <FilterSection title="Mode" className=" font-bold">
              <ColorfulLabel color="#FFD600" title={t("overview.travel")} />
              <ColorfulLabel color="#FF7A00" title={t("overview.work")} />
            </FilterSection> */}
            <DistrictFilter />
            <BrandFilter />
            <TypeFilter />
            <ModelFilter />
            <EquipmentFilter />
            <StartDateFilter />
            <EndDateFilter />
            <MorningAfternoonFilter />
            <HourFilter />
            {/* <FilterSection title={t("overview.metric")} className="font-bold">
              <SelectFilterInput />
            </FilterSection> */}
          </FilterGroup>
        </Scrollbars>
      </Card>
    </Filter>
  )
}
export default OverviewFilters
