import { useEffect, useState } from "react"
import apiClient from "../../../api"
import { RegularUserResponse } from "../../../types/userManagement/regularUsers"

interface ReguarUsersData {
  loading: boolean
  error?: string
  data?: RegularUserResponse
  getUsers: () => void
}

const useRegularUsers = (): ReguarUsersData => {
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState<string>()
  const [data, setData] = useState<RegularUserResponse>({ users: [] })

  useEffect(() => {
    getUsers()
  }, [])

  const getUsers = async () => {
    setLoading(true)
    try {
      const { data } = await apiClient.get<RegularUserResponse>(
        "/user-management/getRegularUsers"
      )
      setData(data)
    } catch (e: any) {
      setError(e)
    }
    setLoading(false)
  }

  return { loading, error, data, getUsers }
}

export default useRegularUsers
