import React, { useEffect } from "react"
import useDeepCompareMemoize from "./useDeepCompareMemoize"

const useDeepCompareEffectForMaps = (
  callback: React.EffectCallback,
  dependencies: any[]
) => {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(callback, dependencies.map(useDeepCompareMemoize))
}

export default useDeepCompareEffectForMaps
