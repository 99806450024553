import { getUnixTime } from "date-fns"
import fromUnixTime from "date-fns/fromUnixTime"
import { useTranslation } from "react-i18next"
import shallow from "zustand/shallow"
import useStore from "../../../store"
import FilterSection from "../FilterSection"
import DateFilter from "../Input/DateFilterInput"

const StartDateFilter = () => {
  const { t } = useTranslation()

  const { startDate, setStartDate } = useStore(
    (state) => ({
      startDate: state.startDate,
      setStartDate: state.setStartDate
    }),
    shallow
  )

  const handleDateChange = (date?: Date) => {
    if (!date) {
      return
    }
    setStartDate(getUnixTime(date))
  }

  return (
    <FilterSection title={t("filter_comp.start_date")} className="font-bold">
      <DateFilter
        onChange={handleDateChange}
        value={startDate ? fromUnixTime(startDate) : null}
      />
    </FilterSection>
  )
}

export default StartDateFilter
