import { format, fromUnixTime } from "date-fns"
import qs from "qs"
import { useCallback, useEffect, useState } from "react"
import shallow from "zustand/shallow"
import apiClient from "../../api"
import useStore from "../../store"
import { DailyReportResponse } from "../../types/dailyReport"
import { useNotyf } from "../useNotyf"

interface DailyReportData {
  loading: boolean
  error?: string
  data?: DailyReportResponse
  getData: () => void
}

const useDailyReport = (): DailyReportData => {
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState<string>()
  const [data, setData] = useState<any>()
  const [pendingRequest, setPendingRequest] = useState(false)
  const { notyf } = useNotyf()

  const {
    dailyReportDate,
    dailyReportEquipmentId,
    dailyReportTimePeriod,
    selectedOrganization
  } = useStore(
    (state) => ({
      dailyReportDate: state.dailyReportDate,
      dailyReportEquipmentId: state.dailyReportEquipmentId,
      dailyReportTimePeriod: state.dailyReportTimePeriod,
      selectedOrganization: state.selectedOrganization
    }),
    shallow
  )

  useEffect(() => {
    if (
      pendingRequest &&
      dailyReportDate &&
      dailyReportEquipmentId &&
      selectedOrganization &&
      data
    ) {
      getData()
    }
  }, [
    pendingRequest,
    dailyReportDate,
    dailyReportEquipmentId,
    selectedOrganization,
    data
  ])

  const buildRequestParams = useCallback(() => {
    if (!dailyReportEquipmentId || !dailyReportDate || !selectedOrganization) {
      return null
    }
    const params: Record<string, string> = {
      date_start: format(fromUnixTime(dailyReportDate), "yyyy-MM-dd"),
      vehicle_id: dailyReportEquipmentId
    }
    if (dailyReportTimePeriod) {
      params["hours_filter"] =
        dailyReportTimePeriod === "morning" ? "0-12" : "12-24"
    }
    return params
  }, [
    dailyReportDate,
    dailyReportEquipmentId,
    dailyReportTimePeriod,
    selectedOrganization
  ])

  const getData = async () => {
    const requestParams = buildRequestParams()
    if (!requestParams) {
      // setPendingRequest(true)
      notyf?.error("Please select start date and equipment!")
      setLoading(false)
      return
    }
    setLoading(true)
    setData(undefined)
    try {
      const { data } = await apiClient.get(
        `/sweeper-api/dashboards/single_vehicle_daily_report?${qs.stringify(
          buildRequestParams()
        )}`
      )
      setData(data)
    } catch (e: any) {
      setData(undefined)
      setError(e)
    }
    setLoading(false)
    setPendingRequest(false)
  }

  return { loading, error, data, getData }
}

export default useDailyReport
